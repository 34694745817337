import { sessionQuery, sessionService } from '../store/session'
import { useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { pdf } from '@react-pdf/renderer'
import { useObservable } from '@ngneat/react-rxjs'

import { ItemType } from '../models/props.models'
import { User } from '../models/users.models'
import { getPlatform } from '../models/platforms.models'

import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useModal from '../hooks/useModal.hooks'
import useSnackbar from '../hooks/useSnackbar.hooks'
import { Mode } from '../models/commons.models'

import CertificateTemplate from '../components/template/Certificate.template'
import LinkButton from '../components/common/button/Link.button'
import InformationUser from '../components/user/Informations.user'
import InformationOrganization from '../components/organization/Informations.organizations'
import DetailsComponent from '../components/common/Details.common'

import Modal from '../components/layout/Modal.layout'
import ModalFormUser from '../components/user/ModalForm.user'
import LoaderOverlay from '../components/layout/LoaderOverlay.layout'

import Double from '../components/layout/Double.layout'
import { FileUtils } from '../utils/files.utils'

import { sendEvent } from '../utils/gtm.utils'
import { organizationsService } from '../store/organizations'
const Account = () => {
  useCheckRoute('Account', [Mode.front, Mode.app, Mode.storeFront])
  const show = useSnackbar()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [user] = useObservable(sessionQuery.user)

  const [modal, setModal] = useModal<'edit' | 'delete'>()
  if (!user) return <></>
  const certificateEnable = getPlatform(user.organization.platform).certificateEnable

  const handleChangePassword = async () => {
    setLoading(true)
    try {
      await sessionService.forgotPassword(user.email)
      show(t('sessions:actions.forgotPasswordSuccess'))
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }
  const onCerfaUpdate = async (enableCerfa: boolean) => {
    setLoading(true)
    try {
      await organizationsService.updateOrganization(user.organization._id, { enableCerfa })
      show(t('sessions:pages.account.editModal.success'))
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  const downloadCertificate = async () => {
    sendEvent('downloadCertificate')
    const blob = await pdf(<CertificateTemplate user={user} />).toBlob()
    FileUtils.downloadFile(`${t('sessions:pages.account.certificate')}.pdf`, blob)
  }

  return (
    <>
      {loading && <LoaderOverlay />}
      <Double
        reverse
        first={
          <InformationUser
            title={t('sessions:pages.account.myAccount')}
            actions={{ onClick: () => setModal('edit') }}
            user={user}
            additionals={[
              {
                label: t('users:attributes.password'),
                type: ItemType.link,
                key: 'password',
                props: {
                  disabled: loading,
                  children: t('sessions:pages.account.changePassword'),
                  onClick: handleChangePassword,
                },
              },
              {
                type: ItemType.button,
                key: 'deleteAccount',
                props: {
                  children: t('sessions:pages.account.deleteAccount'),
                  onClick: () => setModal('delete'),
                },
              },
            ]}
          />
        }
        second={
          <>
            <InformationOrganization
              title={t('sessions:pages.account.myOrganization')}
              organization={user.organization}
              onCerfaUpdate={onCerfaUpdate}
            />
            {certificateEnable && (
              <Box mt="15px">
                <DetailsComponent
                  value={user}
                  items={[
                    {
                      label: t('sessions:pages.account.myCertificate'),
                      type: ItemType.custom,
                      key: 'myCertificate',
                      custom: (
                        <LinkButton color="primary" variant="text" onClick={downloadCertificate}>
                          {t('sessions:pages.account.generateCertificate')}
                        </LinkButton>
                      ),
                    },
                  ]}
                  title={t('sessions:pages.account.certificate')}
                />
              </Box>
            )}
          </>
        }
      />
      {modal === 'delete' && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={sessionService.deleteAccount}
          onSuccess={() => {
            show(t('sessions:pages.account.deleteModal.success'))
            sessionService.logout()
          }}
          title={t('sessions:pages.account.deleteModal.title')}
          description={t('sessions:pages.account.deleteModal.description')}
        />
      )}
      {modal === 'edit' && (
        <ModalFormUser
          user={user}
          title={t('sessions:pages.account.editModal.title')}
          formatValue={(user: User) => ({
            phoneNumber: user.phoneNumber,
            address: user.address,
            receiveNewsEmails: user.receiveNewsEmails,
            receiveMatchingEmails: user.receiveMatchingEmails,
          })}
          onSubmit={sessionService.updateMyAccount}
          onClose={() => setModal('')}
          onSuccess={() => {
            show(t('sessions:pages.account.editModal.success'))
          }}
        />
      )}
    </>
  )
}
export default Account
