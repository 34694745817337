import { useState, useMemo, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, Typography } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  ManageCatalog,
  BuildingType,
  DeconstructionInterventionType,
  ConstructionInterventionType,
  CatalogType,
  Catalog,
  isNeeds,
  CatalogStatus,
} from '../../models/catalogs.models'
import { RetrievalModality, Currency } from '../../models/commons.models'
import { ItemType, FormItem, SelectOption } from '../../models/props.models'
import { organizationsService } from '../../store/organizations'
import { sessionService } from '../../store/session'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormCatalogProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue'> {
  catalog?: ManageCatalog
  hideDocument?: boolean
  isAdmin?: boolean
  useImperials: boolean
}
const ModalFormCatalog: React.FC<ModalFormCatalogProps> = (props) => {
  const { t } = useTranslation()
  const { isAdmin, catalog, hideDocument, useImperials, onSubmit, ...formProps } = props
  const [value, setValue] = useState<ManageCatalog>(
    catalog
      ? {
          ...catalog,
          buildingSurface: catalog.buildingSurface
            ? catalog.buildingSurface / (useImperials ? 0.9144 * 0.9144 : 1)
            : catalog.buildingSurface,
        }
      : ({ type: CatalogType.deconstruction } as ManageCatalog),
  )
  // since catalog can not change type (and not to rerender input)
  const isBuilding = useRef<boolean>(
    value.type === CatalogType.construction || value.type === CatalogType.deconstruction,
  )
  const interventionTypes = useRef<SelectOption[]>(
    value.type === CatalogType.construction
      ? createOptionsFromEnum(ConstructionInterventionType, 'catalogs:interventionType')
      : value.type === CatalogType.deconstruction
      ? createOptionsFromEnum(DeconstructionInterventionType, 'catalogs:interventionType')
      : [],
  )
  const isNeedsRef = useRef<boolean>(isNeeds(value.type as CatalogType))
  const isCreationRef = useRef(!value._id)
  const dateRequired = useRef(
    catalog?.type === CatalogType.deconstruction && catalog?.status === CatalogStatus.accepted,
  )
  const submitCatalog = useCallback(
    (value: ManageCatalog) => {
      onSubmit?.({
        ...value,
        buildingSurface: value.buildingSurface
          ? value.buildingSurface * (useImperials ? 0.9144 * 0.9144 : 1)
          : value.buildingSurface,
      })
    },
    [onSubmit, useImperials],
  )

  const informationsItems: FormItem[] = useMemo(
    () =>
      [
        {
          type: ItemType.text,
          key: 'name',
          required: true,
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.name'),
            placeholder: t('catalogs:attributes.name'),
          },
        },
        {
          type: ItemType.select,
          key: 'buildingType',
          hideItem: !isBuilding.current,
          required: true,
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.buildingType'),
            placeholder: t('global:inputs.selectPlaceholder'),
            items: createOptionsFromEnum(BuildingType, 'catalogs:buildingType'),
          },
        },
        {
          type: ItemType.address,
          key: 'location',
          required: true,
          rules: [YupUtils.FieldValidationType.fullAddress],
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.location'),
            placeholder: t('catalogs:attributes.location'),
          },
        },
        {
          type: ItemType.number,
          key: 'buildingSurface',
          hideItem: !isBuilding.current,
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.buildingSurface'),
            placeholder: t('catalogs:attributes.buildingSurface'),
            endAdornment: (
              <EndAdornmentLabel>
                {useImperials ? t('materials:unitSymbol.yd2') : t('materials:unitSymbol.m2')}
              </EndAdornmentLabel>
            ),
          },
        },
        (catalog: Catalog) => ({
          type: ItemType.text,
          key: 'createdBy',
          hideItem: !catalog?.createdBy || catalog?.organization?._id === catalog?.createdBy?._id,
          grid: { xs: 12, sm: 6 },
          formatValue: (organization: any) => {
            return organization ? organization.name : ''
          },
          props: {
            label: t('catalogs:attributes.createdBy'),
            readOnly: true,
          },
        }),
        {
          type: ItemType.asyncSelect,
          key: 'organization',
          hideItem: !isAdmin,
          grid: { xs: 12, sm: 6 },
          required: true,
          formatValue: (organization: any) => {
            return organization
              ? { label: organization.name ?? '', value: organization._id ?? '' }
              : undefined
          },
          props: {
            label: t('catalogs:attributes.organization'),
            placeholder: t('catalogs:attributes.organization'),
            minimumSearchLength: 3,
            getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
            dataValue: true,
          },
        },
        {
          type: ItemType.radio,
          key: 'interventionTypes',
          hideItem: !isBuilding.current || interventionTypes.current.length === 0,
          grid: { xs: 12 },
          props: {
            multiple: true,
            items: interventionTypes.current,
            label: t('catalogs:attributes.interventionTypes'),
          },
        },
        (catalog: Catalog) => ({
          type: ItemType.asyncSelect,
          key: 'projectOwner',
          hideItem: !isBuilding.current,
          grid: { xs: 12, sm: 6 },
          onChange: (value: any) => {
            setValue((val) => ({
              ...val,
              projectOwner: value?._id ? value : null,
              customProjectOwner: value?.isNew ? value?.value : '',
            }))
          },
          formatValue: (projectOwner: any) => {
            return catalog.customProjectOwner
              ? { label: catalog.customProjectOwner, value: catalog.customProjectOwner }
              : projectOwner
              ? { label: projectOwner.name ?? '', value: projectOwner._id ?? '' }
              : undefined
          },
          props: {
            allowNew: true,
            label: t('catalogs:attributes.projectOwner'),
            placeholder: t('catalogs:attributes.projectOwner'),
            minimumSearchLength: 3,
            getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
            dataValue: true,
          },
        }),
        {
          type: ItemType.text,
          hideItem: !isBuilding.current,
          key: 'designer',
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.designer'),
            placeholder: t('catalogs:attributes.designer'),
          },
        },
        {
          type: ItemType.text,
          hideItem: !isBuilding.current,
          key: 'amoReuse',
          grid: { xs: 12, sm: 6 },
          props: {
            label: t('catalogs:attributes.amoReuse'),
            placeholder: t('catalogs:attributes.amoReuse'),
          },
        },
        {
          type: ItemType.date,
          key: 'retrieval.startDate',
          grid: { xs: 12, sm: 6 },
          hideItem: !isNeedsRef.current,

          rules: [
            (catalog: Catalog) =>
              catalog.retrieval &&
              catalog.retrieval.startDate &&
              catalog.retrieval.endDate &&
              new Date(catalog.retrieval.endDate).getTime() <
                new Date(catalog.retrieval.startDate).getTime()
                ? t('errors:startAfterEnd')
                : undefined,
          ],
          props: {
            label: isBuilding.current
              ? t('catalogs:attributes.constructionStartDate')
              : t('catalogs:attributes.needsStartDate'),
          },
        },
        {
          type: ItemType.date,
          key: 'retrieval.endDate',
          grid: { xs: 12, sm: 6 },
          hideItem: !isNeedsRef.current,
          rules: [
            (catalog: Catalog) =>
              catalog.retrieval &&
              catalog.retrieval.startDate &&
              catalog.retrieval.endDate &&
              new Date(catalog.retrieval.endDate).getTime() <
                new Date(catalog.retrieval.startDate).getTime()
                ? t('errors:startAfterEnd')
                : undefined,
          ],
          props: {
            label: isBuilding.current
              ? t('catalogs:attributes.constructionEndDate')
              : t('catalogs:attributes.needsEndDate'),
          },
        },
        {
          key: 'materials',
          type: ItemType.group,
          props: {
            title: t('catalogs:attributes.retrieval'),
          },
          hideItem: isNeedsRef.current,
          items: [
            {
              type: ItemType.date,
              key: 'retrieval.startDate',
              grid: { xs: 12, sm: 6 },
              required: dateRequired.current,
              rules: [
                (catalog: Catalog) =>
                  catalog.retrieval &&
                  catalog.retrieval.startDate &&
                  catalog.retrieval.endDate &&
                  new Date(catalog.retrieval.endDate).getTime() <
                    new Date(catalog.retrieval.startDate).getTime()
                    ? t('errors:startAfterEnd')
                    : undefined,
              ],
              props: {
                label: t('catalogs:attributes.deconstructionStartDate'),
              },
            },
            {
              type: ItemType.date,
              key: 'retrieval.endDate',
              grid: { xs: 12, sm: 6 },
              required: dateRequired.current,
              rules: [
                (catalog: Catalog) =>
                  catalog.retrieval &&
                  catalog.retrieval.startDate &&
                  catalog.retrieval.endDate &&
                  new Date(catalog.retrieval.endDate).getTime() <
                    new Date(catalog.retrieval.startDate).getTime()
                    ? t('errors:startAfterEnd')
                    : undefined,
              ],
              props: {
                label: t('catalogs:attributes.deconstructionEndDate'),
              },
            },
            {
              type: ItemType.address,
              key: 'retrieval.location',
              grid: { xs: 12, sm: 6 },
              required: !isCreationRef.current,
              props: {
                label: t('catalogs:attributes.retrievalLocation'),
              },
            },
            {
              type: ItemType.select,
              grid: { xs: 12, sm: 6 },
              key: 'retrieval.retrievalModality',
              props: {
                label: t('catalogs:attributes.retrievalModality'),
                placeholder: t('global:inputs.selectPlaceholder'),
                items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
              },
            },
            {
              type: ItemType.text,
              key: 'retrievalInformation',
              props: {
                label: t('catalogs:attributes.retrievalInformation'),
                minRows: 3,
                multiline: true,
                placeholder: t('catalogs:attributes.retrievalInformation'),
              },
            },
          ],
        },
        {
          key: 'descriptions',
          type: ItemType.group,
          props: {
            title: t('catalogs:components.modalForm.descriptions'),
          },
          items: [
            {
              type: ItemType.date,
              key: 'constructionDate',
              hideItem: isNeedsRef.current || !isBuilding.current,
              grid: { xs: 12, sm: 6 },
              props: {
                label: t('catalogs:attributes.constructionDate'),
                format: 'MM/YYYY',
                blurredFormat: true,
              },
            },
            {
              type: ItemType.text,
              key: 'originalActivity',
              hideItem: isNeedsRef.current || !isBuilding.current,
              grid: { xs: 12, sm: 6 },
              props: {
                label: t('catalogs:attributes.originalActivity'),
                placeholder: t('catalogs:attributes.originalActivity'),
              },
            },
            (catalog: Catalog) => ({
              type: ItemType.number,
              key: 'amountOfWork',
              hideItem: !isBuilding.current,
              grid: { xs: 12, sm: 6 },
              props: {
                label: t('catalogs:attributes.amountOfWork'),
                placeholder: t('catalogs:attributes.amountOfWork'),
                endAdornment: (
                  <EndAdornmentLabel>
                    {t(
                      `global:currency.${
                        catalog?.currency ||
                        (sessionService.getCountryParam('currency') as Currency)
                      }`,
                    )}
                  </EndAdornmentLabel>
                ),
              },
            }),
            {
              type: ItemType.number,
              key: 'percentageOfNeed',
              grid: { xs: 12, sm: 6 },
              hideItem: !isNeedsRef.current || !isBuilding.current,
              props: {
                label: t('catalogs:attributes.percentageOfNeed'),
                endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
              },
            },
            (catalog: Catalog) => ({
              type: ItemType.text,
              key: 'secondHandAmount',
              hideItem: !isNeedsRef.current || !isBuilding.current,
              grid: { xs: 12, sm: 6 },
              formatValue: () => {
                return catalog.amountOfWork !== undefined && catalog.percentageOfNeed !== undefined
                  ? (catalog.amountOfWork * catalog.percentageOfNeed) / 100
                  : undefined
              },
              props: {
                label: t('catalogs:attributes.secondHandAmount'),
                placeholder: t('catalogs:attributes.secondHandAmount'),
                endAdornment: (
                  <EndAdornmentLabel>
                    {t(
                      `global:currency.${
                        catalog?.currency ||
                        (sessionService.getCountryParam('currency') as Currency)
                      }`,
                    )}
                  </EndAdornmentLabel>
                ),
                readOnly: true,
              },
            }),
            {
              type: ItemType.text,
              key: 'operationContext',
              props: {
                label: t('catalogs:attributes.operationContext'),
                minRows: 6,
                multiline: true,
                placeholder: t('catalogs:attributes.operationContext'),
              },
            },
          ],
        },
      ] as FormItem[],
    [isAdmin, t, useImperials],
  )
  const documentsItems: FormItem[] = useMemo(
    () => [
      (catalog: Catalog) => ({
        key: 'mainImage',
        type: ItemType.group,
        items: [
          {
            key: 'mainImageFileFile',
            type: ItemType.image,
            required: true,
            props: {
              fileDetail: catalog.mainImageFile,
              label: t('catalogs:components.modalForm.addPicture'),
              description: t('catalogs:components.modalForm.addPictureDescription'),
              maxFileSize: 2.5,
            },
          },
        ],
      }),
      {
        key: 'documents',
        type: ItemType.group,
        props: {
          title: t('catalogs:components.modalForm.documents'),
        },
        items: [
          {
            key: 'diagnosticFilesFile',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            hideItem: isNeedsRef.current || !isBuilding.current,
            props: {
              type: 'file',
              label: t('catalogs:attributes.diagnostics'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  diagnosticFilesFile: (state.diagnosticFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  diagnosticFilesFile: (state.diagnosticFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              maxFileSize: 2.5,
            },
          },
          {
            key: 'plans',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            hideItem: !isNeedsRef.current || !isBuilding.current,

            props: {
              type: 'plan',
              label: t('catalogs:attributes.plans.title'),
              onSelectFiles: (newFiles: File[]) => {
                setValue((state) => ({
                  ...state,
                  plans: (state.plans ?? []).concat(
                    newFiles.map((file) => ({ file, name: file.name, scale: 1, angle: 0 })),
                  ),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  plans: (state.plans ?? []).filter((_, fIndex) => fIndex !== index),
                }))
              },
              maxFileSize: 2.5,
            },
          },
          {
            key: 'imageFilesFile',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            props: {
              type: 'image',
              label: t('catalogs:attributes.images'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              maxFileSize: 2.5,
            },
          },
          {
            key: 'otherFilesFile',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            props: {
              type: 'file',
              label: t('catalogs:attributes.otherFiles'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  otherFilesFile: (state.otherFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  otherFilesFile: (state.otherFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              maxFileSize: 2.5,
            },
          },
        ] as FormItem[],
      },
    ],
    [t],
  )

  const steps: { title: string; items: FormItem[] }[] = useMemo(
    () => [
      { title: t('catalogs:components.modalForm.informations'), items: informationsItems },
      {
        title: t('catalogs:attributes.mainImage'),
        items: documentsItems,
      },
    ],
    [t, documentsItems, informationsItems],
  )

  return (
    <ModalForm
      {...formProps}
      value={value}
      setValue={setValue}
      steps={hideDocument ? undefined : steps}
      items={hideDocument ? informationsItems : undefined}
      onSubmit={submitCatalog}
      maxWidth="md"
    />
  )
}
export default ModalFormCatalog
