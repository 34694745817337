import { Box, InputLabel, MenuItem, FormHelperText } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import StyledSelectInput from './StyledSelect.input'

import { SelectProps } from '../../../models/props.models'

const SelectInput: React.FC<SelectProps> = (props): JSX.Element => {
  const {
    disabled,
    error,
    onClick,
    onChange,
    items,
    label,
    placeholder,
    readOnly,
    required,
    maxWidth,
    size = 'normal',
    value = null,
  } = props

  return (
    <Box display="flex" flexDirection="column" onClick={onClick} position="relative">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <StyledSelectInput
        color="primary"
        onChange={(evt) => onChange?.(evt.target.value)}
        variant="standard"
        disableUnderline
        displayEmpty
        fullWidth
        maxWidth={maxWidth}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        SelectDisplayProps={{
          'aria-label': size === 'large' ? 'select-large' : undefined,
        }}
        MenuProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          style: { marginTop: '50px' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        renderValue={(selected: unknown) => {
          const selectedValue = selected as string | undefined
          if (!selectedValue) return placeholder
          return items?.find((it) => it.value === selectedValue)?.label
        }}
        IconComponent={KeyboardArrowDownIcon}
        value={value}>
        {items?.map((item) => (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </StyledSelectInput>
      {typeof error === 'string' && (
        <FormHelperText error sx={{ position: 'absolute', bottom: '-2px', left: '5px' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

export default SelectInput
