import { useTranslation } from 'react-i18next'
import { useReducer, useEffect, useCallback, useMemo } from 'react'
import { IconButton, Grid, InputAdornment } from '@mui/material'
import { Delete as DeleteIcon, Preview as PreviewIcon } from '@mui/icons-material'
import {
  LocatedMaterial,
  MaterialQuality,
  Material,
  isLinkToRoomDimension,
  getRoomDimension,
  checkMaterialQuantities,
  Unit,
} from '../../../models/materials.models'
import { Room } from '../../../models/catalogs.models'
import NumberTextFieldComponent from '../../common/input/Number.input'
import TextFieldComponent from '../../common/input/Text.input'
import SelectComponent from '../../common/input/Select.input'
import { createOptionsFromEnum } from '../../../utils/i18n.utils'
import CheckboxInput from '../../common/input/Checkbox.input'
import { traduceWasteCategory } from '../../../models/pemd.model'

type DebounceReducer = {
  timeout?: any
  locatedMaterial: LocatedMaterial
  error: string
}
type DebounceReducerAction =
  | { type: 'edit'; update: Partial<LocatedMaterial>; timeout: any; error: string }
  | { type: 'init'; locatedMaterial: LocatedMaterial }
  | { type: 'saved' }

type LocatedMaterialToolbarProps = {
  material: Material
  rooms: Room[]
  planScale: number
  canUpdate: boolean
  showCerfa: boolean
  locatedMaterial: LocatedMaterial
  onDelete: () => void
  onReveal: () => void
  onEdit: (locatedMaterial: LocatedMaterial) => void
  useImperials: boolean
}
const LocatedMaterialToolbar: React.FC<LocatedMaterialToolbarProps> = ({
  rooms,
  planScale,
  material,
  showCerfa,
  canUpdate,
  locatedMaterial,
  onEdit,
  onReveal,
  onDelete,
  useImperials,
}): JSX.Element => {
  const { t } = useTranslation()
  const showLinkToRoom = isLinkToRoomDimension(material)
  const [debounce, dispatch] = useReducer(
    (debounceData: DebounceReducer, action: DebounceReducerAction): DebounceReducer => {
      if (debounceData.timeout) {
        clearTimeout(debounceData.timeout)
      }
      switch (action.type) {
        case 'init':
          const factor =
            useImperials && material.unit === Unit.linearMeters
              ? 3.28084
              : useImperials && material.unit === Unit.squareMeters
              ? 3.28084 * 3.28084
              : useImperials && material.unit === Unit.cubicMeters
              ? 3.28084 * 3.28084 * 3.28084
              : 1
          return {
            locatedMaterial: {
              ...action.locatedMaterial,
              quantity: action.locatedMaterial.quantity * factor,
            },
            timeout: undefined,
            error: '',
          }
        case 'saved':
          return {
            locatedMaterial: debounceData.locatedMaterial,
            timeout: undefined,
            error: '',
          }
        case 'edit':
          let newVal = { ...debounceData.locatedMaterial, ...action.update }
          return {
            locatedMaterial: newVal,
            timeout: action.timeout,
            error: action.error,
          }
      }
    },
    {
      locatedMaterial,
      timeout: undefined,
      error: '',
    },
  )
  const unit = useMemo(() => {
    if (useImperials) {
      if (material.unit === Unit.linearMeters) {
        return t('materials:unitSymbol.lft')
      } else if (material.unit === Unit.squareMeters) {
        return t('materials:unitSymbol.ft2')
      } else if (material.unit === Unit.cubicMeters) {
        return t('materials:unitSymbol.ft3')
      }
    }
    return t(`materials:unitSymbol.${material.unit}`)
  }, [useImperials, material.unit, t])

  useEffect(() => {
    const factor =
      useImperials && material.unit === Unit.linearMeters
        ? 3.28084
        : useImperials && material.unit === Unit.squareMeters
        ? 3.28084 * 3.28084
        : useImperials && material.unit === Unit.cubicMeters
        ? 3.28084 * 3.28084 * 3.28084
        : 1
    if (debounce.locatedMaterial._id !== locatedMaterial._id) {
      if (debounce.timeout) {
        const factor =
          useImperials && material.unit === Unit.linearMeters
            ? 3.28084
            : useImperials && material.unit === Unit.squareMeters
            ? 3.28084 * 3.28084
            : useImperials && material.unit === Unit.cubicMeters
            ? 3.28084 * 3.28084 * 3.28084
            : 1
        onEdit({
          ...debounce.locatedMaterial,
          quantity: Number(debounce.locatedMaterial.quantity) / factor,
        })
      }

      dispatch({
        type: 'init',
        locatedMaterial,
      })
    } else if (
      (debounce.locatedMaterial.quantity !== locatedMaterial.quantity * factor ||
        debounce.locatedMaterial.quality !== locatedMaterial.quality ||
        debounce.locatedMaterial.cerfaWaste !== locatedMaterial.cerfaWaste ||
        debounce.locatedMaterial.reusable !== locatedMaterial.reusable) &&
      !debounce.timeout &&
      !debounce.error
    ) {
      // case where locatedMaterial has been change outside this component (undo/redo)
      dispatch({
        type: 'init',
        locatedMaterial,
      })
    }
  }, [onEdit, debounce, locatedMaterial, useImperials, material.unit])

  const inputHandler = (
    type: 'quantity' | 'quality' | 'linkToRoom' | 'cerfaWaste' | 'reusable',
    value?: string | number | boolean,
  ) => {
    let newValue: string | number | boolean
    const factor =
      useImperials && material.unit === Unit.linearMeters
        ? 3.28084
        : useImperials && material.unit === Unit.squareMeters
        ? 3.28084 * 3.28084
        : useImperials && material.unit === Unit.cubicMeters
        ? 3.28084 * 3.28084 * 3.28084
        : 1
    if (type === 'quantity') {
      // convert imperial to metter
      newValue = Number(value) / factor
    } else if (type === 'cerfaWaste') {
      newValue = Number(value ?? 0)
    } else if (type === 'quality') {
      newValue = value || ''
    } else {
      newValue = value ?? true
    }
    let error =
      type === 'quantity' && (Number.isNaN(newValue) || Number(newValue) <= 0)
        ? t('errors:positive')
        : ''

    let newRoomQuantity: number | undefined
    if (type === 'linkToRoom' && value !== false && showLinkToRoom) {
      const room = rooms.find((room: Room) => room._id === locatedMaterial.room)
      newRoomQuantity = getRoomDimension(planScale, material, room)
    }

    if (type !== 'quality' && (type !== 'linkToRoom' || newRoomQuantity) && !error) {
      const missingQuantity = checkMaterialQuantities(
        material.quantities.map((quantity) =>
          quantity._id !== locatedMaterial._id
            ? quantity
            : {
                ...quantity,
                initial: true,
                [type]: newValue,
                quantity:
                  newRoomQuantity ??
                  (type === 'quantity' ? (newValue as number) : quantity.quantity),
              },
        ),
        material.cerfaWaste,
      )
      if (missingQuantity) {
        error = t('errors:missingQuantity', { data: [missingQuantity] })
      }
    }
    dispatch({
      type: 'edit',
      update: {
        linkToRoom: type === 'quantity' ? false : debounce.locatedMaterial.linkToRoom,
        // send imperial if used
        quantity: newRoomQuantity ? newRoomQuantity * factor : debounce.locatedMaterial.quantity,
        // send imperial if used
        [type]: value,
      },
      error,
      timeout: error
        ? undefined
        : setTimeout(() => {
            onEdit({
              ...debounce.locatedMaterial,
              quantity: Number(debounce.locatedMaterial.quantity) / factor,
              //send converted value
              [type]: newValue,
            })
            dispatch({ type: 'saved' })
          }, 500),
    })
  }
  const getCerfaName = useCallback(
    (cerfaWasteIndex: number) => {
      const cerfa = material.cerfaWaste?.[cerfaWasteIndex]
      if (!cerfa) {
        return ''
      }
      return cerfa.tertiaryCategory
        ? traduceWasteCategory(cerfa.tertiaryCategory)
        : cerfa.secondaryCategory
        ? traduceWasteCategory(cerfa.secondaryCategory)
        : cerfa.primaryCategory
        ? traduceWasteCategory(cerfa.primaryCategory)
        : t('materials:attributes.cerfaWaste.labelIndex', {
            index: cerfaWasteIndex,
          })
    },
    [material.cerfaWaste, t],
  )

  return (
    <Grid container spacing={0}>
      <Grid item xs={11}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={3} lg={1}>
            <img
              src={material.mainImageFile.src || material.mainImageFile.path}
              draggable={false}
              height="70px"
              width="70px"
              style={{ objectFit: 'cover' }}
              alt=""
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextFieldComponent
              fullWidth
              value={material.name}
              label={t('materials:attributes.name')}
              readOnly
            />
          </Grid>
          <Grid item xs={5} lg={2}>
            <NumberTextFieldComponent
              fullWidth
              readOnly={!canUpdate}
              label={t('materials:attributes.quantities.quantity')}
              placeholder={t('materials:attributes.quantities.quantity')}
              onChange={inputHandler.bind(null, 'quantity')}
              showControl
              endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
              error={debounce.error}
              value={debounce.locatedMaterial.quantity}
            />
          </Grid>

          {!material.wasteOnly && (
            <>
              {showCerfa && (
                <Grid item xs={6} lg={2} display="flex" alignItems="center">
                  <CheckboxInput
                    readOnly={!canUpdate}
                    value={debounce.locatedMaterial.reusable !== false}
                    onChange={inputHandler.bind(null, 'reusable')}
                    label={t('materials:attributes.quantities.reusable')}
                  />
                </Grid>
              )}
              <Grid item xs={6} lg={2}>
                <SelectComponent
                  readOnly={!canUpdate}
                  label={t('materials:attributes.quantities.quality')}
                  placeholder={t('materials:attributes.quantities.quality')}
                  onChange={inputHandler.bind(null, 'quality')}
                  items={createOptionsFromEnum(MaterialQuality, 'materials:quality')}
                  value={debounce.locatedMaterial.quality}
                />
              </Grid>
            </>
          )}
          {showCerfa && material.cerfaWaste && material.cerfaWaste.length > 1 && (
            <Grid item xs={6} lg={2}>
              <SelectComponent
                readOnly={!canUpdate}
                value={debounce.locatedMaterial.cerfaWaste ?? 0}
                onChange={inputHandler.bind(null, 'cerfaWaste')}
                label={t('materials:attributes.quantities.cerfaWaste')}
                placeholder={getCerfaName(0)}
                items={material.cerfaWaste?.map((_: any, index: number) => {
                  return {
                    value: index,
                    label: getCerfaName(index),
                  }
                })}
              />
            </Grid>
          )}
          {showLinkToRoom && (
            <Grid item xs={6} lg={3} display="flex" alignItems="center">
              <CheckboxInput
                readOnly={!canUpdate}
                value={debounce.locatedMaterial.linkToRoom !== false}
                onChange={inputHandler.bind(null, 'linkToRoom')}
                label={t('materials:attributes.quantities.linkToRoom')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <IconButton onClick={onReveal} size="small" sx={{ marginTop: '30px' }}>
          <PreviewIcon />
        </IconButton>
        {canUpdate && (
          <IconButton onClick={onDelete} size="small" sx={{ marginTop: '30px' }}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}
export default LocatedMaterialToolbar
