import { Box, Checkbox, FormControlLabel, styled, InputLabel, FormHelperText } from '@mui/material'
import { CheckboxProps } from '../../../models/props.models'
import { useCallback } from 'react'
import Tooltip from '../Tooltip.common'
import { Info as InfoIcon } from '@mui/icons-material'
import Stack from '../Stack.common'

const CheckboxContainer = styled(FormControlLabel, {
  shouldForwardProp: (props) => props !== 'hasTooltip',
})<{ hasTooltip?: boolean }>(({ hasTooltip }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  '& > span:first-of-type': {
    marginTop: hasTooltip ? '5px' : '-1px',
  },
  '& .MuiTypography-root:first-of-type': {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
}))

const CheckboxInput: React.FC<CheckboxProps> = ({
  label,
  value,
  disabled,
  error,
  falseValue,
  onChange,
  readOnly,
  required,
  children,
  tooltip,
}) => {
  const click = useCallback(() => {
    if (!readOnly) {
      if (falseValue) {
        onChange?.(value === true ? false : value === false ? undefined : true)
      } else {
        onChange?.(!value)
      }
    }
  }, [value, falseValue, readOnly, onChange])
  return (
    <Box pl="10px" display="flex" flexDirection="column">
      <CheckboxContainer
        hasTooltip={!!tooltip}
        control={
          <Checkbox color="primary" size="small" indeterminate={falseValue && value === false} />
        }
        labelPlacement="end"
        onChange={click}
        checked={!!value ?? false}
        disabled={disabled}
        label={
          <Box ml="8px" onClick={click}>
            {!!label && (
              <InputLabel error={!!error}>
                <Stack direction="row" alignItems="center" spacing="6px">
                  <Box> {label + (required ? '*' : '')}</Box>

                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <InfoIcon color="primary" />
                    </Tooltip>
                  )}
                </Stack>
              </InputLabel>
            )}
            {!!children && children}
          </Box>
        }
      />
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default CheckboxInput
