import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  InsertPhoto as InsertPhotoIcon,
  LocationOn as LocationOnIcon,
  AccountBox as AccountBoxIcon,
  Apartment as ApartmentIcon,
  CalendarMonth as CalendarMonthIcon,
} from '@mui/icons-material'

import Constants from '../../constants'
import {
  convert2Imperials,
  Material,
  MaterialType,
  TermsOfSale,
  Unit,
} from '../../models/materials.models'
import { getPlatform } from '../../models/platforms.models'
import { Catalog } from '../../models/catalogs.models'
import { Actions } from '../../models/props.models'
import Card from '../common/Card.common'
import dayjs from '../../utils/dayjs.utils'
import AddToCart from './AddToCart.material'
import { useCallback, useMemo } from 'react'

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const NameLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 600,
})
const ReferenceLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 300,
})
const StatusLabel = styled(OneLineTypography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 400,
}))
const AvailableLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.775rem',
  fontWeight: 500,
  color: theme.palette.availableQuantity,
  '& > em': { color: theme.palette.primary.main },
}))
const DesiredLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.desiredQuantity,
}))
const TotalLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))
export const PriceLabel = styled(OneLineTypography)({
  backgroundColor: 'black',
  borderRadius: '4px',
  color: 'white',
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: '4px 6px',
  width: 'fit-content',
})
const OtherInfoLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  height: '1rem',
  color: theme.palette.text.secondary,
}))

interface MaterialCardProps {
  value: Material
  addToCart?: (quantity: number) => void
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
  isPublic?: boolean
  showCerfa?: boolean
  isCatalogPage?: boolean
  isAdmin?: boolean
  useImperials: boolean
}

const MaterialCard: React.FC<MaterialCardProps> = ({
  value,
  onClick,
  actions,
  onSelect,
  selected,
  isPublic,
  showCerfa,
  isCatalogPage,
  isAdmin,
  addToCart,
  useImperials,
}): JSX.Element => {
  const isNew =
    value.createdAt &&
    dayjs().diff(value.createdAt, 'day') < getPlatform(Constants.platform).isNewMaterialDelay
  const material = useMemo<Material>(
    () => (useImperials ? convert2Imperials(value) : value),
    [useImperials, value],
  )
  const addToCartConverted = useCallback(
    (quantity: number) => {
      const factor =
        value.unit === Unit.linearMeters
          ? 3.28084
          : value.unit === Unit.squareMeters
          ? 3.28084 * 3.28084
          : value.unit === Unit.cubicMeters
          ? 3.28084 * 3.28084 * 3.28084
          : 1
      addToCart?.(quantity / (useImperials ? factor : 1))
    },
    [value.unit, addToCart, useImperials],
  )

  const { t } = useTranslation()
  return (
    <Card
      ariaLabel={material.type === MaterialType.resource ? 'resource-card' : 'need-card'}
      imagePlaceholder={
        <Box width="100%" height="100%" display="flex">
          <InsertPhotoIcon
            sx={{ fontSize: '4rem', margin: 'auto', color: Constants.colors.lightBorder }}
          />
        </Box>
      }
      chips={
        isPublic && isNew
          ? [
              {
                label: t('global:actions.isNew'),
                classic: true,
                color: 'secondary',
              },
            ]
          : undefined
      }
      image={material.mainImageFile?.src || material.mainImageFile?.path}
      onClick={onClick}
      selected={selected}
      onSelect={onSelect}
      actions={actions}>
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <NameLabel>{material.name}</NameLabel>
          {material.quality && (
            <Box>
              <StatusLabel>
                {material.quality ? t(`materials:quality.${material.quality}`) : ''}
              </StatusLabel>
            </Box>
          )}
        </Box>
        <Box mt="6px">
          <ReferenceLabel>
            {t('materials:details.reference', { reference: material.reference })}
          </ReferenceLabel>
        </Box>
        <Box mt="4px">
          {material.type === MaterialType.resource ? (
            <AvailableLabel>
              {t('materials:details.quantityAvailable', {
                count: material.currentQty || 0,
                unit: t(`materials:unitSymbol.${material.unit}`),
              })}
              {!!material.reservedQty && !Constants.getIsLocal() && (
                <>
                  &nbsp;
                  <em>
                    {t('materials:details.quantityReserved', {
                      count: material.reservedQty,
                      unit: t(`materials:unitSymbol.${material.unit}`),
                    })}
                  </em>
                </>
              )}
            </AvailableLabel>
          ) : (
            <DesiredLabel>
              {t('materials:details.desiredQuantity', {
                quantity: material.initialQty || 0,
                unit: t(`materials:unitSymbol.${material.unit}`),
              })}
            </DesiredLabel>
          )}
          {showCerfa && !isPublic && value.type === MaterialType.resource && (
            <TotalLabel>
              {t('materials:details.totalQuantity', {
                quantity: value.totalQty ?? value.initialQty,
                unit: t(`materials:unitSymbol.${value.unit}`),
              })}
            </TotalLabel>
          )}
        </Box>
        {material.type === MaterialType.resource && (
          <Box position="absolute" top="125px" right="5px">
            <PriceLabel>
              <>
                {material.visible === false && t('materials:termsOfSale.invisible')}
                {material.visible !== false &&
                  material.termsOfSale === TermsOfSale.sale &&
                  t('materials:details.price', {
                    price: material.price,
                    currency: t(`global:currency.${material.currency}`),
                    unit: t(`materials:unitSymbol.${material.unit}`),
                  })}
                {material.visible !== false &&
                  material.termsOfSale === TermsOfSale.notDefined &&
                  t('materials:details.priceNotDefined')}
                {material.visible !== false &&
                  material.termsOfSale === TermsOfSale.donation &&
                  t('materials:details.donation')}
              </>
            </PriceLabel>
          </Box>
        )}
        {!isCatalogPage && isAdmin && (
          <>
            <Box display="flex" alignItems="center" mt="4px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="15px"
                width="15px"
                mr="4px">
                <AccountBoxIcon
                  style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
                />
              </Box>
              <OtherInfoLabel>{material.catalog?.organization?.name || ''}</OtherInfoLabel>
            </Box>
            <Box display="flex" alignItems="center" mt="4px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="15px"
                width="15px"
                mr="4px">
                <ApartmentIcon
                  style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
                />
              </Box>
              <OtherInfoLabel>{(material.catalog as Catalog)?.name || ''}</OtherInfoLabel>
            </Box>
          </>
        )}
        {!isCatalogPage &&
          material.retrieval?.location &&
          material.type === MaterialType.resource && (
            <Box display="flex" alignItems="center" mt="4px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="15px"
                width="15px"
                mr="4px">
                <LocationOnIcon
                  style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
                />
              </Box>
              <OtherInfoLabel>
                {material.retrieval.location
                  ? `${material.retrieval.location.city}, ${material.retrieval.location.postalCode}`
                  : ''}
              </OtherInfoLabel>
            </Box>
          )}
        {isPublic && (
          <>
            <Box display="flex" alignItems="center" mt="4px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="15px"
                width="15px"
                mr="4px">
                <CalendarMonthIcon
                  style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
                />
              </Box>
              <OtherInfoLabel sx={{ whiteSpace: 'break-spaces', height: 'auto' }}>
                {t('materials:components.card.availabelFromTo', {
                  from: new Date(material.retrieval?.startDate || 'invalid'),
                  to: new Date(material.retrieval?.endDate || 'invalid'),
                })}
              </OtherInfoLabel>
            </Box>

            {material.type !== MaterialType.need && (
              <AddToCart
                addToCart={addToCartConverted}
                minQuantity={material.minQuantity}
                sellByQuantityOf={material.sellByQuantityOf}
                currentQty={material.currentQty}
                unit={material.unit}
              />
            )}
          </>
        )}
      </>
    </Card>
  )
}
export default MaterialCard
