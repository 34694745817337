import { useObservable } from '@ngneat/react-rxjs'
import { categoriesQuery } from '../../store/categories'
import { Category } from '../../models/categories.models'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  FormHelperText,
  InputLabel,
  Backdrop,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  IconButton,
  useMediaQuery,
  ListItemIcon,
  Divider,
  styled,
  InputAdornment,
} from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import StyledInput from '../common/input/Styled.input'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../../models/props.models'
import {
  checkResourceCategory,
  checkWasteCategory,
  getResourceCategoryChoices,
  getWasteCategoryChoices,
  traduceResourceCategory,
  traduceWasteCategory,
} from '../../models/pemd.model'

const Input = styled(StyledInput)({
  input: {
    textOverflow: 'ellipsis',
    direction: 'rtl',
    textAlign: 'left',
  },
})

type BaseInputCategoryProps = {
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  error?: string | boolean
  required?: boolean
  accurateCategory?: boolean
}
type InputCategoryProps =
  | (BaseInputCategoryProps & {
      type?: 'raedificare' | undefined
      primaryCategory?: Category
      secondaryCategory?: Category
      tertiaryCategory?: Category
      onChange: (value: {
        primaryCategory?: Category
        secondaryCategory?: Category
        tertiaryCategory?: Category
      }) => void
    })
  | (BaseInputCategoryProps & {
      type: 'cerfaResource' | 'cerfaWaste'
      primaryCategory?: number
      secondaryCategory?: number
      tertiaryCategory?: number
      onChange: (value: {
        primaryCategory?: number
        secondaryCategory?: number
        tertiaryCategory?: number
      }) => void
    })

const paddingY = 4
const paddingX = 8
const optionWidth = 300
const CategoryMenu = ({
  type,
  label,
  onClose,
  inputRef,
  accurateCategory,
  primaryCategory,
  secondaryCategory,
  tertiaryCategory,
  onChange,
}: InputCategoryProps & { onClose: () => void; inputRef: MutableRefObject<any> }) => {
  const theme = useTheme()
  const mediaDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [forceSM, setForceSm] = useState<boolean>(false)
  const downSm = useMemo(() => {
    return mediaDownSm || forceSM
  }, [mediaDownSm, forceSM])
  const { t } = useTranslation()
  const menuRef = useRef<any>(null)

  const isTouch = 'ontouchstart' in document.documentElement
  const [opened, setOpened] = useState<{
    primary?: SelectOption
    secondary?: SelectOption
  }>({
    primary: undefined,
    secondary: undefined,
  })

  const [menuPosition, setMenuPosition] = useState<{
    top?: string
    left?: string
    bottom?: string
    right?: string
  }>({
    top: (inputRef.current?.getBoundingClientRect?.()?.bottom ?? 0) + paddingY + 'px' ?? 0,
    left: (inputRef.current?.getBoundingClientRect?.()?.left ?? 0) + 'px' ?? 0,
  })

  const [primaryCategories] = useObservable(categoriesQuery.primaryCategories)
  const [secondaryCategories] = useObservable(categoriesQuery.secondaryCategories)
  const [tertiaryCategories] = useObservable(categoriesQuery.tertiaryCategories)
  const getChildren = useCallback(
    (option?: SelectOption, primary = true): SelectOption[] => {
      if (primary) {
        switch (type) {
          case 'cerfaResource':
            return getResourceCategoryChoices(option?.value)
          case 'cerfaWaste':
            return getWasteCategoryChoices(option?.value)
          default:
            return secondaryCategories
              .filter((category: Category) => option?.value === category.parent)
              .map((category: Category) => ({
                label: t(`categories:name.${category.name}` as any),
                value: category._id,
              }))
        }
      } else {
        switch (type) {
          case 'cerfaResource':
            return getResourceCategoryChoices(option?.value)
          case 'cerfaWaste':
            return getWasteCategoryChoices(option?.value)
          default:
            return tertiaryCategories
              .filter((category: Category) => option?.value === category.parent)
              .map((category: Category) => ({
                label: t(`categories:name.${category.name}` as any),
                value: category._id,
              }))
        }
      }
    },
    [t, type, secondaryCategories, tertiaryCategories],
  )
  const primaryCategoryOptions = useMemo<SelectOption[]>(() => {
    switch (type) {
      case 'cerfaResource':
        return getResourceCategoryChoices()
      case 'cerfaWaste':
        return getWasteCategoryChoices()
      default:
        return primaryCategories.map((category: Category) => ({
          label: t(`categories:name.${category.name}` as any),
          value: category._id,
        }))
    }
  }, [t, primaryCategories, type])
  const secondaryCategoryOptions = useMemo<SelectOption[]>(() => {
    return getChildren(opened.primary)
  }, [getChildren, opened])
  const tertiaryCategoryOptions = useMemo<SelectOption[]>(() => {
    return getChildren(opened.secondary, false)
  }, [getChildren, opened])

  useEffect(() => {
    setOpened({
      primary: !secondaryCategory
        ? undefined
        : type === 'cerfaResource'
        ? {
            label: traduceResourceCategory(primaryCategory!),
            value: primaryCategory,
          }
        : type === 'cerfaWaste'
        ? {
            label: traduceWasteCategory(primaryCategory!),
            value: primaryCategory,
          }
        : {
            label: t(`categories:name.${(primaryCategory as Category).name}` as any),
            value: (primaryCategory as Category)._id,
          },
      secondary: !tertiaryCategory
        ? undefined
        : type === 'cerfaResource'
        ? {
            label: traduceResourceCategory(secondaryCategory!),
            value: secondaryCategory,
          }
        : type === 'cerfaWaste'
        ? {
            label: traduceWasteCategory(secondaryCategory!),
            value: secondaryCategory,
          }
        : {
            label: t(`categories:name.${(secondaryCategory as Category).name}` as any),
            value: (secondaryCategory as Category)._id,
          },
    })
  }, [t, type, primaryCategory, secondaryCategory, tertiaryCategory])

  const maxWidth = useMemo(() => {
    return (type === 'cerfaResource' ? 2 : 3) * (optionWidth + paddingX * 2)
  }, [type])
  const maxHeight = useMemo(() => {
    let maxOption = primaryCategoryOptions.length
    primaryCategoryOptions.forEach((primary: SelectOption) => {
      let secondaries = getChildren(primary)
      maxOption = Math.max(maxOption, secondaries.length)
      secondaries.forEach((secondary: SelectOption) => {
        maxOption = Math.max(maxOption, getChildren(secondary, false).length)
      })
    })
    return maxOption * 37
  }, [primaryCategoryOptions, getChildren])

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        const box = menuRef.current?.getBoundingClientRect?.()

        if (maxHeight > window.innerHeight || maxWidth > window.innerWidth) {
          setForceSm(true)
        } else if (box) {
          const top =
            box.top + maxHeight > window.innerHeight
              ? window.innerHeight - maxHeight - 5
              : inputRef.current?.getBoundingClientRect?.()?.bottom + paddingY ?? 0
          const left =
            box.left + maxWidth > window.innerWidth
              ? window.innerWidth - maxWidth - 5
              : inputRef.current?.getBoundingClientRect?.()?.left ?? 0

          setMenuPosition({
            top: `${top}px`,
            left: `${left}px`,
          })
        }
      })
    }

    if (!downSm) {
      handleResize()
      window.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleResize)
    } else {
      setMenuPosition({
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      })
    }
    return () => {
      if (!downSm) {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('scroll', handleResize)
      }
    }
  }, [downSm, inputRef, forceSM, maxWidth, maxHeight])

  const select = useCallback(
    (
      primaryCategory?: SelectOption,
      secondaryCategory?: SelectOption,
      tertiaryCategory?: SelectOption,
    ) => {
      let primary
      let secondary
      let tertiary
      let isAccurate
      switch (type) {
        case 'cerfaResource':
          primary = primaryCategory ? primaryCategory.value : undefined
          secondary = secondaryCategory ? secondaryCategory.value : undefined
          tertiary = tertiaryCategory ? tertiaryCategory.value : undefined
          isAccurate = !!primary && checkResourceCategory(primary, secondary, tertiary)
          break
        case 'cerfaWaste':
          primary = primaryCategory ? primaryCategory.value : undefined
          secondary = secondaryCategory ? secondaryCategory.value : undefined
          tertiary = tertiaryCategory ? tertiaryCategory.value : undefined

          isAccurate = !!primary && checkWasteCategory(primary, secondary, tertiary)
          break
        default:
          primary = primaryCategories.find(
            (primaryCategoryOpt) => primaryCategoryOpt._id === primaryCategory?.value,
          )
          secondary = secondaryCategories.find(
            (secondaryCategoryOpt) => secondaryCategoryOpt._id === secondaryCategory?.value,
          )
          tertiary = tertiaryCategories.find(
            (tertiaryCategoryOpt) => tertiaryCategoryOpt._id === tertiaryCategory?.value,
          )
          isAccurate = !!tertiary
      }
      console.log(isAccurate, {
        primaryCategory: primary,
        secondaryCategory: secondary,
        tertiaryCategory: tertiary,
      })
      if (!accurateCategory || isAccurate) {
        onChange?.({
          primaryCategory: primary,
          secondaryCategory: secondary,
          tertiaryCategory: tertiary,
        } as any)
        onClose()
      } else {
        setOpened(() => ({ primary: primaryCategory, secondary: secondaryCategory }))
      }
    },
    [
      type,
      accurateCategory,
      onChange,
      onClose,
      primaryCategories,
      secondaryCategories,
      tertiaryCategories,
    ],
  )
  const isEqual = useCallback(
    (category: Category | number | undefined, option: SelectOption) => {
      switch (type) {
        case 'cerfaResource':
        case 'cerfaWaste':
          return (category as number) === option.value
        default:
          return (category as Category)?._id === option.value
      }
    },
    [type],
  )

  return createPortal(
    <Backdrop sx={{ zIndex: 100000 }} open invisible onClick={onClose}>
      <Paper
        ref={menuRef}
        onClick={(e: any) => e.stopPropagation()}
        sx={{
          position: 'absolute',
          ...menuPosition,
          display: 'flex',
        }}>
        {(!opened.primary || !downSm) && (
          <List sx={{ width: downSm ? '100%' : undefined }}>
            {downSm && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={onClose}>
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                    {label}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
            {primaryCategoryOptions?.map((primary: SelectOption) => (
              <ListItem
                disablePadding
                key={primary.value}
                secondaryAction={
                  isTouch && !accurateCategory && getChildren(primary).length > 0 ? (
                    <IconButton
                      edge="end"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        setOpened(() => ({ primary: primary, secondary: undefined }))
                      }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  ) : undefined
                }>
                <ListItemButton
                  selected={
                    opened.primary?.value === primary.value ||
                    (downSm && isEqual(primaryCategory, primary))
                  }
                  onClick={() => select(primary)}
                  onMouseOver={() => {
                    if (!downSm) {
                      setOpened(() => ({ primary: primary, secondary: undefined }))
                    }
                  }}
                  sx={{
                    paddingLeft: `${paddingX}px`,
                    paddingRight: `${paddingX}px`,
                    paddingTop: `${paddingY}px`,
                    paddingBottom: `${paddingY}px`,
                  }}>
                  <ListItemText
                    title={primary.label}
                    primary={primary.label}
                    sx={
                      downSm
                        ? {}
                        : {
                            textOverflow: 'ellipsis',
                            maxWidth: `${optionWidth}px`,
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                          }
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {opened.primary && (!opened.secondary || !downSm) && (
          <Paper elevation={downSm ? 0 : 1} sx={{ width: downSm ? '100%' : undefined }}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        setOpened(() => ({ primary: undefined, secondary: undefined }))
                      }>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {opened.primary.label}
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}
              {secondaryCategoryOptions?.map((secondary) => (
                <ListItem
                  disablePadding
                  key={secondary.value}
                  secondaryAction={
                    isTouch && !accurateCategory && getChildren(secondary, false).length > 0 ? (
                      <IconButton
                        edge="end"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setOpened((opened: any) => ({
                            ...opened,
                            secondary: secondary,
                          }))
                        }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    ) : undefined
                  }>
                  <ListItemButton
                    selected={
                      opened.secondary?.value === secondary.value ||
                      (downSm && isEqual(secondaryCategory, secondary))
                    }
                    onClick={() => select(opened.primary, secondary)}
                    onMouseOver={() => {
                      if (!downSm) {
                        setOpened((opened: any) => ({ ...opened, secondary: secondary }))
                      }
                    }}
                    sx={{
                      paddingLeft: `${paddingX}px`,
                      paddingRight: `${paddingX}px`,
                      paddingTop: `${paddingY}px`,
                      paddingBottom: `${paddingY}px`,
                    }}>
                    <ListItemText
                      title={secondary.label}
                      primary={secondary.label}
                      sx={
                        downSm
                          ? {}
                          : {
                              textOverflow: 'ellipsis',
                              maxWidth: `${optionWidth}px`,
                              textWrap: 'nowrap',
                              overflow: 'hidden',
                            }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {opened.secondary && (
          <Paper elevation={downSm ? 0 : 1} sx={{ width: downSm ? '100%' : undefined }}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => setOpened((opened) => ({ ...opened, secondary: undefined }))}>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {opened.secondary.label}
                    </ListItemButton>
                  </ListItem>{' '}
                  <Divider />
                </>
              )}
              {tertiaryCategoryOptions?.map((tertiary) => (
                <ListItem disablePadding key={tertiary.value}>
                  <ListItemButton
                    selected={isEqual(tertiaryCategory, tertiary)}
                    onClick={() => select(opened.primary, opened.secondary, tertiary)}
                    sx={{
                      paddingLeft: `${paddingX}px`,
                      paddingRight: `${paddingX}px`,
                      paddingTop: `${paddingY}px`,
                      paddingBottom: `${paddingY}px`,
                    }}>
                    <ListItemText
                      title={tertiary.label}
                      primary={tertiary.label}
                      sx={
                        downSm
                          ? {}
                          : {
                              textOverflow: 'ellipsis',
                              maxWidth: `${optionWidth}px`,
                              textWrap: 'nowrap',
                              overflow: 'hidden',
                            }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Paper>
    </Backdrop>,
    document.getElementById('category-modal') as HTMLElement,
  )
}

const InputCategory: React.FC<InputCategoryProps> = (props) => {
  const {
    type,
    primaryCategory,
    secondaryCategory,
    tertiaryCategory,
    error,
    label,
    placeholder,
    required,
    disabled,
    readOnly,
    onChange,
  } = props

  const inputRef = useRef<any>(null)
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const value = useMemo(() => {
    let primary = ''
    let secondary = ''
    let tertiary = ''
    switch (type) {
      case 'cerfaResource':
        primary = primaryCategory ? traduceResourceCategory(primaryCategory) : ''
        secondary = secondaryCategory ? traduceResourceCategory(secondaryCategory) : ''
        tertiary = tertiaryCategory ? traduceResourceCategory(tertiaryCategory) : ''
        break
      case 'cerfaWaste':
        primary = primaryCategory ? traduceWasteCategory(primaryCategory) : ''
        secondary = secondaryCategory ? traduceWasteCategory(secondaryCategory) : ''
        tertiary = tertiaryCategory ? traduceWasteCategory(tertiaryCategory) : ''
        break
      default:
        primary = primaryCategory ? t(`categories:name.${primaryCategory.name}` as any) : ''
        secondary = secondaryCategory ? t(`categories:name.${secondaryCategory.name}` as any) : ''
        tertiary = tertiaryCategory ? t(`categories:name.${tertiaryCategory.name}` as any) : ''
        break
    }
    return !primary
      ? ''
      : primary + (!secondary ? '' : ` / ${secondary}` + (!tertiary ? '' : ` / ${tertiary}`))
  }, [type, t, primaryCategory, secondaryCategory, tertiaryCategory])

  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Input
        color="primary"
        variant="outlined"
        ref={inputRef}
        fullWidth
        required={required}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment:
            !required && !!primaryCategory ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    onChange?.({
                      primaryCategory: undefined,
                      secondaryCategory: undefined,
                      tertiaryCategory: undefined,
                    })
                    e.stopPropagation()
                  }}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        value={value}
        onClick={() => {
          if (!readOnly && !disabled) {
            setOpen(true)
          }
        }}
        placeholder={placeholder}
      />
      {open && <CategoryMenu {...props} onClose={() => setOpen(false)} inputRef={inputRef} />}
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default InputCategory
