import { forwardRef, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Material,
  MaterialType,
  TermsOfSale,
  convert2Imperials,
} from '../../models/materials.models'
import { ColumnItem } from '../../models/props.models'
import { Catalog } from '../../models/catalogs.models'
import List, { ListProps, ListRef } from '../common/List.common'
import MaterialCard from './Card.material'
import { StringUtils } from '../../utils/commons.utils'
import { sessionService } from '../../store/session'

export interface MaterialListProps extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {
  type: MaterialType
  canUpdate?: boolean
  isAdmin?: boolean
  isPublic?: boolean
  isCatalogPage?: boolean
  addToCart?: (material: Material, quantity: number) => void
  useImperials: boolean
}

const MaterialList = forwardRef<ListRef, MaterialListProps>((props, ref) => {
  const {
    useImperials,
    type,
    canUpdate,
    isAdmin,
    isPublic,
    isCatalogPage,
    addToCart,
    ...listProps
  } = props
  const showCerfaRef = useRef(sessionService.showCerfa() && type !== MaterialType.need)
  const { t } = useTranslation()

  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('materials:attributes.name'),
        key: 'name',
      },
      (material: Material | undefined) => ({
        label: t('materials:attributes.categories'),
        key: 'categories',
        formatValue: (_: any) => {
          return !material
            ? ''
            : `
            ${t(`categories:name.${material.primaryCategory.name}` as any)} /
            ${t(`categories:name.${material.secondaryCategory.name}` as any)} /
            ${t(`categories:name.${material.tertiaryCategory.name}` as any)}
          `
        },
      }),
      (material: Material | undefined) => ({
        label: t('materials:attributes.organization'),
        key: 'organization',
        formatValue: (_: any) => `${(material?.catalog as Catalog)?.organization?.name || ''}`,
      }),
      {
        label: t('materials:attributes.catalog'),
        key: 'catalog',
        formatValue: (catalog: Catalog) => `${catalog?.name || ''}`,
      },
      ...(type === MaterialType.need
        ? [
            (material: Material | undefined) => ({
              label: t('materials:attributes.desiredQty'),
              key: 'initialQty',
              formatValue: () => {
                const converted = (
                  useImperials && material ? convert2Imperials(material) : material
                ) as Material | undefined
                return converted?.initialQty && converted?.initialQty >= 0 && converted?.unit
                  ? `${StringUtils.formatNumber(converted.initialQty)} ${t(
                      `materials:unitSymbol.${converted.unit}`,
                    )}`
                  : ''
              },
            }),
          ]
        : [
            (material: Material | undefined) => ({
              label: t('materials:attributes.quantities.current'),
              key: 'currentQty',
              formatValue: () => {
                const converted = (
                  useImperials && material ? convert2Imperials(material) : material
                ) as Material | undefined
                return converted?.currentQty && converted?.currentQty >= 0 && converted?.unit
                  ? `${StringUtils.formatNumber(converted.currentQty)} ${t(
                      `materials:unitSymbol.${converted.unit}`,
                    )}`
                  : ''
              },
            }),
            (material: Material | undefined) => ({
              label: t('materials:attributes.price'),
              key: 'price',
              formatValue: (_: number) => {
                const converted = (
                  useImperials && material ? convert2Imperials(material) : material
                ) as Material | undefined
                return converted?.visible === false
                  ? t('materials:termsOfSale.invisible')
                  : converted?.termsOfSale === TermsOfSale.sale
                  ? t('materials:details.price', {
                      price: converted.price,
                      currency: t(`global:currency.${converted.currency}`),
                      unit: t(`materials:unitSymbol.${converted.unit}`),
                    })
                  : converted?.termsOfSale === TermsOfSale.donation
                  ? t('materials:details.donation')
                  : t('materials:details.priceNotDefined')
              },
            }),
          ]),
      (material: Material | undefined) => ({
        label:
          type === MaterialType.need
            ? t('materials:attributes.needsEndDate')
            : t('materials:attributes.retrieval.startDate'),
        key: 'date',
        formatValue: (_: number) =>
          type === MaterialType.need
            ? t('global:format.date', {
                date: new Date(material?.retrieval?.endDate || 'invalid date'),
              })
            : material?.visible === false
            ? ''
            : `${t('global:format.date', {
                date: new Date(material?.retrieval?.startDate || 'invalid date'),
              })} - ${t('global:format.date', {
                date: new Date(material?.retrieval?.endDate || 'invalid date'),
              })}`,
      }),
    ],
    [t, type, useImperials],
  )
  const card = useCallback(
    (props: any) => (
      <MaterialCard
        {...props}
        useImperials={useImperials}
        showCerfa={showCerfaRef.current}
        isCatalogPage={isCatalogPage}
        isAdmin={isAdmin}
        isPublic={isPublic}
        addToCart={addToCart?.bind(null, props.value)}
      />
    ),
    [isAdmin, isPublic, isCatalogPage, addToCart, useImperials],
  )

  return (
    <>
      <List
        {...listProps}
        ref={ref}
        columns={isAdmin && !isCatalogPage ? columns : undefined}
        card={card}
      />
    </>
  )
})
export default MaterialList
