import DetailsComponent from '../common/Details.common'
import { useEffect, useState } from 'react'
import { Organization, LegalForm } from '../../models/organizations.models'
import { ItemType, DetailItem, Actions } from '../../models/props.models'
import { Location } from '../../models/commons.models'
import { useTranslation } from 'react-i18next'
import { organizationsService } from '../../store/organizations'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { sessionService } from '../../store/session'
import { Switch } from '@mui/material'

type InformationOrganizationProps = {
  title: string
  onCerfaUpdate?: (value: boolean) => void
  organization: Organization
  additionals?: DetailItem[]
  onClick?: () => void
  actions?: Actions
}
const InformationOrganization: React.FC<InformationOrganizationProps> = ({
  title,
  organization,
  additionals,
  actions,
  onCerfaUpdate,
  onClick,
}) => {
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [enableCerfa, setEnableCerfa] = useState<boolean>(true)
  useEffect(() => {
    const getEnableCerfa = async () => {
      const country = await sessionService.getCountry(organization?.location?.countryCode)
      setEnableCerfa(!!country.enableCerfa)
    }
    getEnableCerfa()
  }, [organization?.location?.countryCode])

  const items: DetailItem[] = [
    {
      label: t('organizations:attributes.name'),
      key: 'name',
    },
    ...(enableCerfa && !onCerfaUpdate
      ? [
          {
            key: 'enableCerfa',
            label: t('organizations:attributes.enableCerfa'),
            formatValue: (enableCerfa: boolean) =>
              enableCerfa ? t(`global:actions.yes`) : t(`global:actions.no`),
          },
        ]
      : enableCerfa && onCerfaUpdate
      ? [
          {
            label: t('organizations:attributes.enableCerfa'),
            type: ItemType.custom,
            key: 'enableCerfa',
            custom: (
              <Switch
                onChange={(_, checked) => onCerfaUpdate(checked)}
                checked={organization.enableCerfa}
              />
            ),
          },
        ]
      : []),
    {
      label: t('organizations:attributes.legalForm'),
      key: 'legalForm',
      formatValue: (legalForm: LegalForm) =>
        legalForm ? t(`organizations:legalForms.${legalForm}`) : '',
    },
    { label: t('organizations:attributes.siret'), key: 'siret' },
    {
      label: t('organizations:attributes.location'),
      key: 'location',
      formatValue: (location: Location) => location?.fullAddress?.replace(',', '\n') || '',
    },
    {
      label: t('organizations:attributes.kbis'),
      type: organization.kbis ? ItemType.link : ItemType.typo,
      key: 'kbis',
      props: organization.kbis
        ? {
            children: organization.kbis?.path?.split(/\\|\//)?.pop() ?? '',
            onClick: async () => {
              setLoading(true)
              try {
                if (organization.kbis) {
                  await organizationsService.downloadFile(organization.kbis)
                }
              } catch (err: any) {
                show(err)
              }
              setLoading(false)
            },
          }
        : undefined,
    },
    {
      label: t('organizations:attributes.insurance'),
      type:
        enableCerfa && organization.enableCerfa && organization.insurance
          ? ItemType.link
          : ItemType.typo,
      key: 'insurance',
      props:
        enableCerfa && organization.enableCerfa && organization.insurance
          ? {
              children: organization.insurance?.path?.split(/\\|\//)?.pop() ?? '',
              onClick: async () => {
                setLoading(true)
                try {
                  if (organization.insurance) {
                    await organizationsService.downloadFile(organization.insurance)
                  }
                } catch (err: any) {
                  show(err)
                }
                setLoading(false)
              },
            }
          : undefined,
    },
    ...(additionals || []),
  ]
  return (
    <>
      {loading && <LoaderOverlay />}
      <DetailsComponent
        actions={actions}
        title={title}
        value={organization}
        items={items}
        onClick={onClick}
      />
    </>
  )
}
export default InformationOrganization
