import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'

import {
  CatalogType,
  CatalogStatus,
  ApiCatalog,
  Catalog,
  CatalogsPagination,
  InterventionType,
  BuildingType,
  CatalogIcon,
} from '../models/catalogs.models'
import { Point, PaginationParams } from '../models/commons.models'
import { ExportedMaterial, Material } from '../models/materials.models'
import { PlatformReference } from '../models/platforms.models'

export namespace CatalogsApi {
  export interface GetListParams extends PaginationParams {
    platform?: PlatformReference
    search?: string
    public?: boolean
    owned?: boolean
    types?: CatalogType[]
    organization?: string
    countryCode?: string
    radius?: Number
    coordinates?: Point
    status?: CatalogStatus[]
    interventionTypes?: InterventionType[]
    buildingTypes?: BuildingType[]
    periodStart?: Date
    periodEnd?: Date
    ended?: boolean
    catalogIcon?: CatalogIcon
  }
  export const getList = async (params?: GetListParams): Promise<CatalogsPagination> => {
    const response: AxiosResponse<CatalogsPagination> = await axiosApi.get(`/catalogs`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        coordinates: params?.coordinates?.length ? params.coordinates : undefined,
        status: params?.status?.length ? params.status : undefined,
        types: params?.types?.length ? params.types : undefined,
        interventionTypes: params?.interventionTypes?.length ? params.interventionTypes : undefined,
        buildingTypes: params?.buildingTypes?.length ? params.buildingTypes : undefined,
        periodStart: params?.periodStart?.toISOString(),
        periodEnd: params?.periodEnd?.toISOString(),
      },
    })
    return response.data
  }
  export const getById = async (catalogId: string, publicCatalog?: boolean): Promise<Catalog> => {
    const response: AxiosResponse<Catalog> = await axiosApi.get(
      `/catalogs/${catalogId}${!!publicCatalog ? `?public=true` : ''}`,
    )
    return response.data
  }
  export const create = async (createdCatalog: ApiCatalog): Promise<Catalog> => {
    const response: AxiosResponse<Catalog> = await axiosApi.post(`/catalogs`, createdCatalog)
    return response.data
  }
  export const exportCatalog = async (
    exportedCatalog: ApiCatalog,
    materials: ExportedMaterial[],
  ): Promise<{ catalog: Catalog; materials: Material[] }> => {
    const response: AxiosResponse<{ catalog: Catalog; materials: Material[] }> =
      await axiosApi.post(`/catalogs/import`, {
        catalog: exportedCatalog,
        materials,
      })
    return response.data
  }
  export const update = async (
    catalogId: string,
    updatedCatalog: Partial<ApiCatalog>,
  ): Promise<Catalog> => {
    const response: AxiosResponse<Catalog> = await axiosApi.patch(
      `/catalogs/${catalogId}`,
      updatedCatalog,
    )
    return response.data
  }
  export const remove = async (catalogId: string): Promise<void> => {
    await axiosApi.delete(`/catalogs/${catalogId}`)
  }
  export const generateDiag = async (
    catalogId: string,
    format: 'csv' | 'pdf' | 'xls' | 'csv-d' | 'csv-pem',
  ): Promise<Blob> => {
    const response = await axiosApi.get(`/catalogs/${catalogId}/export/${format}`, {
      responseType: 'blob',
    })
    return response.data
  }
}
