import { useState, useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import { MaterialModel } from '../../models/materialModels.models'
import {
  Unit,
  DimensionUnit,
  ImperialUnit,
  ImperialDimensionUnit,
  convert2Imperials,
  convert2Meters,
  Assembly,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { organizationsService } from '../../store/organizations'
import { sessionService } from '../../store/session'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import InputCategory from '../category/Input.category'
import { YupUtils } from '../../utils/yup.utils'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialModelProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  materialModel?: MaterialModel
  useImperials: boolean
}
const ModalFormMaterialModel: React.FC<ModalFormMaterialModelProps> = (props) => {
  const { t } = useTranslation()
  const { useImperials, materialModel, onSubmit, ...formProps } = props
  const [value, setValue] = useState<Partial<MaterialModel>>(
    useImperials
      ? convert2Imperials(materialModel ?? ({} as MaterialModel))
      : materialModel ?? ({} as MaterialModel),
  )
  const showCerfaRef = useRef(sessionService.showCerfa())
  const submitMaterialModel = useCallback(
    (value: MaterialModel) => {
      onSubmit?.(useImperials ? convert2Meters(value) : value)
    },
    [onSubmit, useImperials],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'general',
        items: [
          {
            type: ItemType.text,
            grid: { xs: 12, md: 4 },
            key: 'name',
            required: true,
            props: {
              label: t('materialModels:attributes.name'),
              placeholder: t('materialModels:attributes.name'),
            },
          },
          {
            type: ItemType.select,
            grid: { xs: 6, md: 4 },
            key: 'unit',
            required: true,
            props: {
              label: t('materialModels:attributes.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.asyncSelect,
            key: 'organization',
            grid: { xs: 6, md: 4 },
            required: true,
            formatValue: (organization: any) => {
              return organization
                ? { label: organization.name ?? '', value: organization._id ?? '' }
                : undefined
            },
            props: {
              label: t('materialModels:attributes.organization'),
              placeholder: t('materialModels:attributes.organization'),
              minimumSearchLength: 3,
              getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
              dataValue: true,
            },
          },
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            required: true,
            custom: (
              <InputCategory
                label={t('materialModels:attributes.category')}
                placeholder={t('materialModels:attributes.category')}
                required
                accurateCategory
                primaryCategory={value.primaryCategory}
                secondaryCategory={value.secondaryCategory}
                tertiaryCategory={value.tertiaryCategory}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          (materialModel: MaterialModel) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'unitWeight',
            props: {
              label: t('materials:attributes.weight.unit'),
              placeholder: t('materials:attributes.weight.unit'),
              endAdornment: materialModel?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.${materialModel.unit}`),
                  })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'density',
            props: {
              label: t('materials:attributes.density'),
              placeholder: t('materials:attributes.density'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.m3`),
                  })}
                </EndAdornmentLabel>
              ),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, md: 3 },
            key: 'wasteOnly',
            hideItem: !showCerfaRef.current,
            props: {
              label: t('materials:attributes.wasteOnly'),
              placeholder: t('materials:attributes.wasteOnly'),
            },
          },
        ],
      },
      (materialModel: MaterialModel) => ({
        type: ItemType.group,
        key: 'dimensionsGroup',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.dimensions.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.unit',
            props: {
              label: t('materialModels:attributes.dimensions.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(
                useImperials ? ImperialDimensionUnit : DimensionUnit,
                'materials:dimensionUnit',
              ),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.length',
            props: {
              label: t('materialModels:attributes.dimensions.length'),
              placeholder: t('materialModels:attributes.dimensions.length'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.width',
            props: {
              label: t('materialModels:attributes.dimensions.width'),
              placeholder: t('materialModels:attributes.dimensions.width'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.height',
            props: {
              label: t('materialModels:attributes.dimensions.height'),
              placeholder: t('materialModels:attributes.dimensions.height'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.diameter',
            props: {
              label: t('materialModels:attributes.dimensions.diameter'),
              placeholder: t('materialModels:attributes.dimensions.diameter'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
        ],
      }),
      {
        type: ItemType.group,
        key: 'technicalDetailsGroup',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.technicalDetails.title'),
        },
        items: [
          {
            type: ItemType.array,
            key: 'technicalDetails',
            props: {
              orderable: true,
              addLabel: t('materialModels:attributes.technicalDetails.addButton'),
            },
            items: [
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'name',
                required: true,
                props: {
                  label: t('materialModels:attributes.technicalDetails.name'),
                  placeholder: t('materialModels:attributes.technicalDetails.name'),
                },
              },
              {
                type: ItemType.text,
                grid: { xs: 12, sm: 6 },
                key: 'value',
                props: {
                  label: t('materialModels:attributes.technicalDetails.value'),
                  placeholder: t('materialModels:attributes.technicalDetails.value'),
                },
              },
            ] as FormItem[],
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'carbon',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.carbon.title'),
        },
        items: [
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 4, md: 3 },
            key: 'carbonTotal',
            props: {
              label: t('materialModels:attributes.carbon.carbonTotal'),
              placeholder: t('materialModels:attributes.carbon.carbonTotalPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2/UF</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.text,
            grid: { xs: 12, sm: 8, md: 9 },
            key: 'tracksOfReuse',
            props: {
              label: t('materialModels:attributes.carbon.tracksOfReuse'),
              placeholder: t('materialModels:attributes.carbon.tracksOfReuse'),
            },
          },
        ],
      },
      {
        type: ItemType.group,
        hideItem: !showCerfaRef.current || value.wasteOnly,
        key: 'cerfaResource',

        props: {
          title: t('materials:attributes.cerfaResource.title'),
          tooltip: t('materials:attributes.cerfaResource.tooltip'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'resourcePrimaryCategory',
            grid: { xs: 6 },
            custom: (
              <InputCategory
                type="cerfaResource"
                label={t('materials:attributes.cerfaResource.category')}
                placeholder={t('materials:attributes.cerfaResource.category')}
                accurateCategory
                primaryCategory={value.resourcePrimaryCategory}
                secondaryCategory={value.resourceSecondaryCategory}
                tertiaryCategory={value.resourceTertiaryCategory}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    resourcePrimaryCategory: categoryValue.primaryCategory,
                    resourceSecondaryCategory: categoryValue.secondaryCategory,
                    resourceTertiaryCategory: categoryValue.tertiaryCategory,
                  }))
                }}
              />
            ),
          },
          {
            type: ItemType.select,
            key: 'assembly',
            grid: { xs: 6 },
            props: {
              label: t('materials:attributes.cerfaResource.assembly'),
              placeholder: t('materials:attributes.cerfaResource.assembly'),
              items: createOptionsFromEnum(Assembly, 'materials:assembly'),
            },
          },
          {
            type: ItemType.autocomplete,
            key: 'constituentMaterials',
            grid: { xs: 6 },
            props: {
              label: t('materials:attributes.cerfaResource.constituentMaterials'),
              placeholder: t('materials:attributes.cerfaResource.constituentMaterials'),
              items: [],
            },
          },
        ],
      },
      {
        type: ItemType.group,
        hideItem: !showCerfaRef.current,
        key: 'cerfaWaste',
        props: {
          title: t('materials:attributes.cerfaWaste.title'),
          tooltip: t('materials:attributes.cerfaWaste.tooltip'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'wasteCategory',
            grid: { xs: 12, md: 6 },
            custom: (
              <InputCategory
                type="cerfaWaste"
                label={t('materials:attributes.cerfaWaste.category')}
                placeholder={t('materials:attributes.cerfaWaste.category')}
                accurateCategory
                primaryCategory={value.wastePrimaryCategory}
                secondaryCategory={value.wasteSecondaryCategory}
                tertiaryCategory={value.wasteTertiaryCategory}
                onChange={(categoryValue: any) => {
                  console.log(categoryValue)
                  setValue((val: any) => ({
                    ...val,
                    wastePrimaryCategory: categoryValue.primaryCategory,
                    wasteSecondaryCategory: categoryValue.secondaryCategory,
                    wasteTertiaryCategory: categoryValue.tertiaryCategory,
                  }))
                }}
              />
            ),
          },
          {
            key: 'wasteCode',
            type: ItemType.text,
            grid: { xs: 12, md: 6 },
            props: {
              label: t('materials:attributes.cerfaWaste.wasteCode'),
              placeholder: t('materials:attributes.cerfaWaste.wasteCode'),
            },
          },
          {
            type: ItemType.typo,
            key: 'cerfaWasteValorisation',
            formatValue: () => t('materials:attributes.cerfaWaste.valorisation'),
            rules: [
              (materialModel: any) => {
                const total =
                  (materialModel.reusePercent ?? 0) +
                  (materialModel.recyclablePercent ?? 0) +
                  (materialModel.backfillingPercent ?? 0) +
                  (materialModel.incineratedWithEnergyPercent ?? 0) +
                  (materialModel.incineratedWithoutEnergyPercent ?? 0) +
                  (materialModel.nonRecoverablePercent ?? 0)

                return total !== 0 && total !== 100 ? t('errors:sumPercent') : ''
              },
            ],
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'reusePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.reusePercent'),
              placeholder: t('materials:attributes.cerfaWaste.reusePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'recyclablePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.recyclablePercent'),
              placeholder: t('materials:attributes.cerfaWaste.recyclablePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'backfillingPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.backfillingPercent'),
              placeholder: t('materials:attributes.cerfaWaste.backfillingPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'incineratedWithEnergyPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.incineratedWithEnergyPercent'),
              placeholder: t('materials:attributes.cerfaWaste.incineratedWithEnergyPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.typo,
            key: 'cerfaWasteElimination',
            formatValue: () => t('materials:attributes.cerfaWaste.elimination'),
            rules: [
              (materialModel: any) => {
                const total =
                  (materialModel.reusePercent ?? 0) +
                  (materialModel.recyclablePercent ?? 0) +
                  (materialModel.backfillingPercent ?? 0) +
                  (materialModel.incineratedWithEnergyPercent ?? 0) +
                  (materialModel.incineratedWithoutEnergyPercent ?? 0) +
                  (materialModel.nonRecoverablePercent ?? 0)

                return total !== 0 && total !== 100 ? t('errors:sumPercent') : ''
              },
            ],
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6 },
            key: 'incineratedWithoutEnergyPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent'),
              placeholder: t('materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6 },
            key: 'nonRecoverablePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.nonRecoverablePercent'),
              placeholder: t('materials:attributes.cerfaWaste.nonRecoverablePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
        ],
      },
    ],
    [
      t,
      useImperials,
      value.wasteOnly,
      value.resourcePrimaryCategory,
      value.resourceSecondaryCategory,
      value.resourceTertiaryCategory,
      value.wastePrimaryCategory,
      value.wasteSecondaryCategory,
      value.wasteTertiaryCategory,
      value.primaryCategory,
      value.secondaryCategory,
      value.tertiaryCategory,
    ],
  )

  return (
    <ModalForm
      {...formProps}
      value={value}
      setValue={setValue}
      items={items}
      maxWidth="lg"
      onSubmit={submitMaterialModel}
    />
  )
}
export default ModalFormMaterialModel
