import { useReducer, useCallback, useMemo } from 'react'
import {
  LocatedMaterial,
  Material,
  MaterialQuantity,
  computeResourceQuantities,
  QuantityType,
  isLinkToRoomDimension,
  getRoomDimension,
  checkMaterialQuantities,
} from '../models/materials.models'

import { Room } from '../models/catalogs.models'
import { PlanUtils } from '../utils/plan.utils'
import { FileUtils } from '../utils/files.utils'
import useSnackbar from './useSnackbar.hooks'

type ActionRoom = Room & { locatedMaterialsQuantities: Record<string, number> }
type ActionType = 'edit' | 'add' | 'delete'
type ActionValueType = 'rooms' | 'locatedMaterials' | 'materials'
type Action = {
  type: ActionType
  valueType: ActionValueType
  value: ActionRoom | LocatedMaterial | Material | string
  previousValue: ActionRoom | LocatedMaterial | Material
}
interface PlanData {
  _id: string
  angle: number
  scale: number
  name: string
  rooms: Room[]
  materials: Material[]
}
interface EditPlanReducer extends PlanData {
  currentRoomId?: string
  currentLocatedMaterialId?: string
  currentMaterialId?: string
  currentActionIndex: number
  actions: Action[]
}
type EditPlanReducerAction = {
  type:
    | 'editRoom'
    | 'addRoom'
    | 'deleteRoom'
    | 'editLocatedMaterial'
    | 'addLocatedMaterial'
    | 'deleteLocatedMaterial'
    | 'editMaterial'
    | 'addMaterial'
    | 'deleteMaterial'
  value: Room | LocatedMaterial | Material | string
  updateSeleted: boolean
}
type CatalogReducerSelect = {
  type: 'select'
  roomId?: string
  materialId?: string
  locatedMaterialId?: string
}
type EditPlanReducerEvent =
  | CatalogReducerSelect
  | EditPlanReducerAction
  | { type: 'undo' }
  | { type: 'redo' }
  | { type: 'loadMaterialsSrc'; materials: { _id: string; src: string }[] }
  | { type: 'loadPlan'; planData: PlanData }

const doAction = (planReducer: EditPlanReducer, action: EditPlanReducerAction): EditPlanReducer => {
  const type =
    action.type.indexOf('edit') !== -1
      ? 'edit'
      : action.type.indexOf('add') !== -1
      ? 'add'
      : 'delete'
  const valueType =
    action.type.indexOf('Room') !== -1
      ? 'rooms'
      : action.type.indexOf('LocatedMaterial') !== -1
      ? 'locatedMaterials'
      : 'materials'

  let storedValue
  if (type === 'delete') {
    storedValue = action.value
  } else {
    if ((action.value as any)?.mainImageFile?.src) {
      delete (action.value as any).mainImageFile.src
    }
    storedValue = JSON.parse(JSON.stringify(action.value))

    if (valueType === 'locatedMaterials' && storedValue?.linkToRoom !== false) {
      const located = storedValue as LocatedMaterial
      const material = planReducer.materials.find(
        (material: Material) => material._id === located.material,
      )
      if (isLinkToRoomDimension(material)) {
        const room = PlanUtils.getRoomAtPosition(located.position, planReducer.rooms)
        const newRoomQuantity = getRoomDimension(planReducer.scale, material, room)
        if (newRoomQuantity) {
          storedValue.quantity = newRoomQuantity
        }
      }
    }
  }

  let previousValue
  if (type === 'edit' || type === 'delete') {
    const id = type === 'edit' ? storedValue._id : storedValue
    if (valueType === 'locatedMaterials') {
      planReducer.materials.forEach((material) => {
        let materialQuantity = material.quantities.find((materialQuantity: MaterialQuantity) => {
          return materialQuantity._id === id
        })
        if (materialQuantity) {
          previousValue = {
            ...materialQuantity,
            material: material._id,
          }
        }
      })
    } else {
      previousValue = (planReducer[valueType] as Array<Room | Material>).find(
        (obj: Material | Room) => obj._id === id,
      )
    }

    if (previousValue) {
      if ((previousValue as any)?.mainImageFile?.src) {
        delete (previousValue as any).mainImageFile.src
      }
      previousValue = JSON.parse(JSON.stringify(previousValue))
    }
  }

  if (valueType === 'rooms' && type !== 'delete') {
    const room = (type === 'add' ? storedValue : storedValue) as Room

    const locatedMaterialsQuantities: Record<string, number> = {}
    const preivousLocatedMaterialsQuantities: Record<string, number> = {}

    planReducer.materials
      .filter((material: Material) => isLinkToRoomDimension(material))
      .forEach((material: Material) => {
        material.quantities
          .filter(
            (quantity) =>
              quantity.position &&
              quantity?.linkToRoom !== false &&
              quantity?.plan?._id === planReducer._id &&
              PlanUtils.isInRoom(quantity.position, room),
          )
          .forEach((quantity) => {
            const newRoomQuantity = getRoomDimension(planReducer.scale, material, room)
            if (newRoomQuantity) {
              preivousLocatedMaterialsQuantities[quantity._id] = quantity.quantity
              locatedMaterialsQuantities[quantity._id] = newRoomQuantity
            }
          })
      }, [])
    storedValue.locatedMaterialsQuantities = locatedMaterialsQuantities
    if (type === 'add') {
      previousValue = {}
    }
    previousValue.locatedMaterialsQuantities = preivousLocatedMaterialsQuantities
  }

  const storeAction: Action = {
    type,
    valueType,
    value: storedValue,
    previousValue,
  }
  return {
    ...launchAction(planReducer, storeAction, false, action.updateSeleted),
    currentActionIndex: 0,
    actions: [storeAction, ...planReducer.actions.slice(planReducer.currentActionIndex)],
  }
}
const undoAction = (planReducer: EditPlanReducer): EditPlanReducer => {
  return {
    ...launchAction(planReducer, planReducer.actions[planReducer.currentActionIndex], true),
    currentActionIndex: planReducer.currentActionIndex + 1,
  }
}
const redoAction = (planReducer: EditPlanReducer): EditPlanReducer => {
  return {
    ...launchAction(planReducer, planReducer.actions[planReducer.currentActionIndex - 1]),
    currentActionIndex: planReducer.currentActionIndex - 1,
  }
}

const checkMaterialRooms = (
  planId: string,
  materials: Material[],
  rooms: Room[],
  locatedMaterialsQuantities?: Record<string, number>,
) => {
  return materials.map((material: Material) => {
    return {
      ...material,
      ...computeResourceQuantities(
        material.quantities.map((materialQuantity: MaterialQuantity) => {
          if (!materialQuantity.position || materialQuantity?.plan?._id !== planId) {
            return materialQuantity
          }
          const room = PlanUtils.getRoomAtPosition(materialQuantity.position, rooms)
          return {
            ...materialQuantity,
            quantity:
              locatedMaterialsQuantities?.[materialQuantity._id] ?? materialQuantity.quantity,
            room: room ? { _id: room._id, name: room.name } : undefined,
          }
        }),
      ),
    }
  })
}

const deleteItem = (
  planReducer: EditPlanReducer,
  id: string,
  valueType: ActionValueType,
  updateSeleted: boolean,
  locatedMaterialsQuantities?: Record<string, number>,
): EditPlanReducer => {
  if (valueType === 'locatedMaterials') {
    return {
      ...planReducer,
      materials: planReducer.materials.map((material: Material) => {
        const quantities = material.quantities.filter(
          (materialQuantity: MaterialQuantity) => materialQuantity._id !== id,
        )

        return {
          ...material,
          ...computeResourceQuantities(quantities),
        }
      }),
      ...(updateSeleted
        ? {
            currentMaterialId: planReducer.currentMaterialId,
            currentRoomId: undefined,
            currentLocatedMaterialId: undefined,
          }
        : {}),
    }
  } else {
    const rooms: Room[] =
      valueType === 'rooms'
        ? planReducer.rooms.filter((room: Room) => room._id !== id)
        : planReducer.rooms
    const materials: Material[] =
      valueType === 'materials'
        ? planReducer.materials.filter((material: Material) => material._id !== id)
        : checkMaterialRooms(
            planReducer._id,
            planReducer.materials,
            rooms,
            locatedMaterialsQuantities,
          )

    return {
      ...planReducer,
      rooms,
      materials,
      ...(updateSeleted
        ? {
            currentMaterialId: valueType === 'rooms' ? planReducer.currentMaterialId : undefined,
            currentRoomId: undefined,
            currentLocatedMaterialId: undefined,
          }
        : {}),
    }
  }
}
const addItem = (
  planReducer: EditPlanReducer,
  _value: ActionRoom | LocatedMaterial | Material,
  valueType: ActionValueType,
  updateSeleted: boolean,
): EditPlanReducer => {
  const { locatedMaterialsQuantities, ...value } = _value as any

  if (valueType === 'locatedMaterials') {
    const locatedMaterial = value as LocatedMaterial
    return {
      ...planReducer,
      materials: planReducer.materials.map((material: Material) => {
        if (material._id === locatedMaterial.material) {
          const {
            material: materialId,
            primaryCategory,
            secondaryCategory,
            tertiaryCategory,
            plan,
            room,
            ...others
          } = locatedMaterial

          let newRoom = PlanUtils.getRoomAtPosition(locatedMaterial.position, planReducer.rooms)

          const materialQuantity: MaterialQuantity = {
            ...others,
            initial: true,
            type: QuantityType.inventory,
            plan: { _id: planReducer._id, name: planReducer.name },
            room: newRoom ? { _id: newRoom._id, name: newRoom.name } : undefined,
          }
          const quantities = [...material.quantities, materialQuantity]

          return {
            ...material,
            ...computeResourceQuantities(quantities),
          }
        }
        return material
      }),
      ...(updateSeleted
        ? {
            currentMaterialId: locatedMaterial.material,
            currentRoomId: undefined,
            currentLocatedMaterialId: value._id,
          }
        : {}),
    }
  } else {
    const rooms = valueType === 'rooms' ? [...planReducer.rooms, value as Room] : planReducer.rooms
    const materials =
      valueType === 'materials'
        ? [...planReducer.materials, value as Material]
        : checkMaterialRooms(
            planReducer._id,
            planReducer.materials,
            rooms,
            locatedMaterialsQuantities,
          )

    return {
      ...planReducer,
      rooms,
      materials,
      ...(updateSeleted
        ? {
            currentMaterialId:
              valueType === 'materials' ? value._id : planReducer.currentMaterialId,
            currentRoomId: valueType === 'rooms' ? value._id : undefined,
            currentLocatedMaterialId: undefined,
          }
        : {}),
    }
  }
}
const editItem = (
  planReducer: EditPlanReducer,
  _value: ActionRoom | LocatedMaterial | Material,
  valueType: ActionValueType,
  updateSeleted: boolean,
): EditPlanReducer => {
  const { locatedMaterialsQuantities, ...value } = _value as any

  if (valueType === 'locatedMaterials') {
    const locatedMaterial = value as LocatedMaterial
    return {
      ...planReducer,
      materials: planReducer.materials.map((material: Material) => {
        if (material._id === locatedMaterial.material) {
          const {
            material: materialId,
            primaryCategory,
            secondaryCategory,
            tertiaryCategory,
            plan,
            ...others
          } = locatedMaterial

          let newRoom = PlanUtils.getRoomAtPosition(locatedMaterial.position, planReducer.rooms)
          const materialQuantity: MaterialQuantity = {
            ...others,
            initial: true,
            type: QuantityType.inventory,
            room: newRoom ? { _id: newRoom._id, name: newRoom.name } : undefined,
            plan: { _id: planReducer._id, name: planReducer.name },
          }

          const quantities = material.quantities.map((quantity: MaterialQuantity) => {
            return quantity._id === materialQuantity._id ? materialQuantity : quantity
          })

          return {
            ...material,
            ...computeResourceQuantities(quantities),
          }
        }
        return material
      }),
      ...(updateSeleted
        ? {
            currentMaterialId: planReducer.currentMaterialId,
            currentRoomId: undefined,
            currentLocatedMaterialId: value._id,
          }
        : {}),
    }
  } else {
    const rooms: Room[] =
      valueType === 'rooms'
        ? planReducer.rooms.map((room: Room) => (room._id === value._id ? (value as Room) : room))
        : planReducer.rooms
    const materials: Material[] =
      valueType === 'materials'
        ? planReducer.materials.map((material: Material) =>
            material._id === value._id ? (value as Material) : material,
          )
        : checkMaterialRooms(
            planReducer._id,
            planReducer.materials,
            rooms,
            locatedMaterialsQuantities,
          )

    return {
      ...planReducer,
      rooms,
      materials,
      ...(updateSeleted
        ? {
            currentMaterialId:
              valueType === 'materials' ? value._id : planReducer.currentMaterialId,
            currentRoomId: valueType === 'rooms' ? value._id : undefined,
            currentLocatedMaterialId: undefined,
          }
        : {}),
    }
  }
}
const launchAction = (
  planReducer: EditPlanReducer,
  action: Action,
  backward = false,
  updateSeleted = true,
): EditPlanReducer => {
  const { type, valueType, value, previousValue } = action
  if (type === 'add') {
    if (backward) {
      return deleteItem(
        planReducer,
        (value as ActionRoom | LocatedMaterial | Material)._id,
        valueType,
        updateSeleted,
        (previousValue as ActionRoom)?.locatedMaterialsQuantities,
      )
    } else {
      return addItem(
        planReducer,
        value as ActionRoom | LocatedMaterial | Material,
        valueType,
        updateSeleted,
      )
    }
  } else if (type === 'edit') {
    return editItem(
      planReducer,
      backward ? previousValue : (value as ActionRoom | LocatedMaterial | Material),
      valueType,
      updateSeleted,
    )
  } else if (type === 'delete') {
    if (backward) {
      return addItem(planReducer, previousValue, valueType, updateSeleted)
    } else {
      return deleteItem(planReducer, value as string, valueType, updateSeleted)
    }
  }
  return planReducer
}

const editPlanReducer = (
  planReducer: EditPlanReducer,
  action: EditPlanReducerEvent,
): EditPlanReducer => {
  switch (action.type) {
    case 'loadMaterialsSrc':
      return {
        ...planReducer,
        materials: planReducer.materials.map((material) => {
          const src = action.materials.find(({ _id }) => _id === material._id)

          return src
            ? { ...material, mainImageFile: { ...material.mainImageFile, src: src.src } }
            : material
        }),
      }
    case 'loadPlan':
      return {
        ...action.planData,
        rooms: action.planData.rooms || [],
        actions: [],
        currentRoomId: undefined,
        currentMaterialId: undefined,
        currentLocatedMaterialId: undefined,
        currentActionIndex: 0,
      }
    case 'select':
      return {
        ...planReducer,
        currentMaterialId: action.materialId,
        currentRoomId: action.roomId,
        currentLocatedMaterialId: action.locatedMaterialId,
      }
    case 'editRoom':
    case 'deleteRoom':
    case 'editLocatedMaterial':
    case 'addLocatedMaterial':
    case 'deleteLocatedMaterial':
    case 'addRoom':
    case 'editMaterial':
    case 'addMaterial':
    case 'deleteMaterial':
      return doAction(planReducer, action)
    case 'undo':
      return undoAction(planReducer)
    case 'redo':
      return redoAction(planReducer)
    default:
      throw new Error(`${action} is not handle by planReducer`)
  }
}

type UseEditPlan = {
  planData: PlanData
  currentMaterial?: Material
  currentRoom?: Room
  currentLocatedMaterial?: LocatedMaterial
  canUndo: boolean
  canRedo: boolean
  loadPlan: (planData: PlanData) => void
  select: (arg: Omit<CatalogReducerSelect, 'type'>, updateOthers?: boolean) => void
  editRoom: (room: Room, updateSeleted?: boolean) => void
  addRoom: (room: Room, updateSeleted?: boolean) => void
  deleteRoom: (id: string, updateSeleted?: boolean) => void
  editLocatedMaterial: (room: LocatedMaterial, updateSeleted?: boolean) => void
  addLocatedMaterial: (room: LocatedMaterial, updateSeleted?: boolean) => void
  deleteLocatedMaterial: (id: string, updateSeleted?: boolean) => void
  editMaterial: (room: Material, updateSeleted?: boolean) => void
  addMaterial: (room: Material, updateSeleted?: boolean) => void
  deleteMaterial: (id: string, updateSeleted?: boolean) => void
  undo: () => void
  redo: () => void
}
const useEditPlan = (): UseEditPlan => {
  const show = useSnackbar()
  const [plan, dispatchPlan] = useReducer(editPlanReducer, {
    _id: '',
    name: '',
    scale: 0.01,
    angle: 0,
    rooms: [],
    materials: [],
    actions: [],
    currentRoomId: undefined,
    currentMaterialId: undefined,
    currentLocatedMaterialId: undefined,
    currentActionIndex: 0,
  })

  const currentMaterial = useMemo(
    () => plan.materials.find((material: Material) => material._id === plan.currentMaterialId),
    [plan.materials, plan.currentMaterialId],
  )
  const currentRoom = useMemo(
    () => plan.rooms.find((room: Room) => room._id === plan.currentRoomId),
    [plan.rooms, plan.currentRoomId],
  )

  const currentLocatedMaterial = useMemo(() => {
    let currentLocatedMaterial = undefined
    if (plan.currentLocatedMaterialId) {
      plan.materials.forEach((material) => {
        let materialQuantity = material.quantities.find((materialQuantity: MaterialQuantity) => {
          return materialQuantity._id === plan.currentLocatedMaterialId
        })
        if (materialQuantity) {
          currentLocatedMaterial = {
            ...materialQuantity,
            plan: materialQuantity.plan?._id,
            room: materialQuantity.room?._id,
            material: material._id,
            primaryCategory: material.primaryCategory,
            secondaryCategory: material.secondaryCategory,
            tertiaryCategory: material.tertiaryCategory,
          }
        }
      })
    }
    return currentLocatedMaterial
  }, [plan.materials, plan.currentLocatedMaterialId])

  const tryLoadImages = useCallback((materials: Material[]) => {
    const loadImages = async () => {
      return await Promise.all(
        materials.map((material: Material) => FileUtils.loadImage(material.mainImageFile)),
      )
    }
    loadImages()
      .then((srcs: string[]) => {
        dispatchPlan({
          type: 'loadMaterialsSrc',
          materials: materials.map((material: Material, index: number) => ({
            _id: material._id,
            src: srcs[index],
          })),
        })
      })
      .catch(() => {})
  }, [])

  return {
    planData: {
      _id: plan._id,
      name: plan.name,
      scale: plan.scale,
      angle: plan.angle,
      rooms: plan.rooms,
      materials: plan.materials,
    },
    currentMaterial,
    currentRoom,
    currentLocatedMaterial,
    canUndo: plan.currentActionIndex < plan.actions.length,
    canRedo: plan.currentActionIndex > 0,
    loadPlan: useCallback(
      (planData: PlanData) => {
        dispatchPlan({ planData, type: 'loadPlan' })
        tryLoadImages(planData.materials)
      },
      [tryLoadImages],
    ),
    select: useCallback(
      (arg: Omit<CatalogReducerSelect, 'type'>, updateOthers = false) => {
        if (updateOthers) {
          dispatchPlan({ ...arg, type: 'select' })
        } else {
          dispatchPlan({
            materialId: plan.currentMaterialId,
            roomId: plan.currentRoomId,
            locatedMaterialId: plan.currentLocatedMaterialId,
            ...arg,
            type: 'select',
          })
        }
      },
      [plan.currentMaterialId, plan.currentRoomId, plan.currentLocatedMaterialId],
    ),
    editRoom: useCallback(
      (room: Room, updateSeleted = true) =>
        dispatchPlan({ value: room, type: 'editRoom', updateSeleted }),
      [],
    ),
    addRoom: useCallback(
      (room: Room, updateSeleted = true) =>
        dispatchPlan({ value: room, type: 'addRoom', updateSeleted }),
      [],
    ),
    deleteRoom: useCallback(
      (id: string, updateSeleted = true) =>
        dispatchPlan({ value: id, type: 'deleteRoom', updateSeleted }),
      [],
    ),
    editLocatedMaterial: useCallback(
      (locatedMaterial: LocatedMaterial, updateSeleted = true) =>
        dispatchPlan({ value: locatedMaterial, type: 'editLocatedMaterial', updateSeleted }),
      [],
    ),
    addLocatedMaterial: useCallback(
      (locatedMaterial: LocatedMaterial, updateSeleted = true) =>
        dispatchPlan({ value: locatedMaterial, type: 'addLocatedMaterial', updateSeleted }),
      [],
    ),
    deleteLocatedMaterial: useCallback(
      (id: string, updateSeleted = true) => {
        let material = plan.materials.find((m: Material) => m.quantities.find((q) => q._id === id))

        const missingQuantity = checkMaterialQuantities(
          material?.quantities.filter((quantity) => quantity._id !== id) || [],
          material?.cerfaWaste,
        )
        if (missingQuantity) {
          let error: any = new Error('MISSING_QUANTITY')
          error.response = {
            data: {
              error: 'MISSING_QUANTITY',
              message: [missingQuantity],
            },
          }
          show(error)
        } else {
          dispatchPlan({ value: id, type: 'deleteLocatedMaterial', updateSeleted })
        }
      },
      [plan.materials, show],
    ),
    editMaterial: useCallback(
      (material: Material, updateSeleted = true) => {
        dispatchPlan({
          value: material,
          type: 'editMaterial',
          updateSeleted,
        })
        tryLoadImages([material])
      },
      [tryLoadImages],
    ),
    addMaterial: useCallback(
      (material: Material, updateSeleted = true) => {
        dispatchPlan({ value: material, type: 'addMaterial', updateSeleted })
        tryLoadImages([material])
      },
      [tryLoadImages],
    ),
    deleteMaterial: useCallback(
      (id: string, updateSeleted = true) =>
        dispatchPlan({ value: id, type: 'deleteMaterial', updateSeleted }),
      [],
    ),
    undo: useCallback(() => {
      const lastAction = plan.actions[plan.currentActionIndex]
      dispatchPlan({ type: 'undo' })
      if (
        (lastAction.type === 'edit' || lastAction.type === 'delete') &&
        lastAction.valueType === 'materials'
      ) {
        tryLoadImages([lastAction.previousValue as Material])
      }
    }, [tryLoadImages, plan.actions, plan.currentActionIndex]),
    redo: useCallback(() => {
      const nextAction = plan.actions[plan.currentActionIndex - 1]
      dispatchPlan({ type: 'redo' })
      if (
        (nextAction.type === 'edit' || nextAction.type === 'add') &&
        nextAction.valueType === 'materials'
      ) {
        tryLoadImages([nextAction.value as Material])
      }
    }, [tryLoadImages, plan.actions, plan.currentActionIndex]),
  }
}

export default useEditPlan
