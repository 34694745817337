import { SelectOption } from './props.models'

export const getResourceCategoryChoices = (parentCategory?: number): SelectOption[] => {
  const parse = (cat: any) => ({
    value: cat.id,
    label: cat.nomenclature,
  })
  if (!parentCategory) {
    return ResourceCategory.map(parse)
  } else {
    let primaryCat, secondaryCat
    for (let i = 0; i < ResourceCategory.length; i++) {
      primaryCat = ResourceCategory[i]
      if (parentCategory === primaryCat.id) {
        return primaryCat?.children.map(parse) ?? []
      }

      for (let j = 0; j < primaryCat?.children.length ?? 0; j++) {
        secondaryCat = primaryCat.children[j]
        if (parentCategory === secondaryCat.id) {
          return secondaryCat?.children?.map(parse) ?? []
        }
      }
    }
  }
  return []
}
export const checkResourceCategory = (
  primary?: number,
  secondary?: number,
  tertiary?: number,
): boolean => {
  if (!primary && !secondary && !tertiary) {
    return true
  }
  const primaryCat = ResourceCategory.find((cat) => cat.id === primary)
  if (!primaryCat) {
    return false
  } else if (!primaryCat.children || primaryCat.children.length === 0) {
    return !secondary && !tertiary
  } else {
    const secondaryCat = primaryCat.children.find((cat) => cat.id === secondary)
    if (!secondaryCat) {
      return false
    } else if (!secondaryCat.children || secondaryCat.children.length === 0) {
      return !tertiary
    } else {
      return !!secondaryCat.children.find((cat) => cat.id === tertiary)
    }
  }
}
export const traduceResourceCategory = (category?: number): string => {
  let primaryCat, secondaryCat, tertiaryCat
  for (let i = 0; i < ResourceCategory.length; i++) {
    primaryCat = ResourceCategory[i]
    if (category === primaryCat.id) {
      return primaryCat.nomenclature
    }

    for (let j = 0; j < primaryCat?.children.length ?? 0; j++) {
      secondaryCat = primaryCat.children[j]
      if (category === secondaryCat.id) {
        return secondaryCat.nomenclature
      }

      for (let k = 0; k < secondaryCat.children?.length ?? 0; k++) {
        tertiaryCat = secondaryCat.children[k]
        if (category === tertiaryCat.id) {
          return tertiaryCat.nomenclature
        }
      }
    }
  }
  return ''
}
export const ResourceCategory = [
  {
    id: 1,
    nomenclature: 'VRD (Voirie, Réseaux Divers)',
    unite: null,
    exemples: null,
    label: '1 - VRD (Voirie, Réseaux Divers)',
    children: [
      {
        id: 15,
        nomenclature: 'Réseaux extérieurs (secs, humides, fourreaux, etc)',
        unite: 'ml',
        exemples: null,
        label: '1.1 - Réseaux extérieurs (secs, humides, fourreaux, etc)',
        children: [
          {
            id: 215,
            nomenclature: 'Gaines / Fourreaux',
            unite: null,
            exemples: null,
            label: '1.1.1 - Gaines / Fourreaux',
          },
          {
            id: 216,
            nomenclature: "Réseaux d'eau enterrés (AEP, EU, EP)",
            unite: null,
            exemples: null,
            label: "1.1.2 - Réseaux d'eau enterrés (AEP, EU, EP)",
          },
          {
            id: 217,
            nomenclature: "Réseaux d'évacuation et d'assainissement",
            unite: null,
            exemples: null,
            label: "1.1.3 - Réseaux d'évacuation et d'assainissement",
          },
          {
            id: 218,
            nomenclature: 'Systèmes de drainage',
            unite: null,
            exemples: null,
            label: '1.1.4 - Systèmes de drainage',
          },
          {
            id: 219,
            nomenclature: 'Autres éléments de réseaux extérieurs',
            unite: null,
            exemples: null,
            label: '1.1.5 - Autres éléments de réseaux extérieurs',
          },
        ],
      },
      {
        id: 16,
        nomenclature: 'Stockages (cuves, bassins, etc)',
        unite: 'U, m³',
        exemples:
          "Eléments pour le pompage d'eau, systèmes de pré-traitement d'eaux usées, systèmes de récupérations et de stockages des eaux pluviales",
        label: '1.2 - Stockages (cuves, bassins, etc)',
        children: [
          {
            id: 220,
            nomenclature: 'Bassins',
            unite: null,
            exemples: null,
            label: '1.2.1 - Bassins',
          },
          {
            id: 221,
            nomenclature: 'Silos',
            unite: null,
            exemples: null,
            label: '1.2.2 - Silos',
          },
          {
            id: 222,
            nomenclature: 'Citernes',
            unite: null,
            exemples: null,
            label: '1.2.3 - Citernes',
          },
          {
            id: 223,
            nomenclature: 'Cuves',
            unite: null,
            exemples: null,
            label: '1.2.4 - Cuves',
          },
          {
            id: 224,
            nomenclature: 'Réservoirs',
            unite: null,
            exemples: null,
            label: '1.2.5 - Réservoirs',
          },
          {
            id: 225,
            nomenclature: "Récupérateurs d'eau de pluie",
            unite: null,
            exemples: null,
            label: "1.2.6 - Récupérateurs d'eau de pluie",
          },
          {
            id: 226,
            nomenclature: 'Autres éléments de réseaux extérieurs',
            unite: null,
            exemples: null,
            label: '1.2.7 - Autres éléments de réseaux extérieurs',
          },
        ],
      },
      {
        id: 17,
        nomenclature: 'Voiries, revêtements',
        unite: 'm², ml',
        exemples:
          "Sous couches, revêtement et bordures de voies d'accès (sur parcelle), sol pour aire de jeu, dallage sur plots, platelage, etc.",
        label: '1.3 - Voiries, revêtements',
        children: [
          {
            id: 227,
            nomenclature: 'Sous couches / Couches de fondation',
            unite: null,
            exemples: null,
            label: '1.3.1 - Sous couches / Couches de fondation',
          },
          {
            id: 228,
            nomenclature: 'Bordures',
            unite: null,
            exemples: null,
            label: '1.3.2 - Bordures',
          },
          {
            id: 229,
            nomenclature: 'Dalles',
            unite: null,
            exemples: null,
            label: '1.3.3 - Dalles',
          },
          {
            id: 230,
            nomenclature: 'Gravillons',
            unite: null,
            exemples: null,
            label: '1.3.4 - Gravillons',
          },
          {
            id: 231,
            nomenclature: 'Murs de soutènement',
            unite: null,
            exemples: null,
            label: '1.3.5 - Murs de soutènement',
          },
          {
            id: 232,
            nomenclature: 'Pavés',
            unite: null,
            exemples: null,
            label: '1.3.6 - Pavés',
          },
          {
            id: 233,
            nomenclature: 'Platelage',
            unite: null,
            exemples: null,
            label: '1.3.7 - Platelage',
          },
          {
            id: 234,
            nomenclature: 'Revêtements de sol extérieurs',
            unite: null,
            exemples: null,
            label: '1.3.8 - Revêtements de sol extérieurs',
          },
          {
            id: 235,
            nomenclature: 'Signalisation / matériel de voirie',
            unite: null,
            exemples: null,
            label: '1.3.9 - Signalisation / matériel de voirie',
          },
          {
            id: 236,
            nomenclature: "Regards de visites / grilles d'avaloir ",
            unite: null,
            exemples: null,
            label: "1.3.10 - Regards de visites / grilles d'avaloir ",
          },
          {
            id: 237,
            nomenclature: 'Autres éléments de voirie et revêtements',
            unite: null,
            exemples: null,
            label: '1.3.11 - Autres éléments de voirie et revêtements',
          },
        ],
      },
      {
        id: 144,
        nomenclature: 'Clôtures',
        unite: 'ml, m²',
        exemples: 'Eléments de clôture de la parcelle : grilles, murs, murets, claustras, etc.',
        label: '1.4 - Clôtures',
        children: [
          {
            id: 238,
            nomenclature: 'Portails / Portillons',
            unite: null,
            exemples: null,
            label: '1.4.1 - Portails / Portillons',
          },
          {
            id: 239,
            nomenclature: 'Murets',
            unite: null,
            exemples: null,
            label: '1.4.2 - Murets',
          },
          {
            id: 240,
            nomenclature: 'Gabions',
            unite: null,
            exemples: null,
            label: '1.4.3 - Gabions',
          },
          {
            id: 241,
            nomenclature: 'Grillages',
            unite: null,
            exemples: null,
            label: '1.4.4 - Grillages',
          },
          {
            id: 242,
            nomenclature: 'Brises vues / Claustras',
            unite: null,
            exemples: null,
            label: '1.4.5 - Brises vues / Claustras',
          },
          {
            id: 243,
            nomenclature: 'Autres éléments de clôture',
            unite: null,
            exemples: null,
            label: '1.4.6 - Autres éléments de clôture',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    nomenclature: 'Fondations et infrastructure',
    unite: null,
    exemples: null,
    label: '2 - Fondations et infrastructure',
    children: [
      {
        id: 18,
        nomenclature: 'Fondations',
        unite: 'm³',
        exemples: 'Béton de propreté, soubassement, longrines,',
        label: '2.1 - Fondations',
        children: [
          {
            id: 155,
            nomenclature: 'Hérissons',
            unite: null,
            exemples: null,
            label: '2.1.1 - Hérissons',
          },
          {
            id: 156,
            nomenclature: 'Longrines',
            unite: null,
            exemples: null,
            label: '2.1.2 - Longrines',
          },
          {
            id: 157,
            nomenclature: 'Murs de soutènement',
            unite: null,
            exemples: null,
            label: '2.1.3 - Murs de soutènement',
          },
          {
            id: 158,
            nomenclature: 'Palplanches',
            unite: null,
            exemples: null,
            label: '2.1.4 - Palplanches',
          },
          {
            id: 159,
            nomenclature: 'Pieux / Micropieux',
            unite: null,
            exemples: null,
            label: '2.1.5 - Pieux / Micropieux',
          },
          {
            id: 160,
            nomenclature: 'Plots',
            unite: null,
            exemples: null,
            label: '2.1.6 - Plots',
          },
          {
            id: 161,
            nomenclature: 'Sondes géothermiques',
            unite: null,
            exemples: null,
            label: '2.1.7 - Sondes géothermiques',
          },
          {
            id: 162,
            nomenclature: 'Autres éléments de fondation',
            unite: null,
            exemples: null,
            label: '2.1.8 - Autres éléments de fondation',
          },
        ],
      },
      {
        id: 19,
        nomenclature: 'Murs et structures enterrées (escalier de cave, parking, etc)',
        unite: 'm², m³ ou U',
        exemples: 'Structures porteuses pour parkings et locaux',
        label: '2.2 - Murs et structures enterrées (escalier de cave, parking, etc)',
        children: [
          {
            id: 163,
            nomenclature: 'Murs de soubassement',
            unite: null,
            exemples: null,
            label: '2.2.1 - Murs de soubassement',
          },
          {
            id: 164,
            nomenclature: 'Murs de sous sol',
            unite: null,
            exemples: null,
            label: '2.2.2 - Murs de sous sol',
          },
          {
            id: 165,
            nomenclature: 'Structure porteuse souterrains',
            unite: null,
            exemples: null,
            label: '2.2.3 - Structure porteuse souterrains',
          },
          {
            id: 166,
            nomenclature: 'Poteaux souterrains',
            unite: null,
            exemples: null,
            label: '2.2.4 - Poteaux souterrains',
          },
          {
            id: 167,
            nomenclature: 'Poutres souterraines',
            unite: null,
            exemples: null,
            label: '2.2.5 - Poutres souterraines',
          },
          {
            id: 168,
            nomenclature: "Parois de cage d'escalier",
            unite: null,
            exemples: null,
            label: "2.2.6 - Parois de cage d'escalier",
          },
          {
            id: 169,
            nomenclature: "Rampes d'accès véhicule",
            unite: null,
            exemples: null,
            label: "2.2.7 - Rampes d'accès véhicule",
          },
          {
            id: 170,
            nomenclature: 'Dalles de parking',
            unite: null,
            exemples: null,
            label: '2.2.8 - Dalles de parking',
          },
          {
            id: 171,
            nomenclature: 'Autres murs et structures enterrés',
            unite: null,
            exemples: null,
            label: '2.2.9 - Autres murs et structures enterrés',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    nomenclature: 'Superstructure - Maçonnerie',
    unite: null,
    exemples: null,
    label: '3 - Superstructure - Maçonnerie',
    children: [
      {
        id: 20,
        nomenclature: 'Planchers, dalles, balcons',
        unite: 'm², m³',
        exemples: 'Dalles, planchers, planchers collaborants, dalles de compression, etc.',
        label: '3.1 - Planchers, dalles, balcons',
        children: [
          {
            id: 172,
            nomenclature: 'Bacs aciers pour plancher',
            unite: null,
            exemples: null,
            label: '3.1.1 - Bacs aciers pour plancher',
          },
          {
            id: 173,
            nomenclature: 'Structures spécifiques balcons',
            unite: null,
            exemples: null,
            label: '3.1.2 - Structures spécifiques balcons',
          },
          {
            id: 174,
            nomenclature: 'Dalles / Panneaux',
            unite: null,
            exemples: null,
            label: '3.1.3 - Dalles / Panneaux',
          },
          {
            id: 175,
            nomenclature: 'Dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '3.1.4 - Dalles de toiture terrasse',
          },
          {
            id: 176,
            nomenclature: 'Hourdis / Entrevous',
            unite: null,
            exemples: null,
            label: '3.1.5 - Hourdis / Entrevous',
          },
          {
            id: 177,
            nomenclature: 'Poutrelles intégrées',
            unite: null,
            exemples: null,
            label: '3.1.6 - Poutrelles intégrées',
          },
          {
            id: 178,
            nomenclature: 'Prédalles',
            unite: null,
            exemples: null,
            label: '3.1.7 - Prédalles',
          },
          {
            id: 179,
            nomenclature: 'Solives',
            unite: null,
            exemples: null,
            label: '3.1.8 - Solives',
          },
          {
            id: 180,
            nomenclature: 'Autres éléments de plancher et dalles',
            unite: null,
            exemples: null,
            label: '3.1.9 - Autres éléments de plancher et dalles',
          },
        ],
      },
      {
        id: 21,
        nomenclature: 'Poutres',
        unite: 'ml, m³',
        exemples: 'Eléments porteurs horizontaux : poutres, linteaux,',
        label: '3.2 - Poutres',
        children: [
          {
            id: 181,
            nomenclature: 'Profilés métalliques',
            unite: null,
            exemples: null,
            label: '3.2.1 - Profilés métalliques',
          },
          {
            id: 182,
            nomenclature: 'Pannes',
            unite: null,
            exemples: null,
            label: '3.2.2 - Pannes',
          },
          {
            id: 183,
            nomenclature: 'Autres Poutres',
            unite: null,
            exemples: null,
            label: '3.2.3 - Autres Poutres',
          },
        ],
      },
      {
        id: 22,
        nomenclature: 'Façades',
        unite: 'ml, m², m³',
        exemples: 'Murs extérieurs, maçonnerie, voiles, armatures,',
        label: '3.3 - Façades',
        children: [
          {
            id: 184,
            nomenclature: 'Ossatures grille / Armatures',
            unite: null,
            exemples: null,
            label: '3.3.1 - Ossatures grille / Armatures',
          },
          {
            id: 185,
            nomenclature: 'Chainages',
            unite: null,
            exemples: null,
            label: '3.3.2 - Chainages',
          },
          {
            id: 186,
            nomenclature: 'Façades préfabriquées',
            unite: null,
            exemples: null,
            label: '3.3.3 - Façades préfabriquées',
          },
          {
            id: 187,
            nomenclature: 'Autres éléments de façade',
            unite: null,
            exemples: null,
            label: '3.3.4 - Autres éléments de façade',
          },
        ],
      },
      {
        id: 23,
        nomenclature: 'Refends',
        unite: 'ml, m², m³',
        exemples: 'Murs de refend',
        label: '3.4 - Refends',
        children: [
          {
            id: 188,
            nomenclature: 'Murs de refend',
            unite: null,
            exemples: null,
            label: '3.4.1 - Murs de refend',
          },
        ],
      },
      {
        id: 24,
        nomenclature: 'Poteaux',
        unite: 'ml, m², m³',
        exemples: null,
        label: '3.5 - Poteaux',
        children: [
          {
            id: 189,
            nomenclature: 'Poteaux',
            unite: null,
            exemples: null,
            label: '3.5.1 - Poteaux',
          },
          {
            id: 190,
            nomenclature: 'Autres poteaux',
            unite: null,
            exemples: null,
            label: '3.5.2 - Autres poteaux',
          },
        ],
      },
      {
        id: 25,
        nomenclature: 'Escaliers et rampes maçonnées',
        unite: 'U, ml',
        exemples:
          "Escaliers intérieurs, escaliers extérieurs, escaliers de secours, rampe d'accès piéton.",
        label: '3.6 - Escaliers et rampes maçonnées',
        children: [
          {
            id: 200,
            nomenclature: 'Escaliers',
            unite: null,
            exemples: null,
            label: '3.6.1 - Escaliers',
          },
          {
            id: 201,
            nomenclature: 'Rampes',
            unite: null,
            exemples: null,
            label: '3.6.2 - Rampes',
          },
          {
            id: 202,
            nomenclature: "Autres éléments d'escaliers et de rampes maçonnées",
            unite: null,
            exemples: null,
            label: "3.6.3 - Autres éléments d'escaliers et de rampes maçonnées",
          },
        ],
      },
      {
        id: 26,
        nomenclature: 'Éléments d’isolation',
        unite: 'ml, m², m³',
        exemples: 'Rupteurs thermiques et acoustiques.',
        label: '3.7 - Éléments d’isolation',
        children: [
          {
            id: 203,
            nomenclature: 'Isolation thermique ',
            unite: null,
            exemples: null,
            label: '3.7.1 - Isolation thermique ',
          },
          {
            id: 204,
            nomenclature: 'Rupteurs de pont thermique',
            unite: null,
            exemples: null,
            label: '3.7.2 - Rupteurs de pont thermique',
          },
          {
            id: 205,
            nomenclature: 'Pare-vapeurs / Ecrans',
            unite: null,
            exemples: null,
            label: '3.7.3 - Pare-vapeurs / Ecrans',
          },
          {
            id: 206,
            nomenclature: "Autres éléments d'isolation",
            unite: null,
            exemples: null,
            label: "3.7.4 - Autres éléments d'isolation",
          },
        ],
      },
      {
        id: 27,
        nomenclature: 'Maçonneries diverses (brique, parpaing, muret, etc)',
        unite: 'ml, m², m³',
        exemples: 'Appuis de baie.',
        label: '3.8 - Maçonneries diverses (brique, parpaing, muret, etc)',
        children: [
          {
            id: 207,
            nomenclature: 'Briques',
            unite: null,
            exemples: null,
            label: '3.8.1 - Briques',
          },
          {
            id: 208,
            nomenclature: 'Dalles alvéolées',
            unite: null,
            exemples: null,
            label: '3.8.2 - Dalles alvéolées',
          },
          {
            id: 209,
            nomenclature: 'Moellons',
            unite: null,
            exemples: null,
            label: '3.8.3 - Moellons',
          },
          {
            id: 210,
            nomenclature: 'Parpaings',
            unite: null,
            exemples: null,
            label: '3.8.4 - Parpaings',
          },
          {
            id: 211,
            nomenclature: 'Petits éléments de maçonnerie pour appuis de baie',
            unite: null,
            exemples: null,
            label: '3.8.5 - Petits éléments de maçonnerie pour appuis de baie',
          },
          {
            id: 212,
            nomenclature: 'Pierres',
            unite: null,
            exemples: null,
            label: '3.8.6 - Pierres',
          },
          {
            id: 213,
            nomenclature: 'Placage',
            unite: null,
            exemples: null,
            label: '3.8.7 - Placage',
          },
          {
            id: 214,
            nomenclature: 'Autres maçonneries diverses',
            unite: null,
            exemples: null,
            label: '3.8.8 - Autres maçonneries diverses',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    nomenclature: 'Couverture - Étanchéité - Charpente - Zinguerie',
    unite: null,
    exemples: null,
    label: '4 - Couverture - Étanchéité - Charpente - Zinguerie',
    children: [
      {
        id: 28,
        nomenclature: 'Toitures terrasses',
        unite: 'm², m³',
        exemples:
          'Revêtement, protection lourde, étanchéité, parevapeur, complexe pour toiture végétalisée, etc. (hors dalle porteuse).',
        label: '4.1 - Toitures terrasses',
        children: [
          {
            id: 244,
            nomenclature: 'Dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.1 - Dalles de toiture terrasse',
          },
          {
            id: 245,
            nomenclature: 'Plots sous dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.2 - Plots sous dalles de toiture terrasse',
          },
          {
            id: 246,
            nomenclature: 'Eléments de toiture végétalisée',
            unite: null,
            exemples: null,
            label: '4.1.3 - Eléments de toiture végétalisée',
          },
          {
            id: 247,
            nomenclature: 'Gravillons',
            unite: null,
            exemples: null,
            label: '4.1.4 - Gravillons',
          },
          {
            id: 248,
            nomenclature: 'Isolation thermique et acoustique pour toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.5 - Isolation thermique et acoustique pour toiture terrasse',
          },
          {
            id: 249,
            nomenclature: "Revêtement d'étanchéité",
            unite: null,
            exemples: null,
            label: "4.1.6 - Revêtement d'étanchéité",
          },
          {
            id: 250,
            nomenclature: "Protections d'étanchéité (lourdes / légères)",
            unite: null,
            exemples: null,
            label: "4.1.7 - Protections d'étanchéité (lourdes / légères)",
          },
          {
            id: 251,
            nomenclature: 'Autres éléments de toitures terrasses',
            unite: null,
            exemples: null,
            label: '4.1.8 - Autres éléments de toitures terrasses',
          },
        ],
      },
      {
        id: 29,
        nomenclature: 'Toitures en pente (tuiles, ardoises, etc)',
        unite: 'm²',
        exemples:
          'Charpentes, étanchéités, éléments de couverture (tuiles, tôles, ardoises, etc.), etc.',
        label: '4.2 - Toitures en pente (tuiles, ardoises, etc)',
        children: [
          {
            id: 252,
            nomenclature: 'Ardoises',
            unite: null,
            exemples: null,
            label: '4.2.1 - Ardoises',
          },
          {
            id: 253,
            nomenclature: 'Bacs acier',
            unite: null,
            exemples: null,
            label: '4.2.2 - Bacs acier',
          },
          {
            id: 254,
            nomenclature: 'Charpente en bois',
            unite: null,
            exemples: null,
            label: '4.2.3 - Charpente en bois',
          },
          {
            id: 255,
            nomenclature: 'Charpente métallique',
            unite: null,
            exemples: null,
            label: '4.2.4 - Charpente métallique',
          },
          {
            id: 256,
            nomenclature: 'Chevrons',
            unite: null,
            exemples: null,
            label: '4.2.5 - Chevrons',
          },
          {
            id: 257,
            nomenclature: 'Eléments de couverture en grands éléments',
            unite: null,
            exemples: null,
            label: '4.2.6 - Eléments de couverture en grands éléments',
          },
          {
            id: 258,
            nomenclature: 'Eléments de couverture en petits éléments',
            unite: null,
            exemples: null,
            label: '4.2.7 - Eléments de couverture en petits éléments',
          },
          {
            id: 259,
            nomenclature: 'Feuilles à base de bitume',
            unite: null,
            exemples: null,
            label: '4.2.8 - Feuilles à base de bitume',
          },
          {
            id: 260,
            nomenclature: 'Membranes synthétiques',
            unite: null,
            exemples: null,
            label: '4.2.9 - Membranes synthétiques',
          },
          {
            id: 261,
            nomenclature: 'Plaques ondulées fibro ciment',
            unite: null,
            exemples: null,
            label: '4.2.10 - Plaques ondulées fibro ciment',
          },
          {
            id: 262,
            nomenclature: 'Tôles',
            unite: null,
            exemples: null,
            label: '4.2.11 - Tôles',
          },
          {
            id: 263,
            nomenclature: 'Tuiles terre cuite',
            unite: null,
            exemples: null,
            label: '4.2.12 - Tuiles terre cuite',
          },
          {
            id: 264,
            nomenclature: 'Voligeages',
            unite: null,
            exemples: null,
            label: '4.2.13 - Voligeages',
          },
          {
            id: 265,
            nomenclature: 'Chaume',
            unite: null,
            exemples: null,
            label: '4.2.14 - Chaume',
          },
          {
            id: 266,
            nomenclature: 'Toitures végétales',
            unite: null,
            exemples: null,
            label: '4.2.15 - Toitures végétales',
          },
          {
            id: 267,
            nomenclature: 'Autres éléments de toiture en pente',
            unite: null,
            exemples: null,
            label: '4.2.16 - Autres éléments de toiture en pente',
          },
        ],
      },
      {
        id: 30,
        nomenclature: 'Éléments techniques de toiture',
        unite: 'ml, m², U',
        exemples: 'Cheminées, lanterneaux, désenfumages,',
        label: '4.3 - Éléments techniques de toiture',
        children: [
          {
            id: 268,
            nomenclature: 'Boisseaux',
            unite: null,
            exemples: null,
            label: '4.3.1 - Boisseaux',
          },
          {
            id: 269,
            nomenclature: 'Cheminées',
            unite: null,
            exemples: null,
            label: '4.3.2 - Cheminées',
          },
          {
            id: 270,
            nomenclature: 'Chéneaux / gouttières',
            unite: null,
            exemples: null,
            label: '4.3.3 - Chéneaux / gouttières',
          },
          {
            id: 271,
            nomenclature: 'Conduits de fumisterie',
            unite: null,
            exemples: null,
            label: '4.3.4 - Conduits de fumisterie',
          },
          {
            id: 272,
            nomenclature: "Descentes d'eaux pluviales",
            unite: null,
            exemples: null,
            label: "4.3.5 - Descentes d'eaux pluviales",
          },
          {
            id: 273,
            nomenclature: 'Lanterneaux',
            unite: null,
            exemples: null,
            label: '4.3.6 - Lanterneaux',
          },
          {
            id: 274,
            nomenclature: "Echelles d'accès en toiture",
            unite: null,
            exemples: null,
            label: "4.3.7 - Echelles d'accès en toiture",
          },
          {
            id: 275,
            nomenclature: 'Equipements de Protection Collective (EPC)',
            unite: null,
            exemples: null,
            label: '4.3.8 - Equipements de Protection Collective (EPC)',
          },
          {
            id: 276,
            nomenclature: 'Autres éléments techniques de toiture',
            unite: null,
            exemples: null,
            label: '4.3.9 - Autres éléments techniques de toiture',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    nomenclature: 'Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures',
    unite: null,
    exemples: null,
    label: '5 - Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures',
    children: [
      {
        id: 31,
        nomenclature: 'Cloisons',
        unite: 'ml, m², U',
        exemples:
          'Cloisons de distribution, cloisons fixes, cloisons mobiles, cloisons amovibles, etc.',
        label: '5.1 - Cloisons',
        children: [
          {
            id: 277,
            nomenclature: 'Blocs de béton cellulaire',
            unite: null,
            exemples: null,
            label: '5.1.1 - Blocs de béton cellulaire',
          },
          {
            id: 279,
            nomenclature: 'Carreaux de plâtre',
            unite: null,
            exemples: null,
            label: '5.1.2 - Carreaux de plâtre',
          },
          {
            id: 280,
            nomenclature: 'Cloisons amovibles / Cloisons mobiles',
            unite: null,
            exemples: null,
            label: '5.1.3 - Cloisons amovibles / Cloisons mobiles',
          },
          {
            id: 281,
            nomenclature: 'Montants de fixation en bois',
            unite: null,
            exemples: null,
            label: '5.1.4 - Montants de fixation en bois',
          },
          {
            id: 282,
            nomenclature: 'Plaques / Panneaux',
            unite: null,
            exemples: null,
            label: '5.1.5 - Plaques / Panneaux',
          },
          {
            id: 541,
            nomenclature: 'Rails métalliques',
            unite: null,
            exemples: null,
            label: '5.1.6 - Rails métalliques',
          },
          {
            id: 283,
            nomenclature: 'Autres éléments de cloisons',
            unite: null,
            exemples: null,
            label: '5.1.7 - Autres éléments de cloisons',
          },
        ],
      },
      {
        id: 32,
        nomenclature: 'Doublages mur, matériaux de protection isolants et membranes',
        unite: 'ml, m²',
        exemples: 'Enduit intérieur, plaques de plâtre, matériaux',
        label: '5.2 - Doublages mur, matériaux de protection isolants et membranes',
        children: [
          {
            id: 284,
            nomenclature: 'Complexes isolants',
            unite: null,
            exemples: null,
            label: '5.2.1 - Complexes isolants',
          },
          {
            id: 285,
            nomenclature: 'Contres cloisons isolantes en plaques',
            unite: null,
            exemples: null,
            label: '5.2.2 - Contres cloisons isolantes en plaques',
          },
          {
            id: 286,
            nomenclature: "Films étanchéité à l'air",
            unite: null,
            exemples: null,
            label: "5.2.3 - Films étanchéité à l'air",
          },
          {
            id: 287,
            nomenclature: 'Isolation mince réfléchissante',
            unite: null,
            exemples: null,
            label: '5.2.4 - Isolation mince réfléchissante',
          },
          {
            id: 288,
            nomenclature: 'Isolation thermique et acoustique intérieure',
            unite: null,
            exemples: null,
            label: '5.2.5 - Isolation thermique et acoustique intérieure',
          },
          {
            id: 289,
            nomenclature: 'Matériaux de protection incendie',
            unite: null,
            exemples: null,
            label: '5.2.6 - Matériaux de protection incendie',
          },
          {
            id: 290,
            nomenclature: 'Panneaux sandwich',
            unite: null,
            exemples: null,
            label: '5.2.7 - Panneaux sandwich',
          },
          {
            id: 291,
            nomenclature: 'Pare vapeur',
            unite: null,
            exemples: null,
            label: '5.2.8 - Pare vapeur',
          },
          {
            id: 292,
            nomenclature: 'Membranes synthétiques',
            unite: null,
            exemples: null,
            label: '5.2.9 - Membranes synthétiques',
          },
          {
            id: 293,
            nomenclature: 'Autres doublages murs, matériaux de protection isolants et membranes',
            unite: null,
            exemples: null,
            label: '5.2.10 - Autres doublages murs, matériaux de protection isolants et membranes',
          },
        ],
      },
      {
        id: 33,
        nomenclature: 'Plafonds suspendus',
        unite: 'm²',
        exemples: 'Systèmes de fixation, systèmes de suspension, plafonds tendus, etc.',
        label: '5.3 - Plafonds suspendus',
        children: [
          {
            id: 294,
            nomenclature: 'Fixations pour plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.1 - Fixations pour plafonds suspendus',
          },
          {
            id: 295,
            nomenclature: 'Isolation acoustique pour plafonds suspendus ou tendus',
            unite: null,
            exemples: null,
            label: '5.3.2 - Isolation acoustique pour plafonds suspendus ou tendus',
          },
          {
            id: 296,
            nomenclature: 'Isolation thermique pour plafonds suspendus ou tendus',
            unite: null,
            exemples: null,
            label: '5.3.3 - Isolation thermique pour plafonds suspendus ou tendus',
          },
          {
            id: 297,
            nomenclature: 'Plafonds tendus',
            unite: null,
            exemples: null,
            label: '5.3.4 - Plafonds tendus',
          },
          {
            id: 298,
            nomenclature: 'Plaques / Panneaux plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.5 - Plaques / Panneaux plafonds suspendus',
          },
          {
            id: 299,
            nomenclature: 'Rails plafond suspendus',
            unite: null,
            exemples: null,
            label: '5.3.6 - Rails plafond suspendus',
          },
          {
            id: 300,
            nomenclature: 'Autres éléments de plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.7 - Autres éléments de plafonds suspendus',
          },
        ],
      },
      {
        id: 34,
        nomenclature: 'Planchers surélevés',
        unite: 'm²',
        exemples: 'Dalles sur plots (faux-planchers)',
        label: '5.4 - Planchers surélevés',
        children: [
          {
            id: 301,
            nomenclature: 'Dalles de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.1 - Dalles de plancher surélevés',
          },
          {
            id: 302,
            nomenclature: 'Verrins',
            unite: null,
            exemples: null,
            label: '5.4.2 - Verrins',
          },
          {
            id: 303,
            nomenclature: 'Plots de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.3 - Plots de plancher surélevés',
          },
          {
            id: 304,
            nomenclature: 'Autres éléments de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.4 - Autres éléments de plancher surélevés',
          },
        ],
      },
      {
        id: 35,
        nomenclature: 'Menuiseries intérieures',
        unite: 'U, ml',
        exemples:
          'Portes intérieures, portes palières intérieures, portes coupe-feu intérieures, fenêtres intérieures, etc.',
        label: '5.5 - Menuiseries intérieures',
        children: [
          {
            id: 305,
            nomenclature: 'Blocs portes coupe-feu intérieurs',
            unite: null,
            exemples: null,
            label: '5.5.1 - Blocs portes coupe-feu intérieurs',
          },
          {
            id: 306,
            nomenclature: 'Fenêtres intérieures',
            unite: null,
            exemples: null,
            label: '5.5.2 - Fenêtres intérieures',
          },
          {
            id: 307,
            nomenclature: 'Portes / Vantaux',
            unite: null,
            exemples: null,
            label: '5.5.3 - Portes / Vantaux',
          },
          {
            id: 308,
            nomenclature: 'Huisseries',
            unite: null,
            exemples: null,
            label: '5.5.4 - Huisseries',
          },
          {
            id: 309,
            nomenclature: 'Portes palières',
            unite: null,
            exemples: null,
            label: '5.5.5 - Portes palières',
          },
          {
            id: 310,
            nomenclature: 'Portes enroulables',
            unite: null,
            exemples: null,
            label: '5.5.6 - Portes enroulables',
          },
          {
            id: 311,
            nomenclature: 'Autres menuiseries intérieures',
            unite: null,
            exemples: null,
            label: '5.5.7 - Autres menuiseries intérieures',
          },
        ],
      },
      {
        id: 145,
        nomenclature: 'Métalleries et quincailleries',
        unite: 'U, ml',
        exemples: 'Garde corps, mains courantes, poignées d’accessibilité PMR, etc.',
        label: '5.6 - Métalleries et quincailleries',
        children: [
          {
            id: 312,
            nomenclature: 'Ferronerie',
            unite: null,
            exemples: null,
            label: '5.6.1 - Ferronerie',
          },
          {
            id: 313,
            nomenclature: 'Garde-corps',
            unite: null,
            exemples: null,
            label: '5.6.2 - Garde-corps',
          },
          {
            id: 314,
            nomenclature: 'Rampes et Mains courantes',
            unite: null,
            exemples: null,
            label: '5.6.3 - Rampes et Mains courantes',
          },
          {
            id: 315,
            nomenclature: 'Poignées de porte ',
            unite: null,
            exemples: null,
            label: '5.6.4 - Poignées de porte ',
          },
          {
            id: 316,
            nomenclature: 'Serrures / verroux',
            unite: null,
            exemples: null,
            label: '5.6.5 - Serrures / verroux',
          },
          {
            id: 317,
            nomenclature: 'Ferme-portes',
            unite: null,
            exemples: null,
            label: '5.6.6 - Ferme-portes',
          },
          {
            id: 318,
            nomenclature: 'Nez de marche',
            unite: null,
            exemples: null,
            label: '5.6.7 - Nez de marche',
          },
          {
            id: 319,
            nomenclature: 'Autres éléments de metallerie et quincallerie',
            unite: null,
            exemples: null,
            label: '5.6.8 - Autres éléments de metallerie et quincallerie',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    nomenclature: 'Façades et menuiseries extérieures',
    unite: null,
    exemples: null,
    label: '6 - Façades et menuiseries extérieures',
    children: [
      {
        id: 36,
        nomenclature: 'Revêtement, isolation et doublage extérieur',
        unite: 'm²',
        exemples:
          'Eléments d’isolation des murs par l’extérieur, enduits extérieurs, façades légères non porteuses, bardages, parements de façade, pares-pluie, peintures, lasures, etc.',
        label: '6.1 - Revêtement, isolation et doublage extérieur',
        children: [
          {
            id: 321,
            nomenclature: 'Bardages double peau',
            unite: null,
            exemples: null,
            label: '6.1.1 - Bardages double peau',
          },
          {
            id: 320,
            nomenclature: 'Bardages simple peau',
            unite: null,
            exemples: null,
            label: '6.1.1 - Bardages simple peau',
          },
          {
            id: 322,
            nomenclature: 'Parement',
            unite: null,
            exemples: null,
            label: '6.1.2 - Parement',
          },
          {
            id: 323,
            nomenclature: 'Façades légères non porteuse / Murs rideaux',
            unite: null,
            exemples: null,
            label: '6.1.3 - Façades légères non porteuse / Murs rideaux',
          },
          {
            id: 324,
            nomenclature: 'Isolation thermique extérieure (ITE) ',
            unite: null,
            exemples: null,
            label: '6.1.4 - Isolation thermique extérieure (ITE) ',
          },
          {
            id: 325,
            nomenclature: 'Membranes pare pluie',
            unite: null,
            exemples: null,
            label: '6.1.5 - Membranes pare pluie',
          },
          {
            id: 326,
            nomenclature: "Système d'isolation thermique extérieure par enduit (ETICS) en panneaux",
            unite: null,
            exemples: null,
            label:
              "6.1.6 - Système d'isolation thermique extérieure par enduit (ETICS) en panneaux",
          },
          {
            id: 327,
            nomenclature: 'Vétages',
            unite: null,
            exemples: null,
            label: '6.1.7 - Vétages',
          },
          {
            id: 328,
            nomenclature: 'Vêtures',
            unite: null,
            exemples: null,
            label: '6.1.8 - Vêtures',
          },
          {
            id: 329,
            nomenclature: 'Autres revêtements extérieurs, isolation et doublage extérieur',
            unite: null,
            exemples: null,
            label: '6.1.9 - Autres revêtements extérieurs, isolation et doublage extérieur',
          },
        ],
      },
      {
        id: 37,
        nomenclature: 'Portes, fenêtres, fermetures, protections solaires',
        unite: 'U, m²',
        exemples: 'Fenêtres extérieures, portes-fenêtres, baies',
        label: '6.2 - Portes, fenêtres, fermetures, protections solaires',
        children: [
          {
            id: 330,
            nomenclature: 'Baies vitrées fixes',
            unite: null,
            exemples: null,
            label: '6.2.1 - Baies vitrées fixes',
          },
          {
            id: 331,
            nomenclature: 'Brises soleil / protections solaires',
            unite: null,
            exemples: null,
            label: '6.2.2 - Brises soleil / protections solaires',
          },
          {
            id: 332,
            nomenclature: 'Brises vue',
            unite: null,
            exemples: null,
            label: '6.2.3 - Brises vue',
          },
          {
            id: 542,
            nomenclature: 'Fenêtres de toit',
            unite: null,
            exemples: null,
            label: '6.2.4 - Fenêtres de toit',
          },
          {
            id: 333,
            nomenclature: 'Fenêtres extérieures',
            unite: null,
            exemples: null,
            label: '6.2.5 - Fenêtres extérieures',
          },
          {
            id: 334,
            nomenclature: 'Issues de secours',
            unite: null,
            exemples: null,
            label: '6.2.6 - Issues de secours',
          },
          {
            id: 335,
            nomenclature: 'Persiennes',
            unite: null,
            exemples: null,
            label: '6.2.7 - Persiennes',
          },
          {
            id: 336,
            nomenclature: 'Portes de garage extérieures',
            unite: null,
            exemples: null,
            label: '6.2.8 - Portes de garage extérieures',
          },
          {
            id: 337,
            nomenclature: 'Portes extérieures',
            unite: null,
            exemples: null,
            label: '6.2.9 - Portes extérieures',
          },
          {
            id: 338,
            nomenclature: 'Portes-fenêtres',
            unite: null,
            exemples: null,
            label: '6.2.10 - Portes-fenêtres',
          },
          {
            id: 339,
            nomenclature: "Rideaux d'occultation / Stores",
            unite: null,
            exemples: null,
            label: "6.2.11 - Rideaux d'occultation / Stores",
          },
          {
            id: 340,
            nomenclature: 'Verrières',
            unite: null,
            exemples: null,
            label: '6.2.12 - Verrières',
          },
          {
            id: 341,
            nomenclature: 'Volets',
            unite: null,
            exemples: null,
            label: '6.2.13 - Volets',
          },
          {
            id: 342,
            nomenclature: 'Portes automatique vitrées',
            unite: null,
            exemples: null,
            label: '6.2.14 - Portes automatique vitrées',
          },
          {
            id: 343,
            nomenclature: 'Portes tambours',
            unite: null,
            exemples: null,
            label: '6.2.15 - Portes tambours',
          },
          {
            id: 344,
            nomenclature: 'Autres portes, fenêtres, fermetures et protections solaires',
            unite: null,
            exemples: null,
            label: '6.2.16 - Autres portes, fenêtres, fermetures et protections solaires',
          },
        ],
      },
      {
        id: 38,
        nomenclature: 'Habillages et ossatures',
        unite: 'm²',
        exemples:
          'Habillages des tableaux et voussures, garde corps, claustras, grilles et barreaux de sécurité, vérandas, serres, couvertures vitrées d’atriums, coupoles, etc.',
        label: '6.3 - Habillages et ossatures',
        children: [
          {
            id: 345,
            nomenclature: 'Grilles et barreaux de sécurité',
            unite: null,
            exemples: null,
            label: '6.3.1 - Grilles et barreaux de sécurité',
          },
          {
            id: 346,
            nomenclature: 'Claustras',
            unite: null,
            exemples: null,
            label: '6.3.2 - Claustras',
          },
          {
            id: 347,
            nomenclature: 'Habillages de tableau',
            unite: null,
            exemples: null,
            label: '6.3.3 - Habillages de tableau',
          },
          {
            id: 348,
            nomenclature: 'Habillages de voussure',
            unite: null,
            exemples: null,
            label: '6.3.4 - Habillages de voussure',
          },
          {
            id: 349,
            nomenclature: 'Serres',
            unite: null,
            exemples: null,
            label: '6.3.5 - Serres',
          },
          {
            id: 350,
            nomenclature: 'Verandas',
            unite: null,
            exemples: null,
            label: '6.3.6 - Verandas',
          },
          {
            id: 351,
            nomenclature: 'Autres habillages et ossatures',
            unite: null,
            exemples: null,
            label: '6.3.7 - Autres habillages et ossatures',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    nomenclature:
      'Revêtement des sols, murs et plafonds - Chape - Peintures - Produits de décoration',
    unite: null,
    exemples: null,
    label: '7 - Revêtement des sols, murs et plafonds - Chape - Peintures - Produits de décoration',
    children: [
      {
        id: 39,
        nomenclature: 'Revêtement des sols (parquet, carrelage, moquette, etc)',
        unite: 'm²',
        exemples:
          'Chappes flottantes, ragréages, sous-couches acoustiques, revêtements de sol souples, revêtements de sol durs, revêtements de sol coulés, plinthes, barres de seuil, bandes podotactiles (accessibilité PMR), etc.',
        label: '7.1 - Revêtement des sols (parquet, carrelage, moquette, etc)',
        children: [
          {
            id: 352,
            nomenclature: 'Bandes podotactiles (accessibilité PMR)',
            unite: null,
            exemples: null,
            label: '7.1.1 - Bandes podotactiles (accessibilité PMR)',
          },
          {
            id: 353,
            nomenclature: 'Barres de seuil',
            unite: null,
            exemples: null,
            label: '7.1.2 - Barres de seuil',
          },
          {
            id: 354,
            nomenclature: 'Carrelage',
            unite: null,
            exemples: null,
            label: '7.1.3 - Carrelage',
          },
          {
            id: 355,
            nomenclature: 'Dalles',
            unite: null,
            exemples: null,
            label: '7.1.4 - Dalles',
          },
          {
            id: 356,
            nomenclature: 'Lames',
            unite: null,
            exemples: null,
            label: '7.1.5 - Lames',
          },
          {
            id: 357,
            nomenclature: 'Plinthes',
            unite: null,
            exemples: null,
            label: '7.1.6 - Plinthes',
          },
          {
            id: 358,
            nomenclature: 'Rouleaux',
            unite: null,
            exemples: null,
            label: '7.1.7 - Rouleaux',
          },
          {
            id: 359,
            nomenclature: 'Sous couches acoustiques',
            unite: null,
            exemples: null,
            label: '7.1.8 - Sous couches acoustiques',
          },
          {
            id: 360,
            nomenclature: 'Autres revêtements des sols',
            unite: null,
            exemples: null,
            label: '7.1.9 - Autres revêtements des sols',
          },
        ],
      },
      {
        id: 40,
        nomenclature: 'Revêtements des murs et plafonds',
        unite: 'm²',
        exemples:
          'Faïences murales, parements intérieurs (briquettes, lambris, etc.), revêtements de plafond (toile de verre, etc.)',
        label: '7.2 - Revêtements des murs et plafonds',
        children: [
          {
            id: 361,
            nomenclature: 'Briquettes',
            unite: null,
            exemples: null,
            label: '7.2.1 - Briquettes',
          },
          {
            id: 362,
            nomenclature: 'Faïence',
            unite: null,
            exemples: null,
            label: '7.2.2 - Faïence',
          },
          {
            id: 363,
            nomenclature: 'Lambris',
            unite: null,
            exemples: null,
            label: '7.2.3 - Lambris',
          },
          {
            id: 364,
            nomenclature: 'Panneaux muraux agrafés ou attachés',
            unite: null,
            exemples: null,
            label: '7.2.4 - Panneaux muraux agrafés ou attachés',
          },
          {
            id: 365,
            nomenclature: 'Plaques de revêtement / platelage',
            unite: null,
            exemples: null,
            label: '7.2.5 - Plaques de revêtement / platelage',
          },
          {
            id: 366,
            nomenclature: 'Autres revêtements des murs et plafonds',
            unite: null,
            exemples: null,
            label: '7.2.6 - Autres revêtements des murs et plafonds',
          },
        ],
      },
      {
        id: 41,
        nomenclature: 'Éléments de décoration et revêtements des menuiseries',
        unite: 'm² (ou U)',
        exemples: 'Lasures, boiseries, frises, etc.',
        label: '7.3 - Éléments de décoration et revêtements des menuiseries',
        children: [
          {
            id: 367,
            nomenclature: 'Boiseries',
            unite: null,
            exemples: null,
            label: '7.3.1 - Boiseries',
          },
          {
            id: 368,
            nomenclature: 'Frises',
            unite: null,
            exemples: null,
            label: '7.3.2 - Frises',
          },
          {
            id: 369,
            nomenclature: 'Autres éléments de décoration et revêtements des menuiseries',
            unite: null,
            exemples: null,
            label: '7.3.3 - Autres éléments de décoration et revêtements des menuiseries',
          },
        ],
      },
    ],
  },
  {
    id: 8,
    nomenclature: 'CVC (Chauffage - Ventilation - Climatisation)',
    unite: null,
    exemples: null,
    label: '8 - CVC (Chauffage - Ventilation - Climatisation)',
    children: [
      {
        id: 42,
        nomenclature:
          'Équipements de chauffage (chaudières, radiateurs à eau, radiateurs électriques, etc)',
        unite: 'U',
        exemples: 'Chaudières, poêles à bois, cheminée, insert, éléments de régulation, etc.',
        label:
          '8.1 - Équipements de chauffage (chaudières, radiateurs à eau, radiateurs électriques, etc)',
        children: [
          {
            id: 370,
            nomenclature: 'Chaudières',
            unite: null,
            exemples: null,
            label: '8.1.1 - Chaudières',
          },
          {
            id: 371,
            nomenclature: 'Cheminées',
            unite: null,
            exemples: null,
            label: '8.1.2 - Cheminées',
          },
          {
            id: 372,
            nomenclature: 'Eléments de régulation',
            unite: null,
            exemples: null,
            label: '8.1.3 - Eléments de régulation',
          },
          {
            id: 373,
            nomenclature: 'Inserts',
            unite: null,
            exemples: null,
            label: '8.1.4 - Inserts',
          },
          {
            id: 374,
            nomenclature: 'Panneaux solaires photovoltaïques',
            unite: null,
            exemples: null,
            label: '8.1.5 - Panneaux solaires photovoltaïques',
          },
          {
            id: 375,
            nomenclature: 'Panneaux solaires thermiques',
            unite: null,
            exemples: null,
            label: '8.1.6 - Panneaux solaires thermiques',
          },
          {
            id: 376,
            nomenclature: 'Plafonds rayonnants',
            unite: null,
            exemples: null,
            label: '8.1.7 - Plafonds rayonnants',
          },
          {
            id: 377,
            nomenclature: 'Planchers chauffants',
            unite: null,
            exemples: null,
            label: '8.1.8 - Planchers chauffants',
          },
          {
            id: 378,
            nomenclature: 'Planchers rayonnants',
            unite: null,
            exemples: null,
            label: '8.1.9 - Planchers rayonnants',
          },
          {
            id: 379,
            nomenclature: 'Poêles',
            unite: null,
            exemples: null,
            label: '8.1.10 - Poêles',
          },
          {
            id: 380,
            nomenclature: 'Pompes à chaleur',
            unite: null,
            exemples: null,
            label: '8.1.11 - Pompes à chaleur',
          },
          {
            id: 381,
            nomenclature: 'Radiateurs à eau',
            unite: null,
            exemples: null,
            label: '8.1.12 - Radiateurs à eau',
          },
          {
            id: 382,
            nomenclature: 'Radiateurs électriques',
            unite: null,
            exemples: null,
            label: '8.1.13 - Radiateurs électriques',
          },
          {
            id: 383,
            nomenclature: "Rideaux d'air chaud",
            unite: null,
            exemples: null,
            label: "8.1.14 - Rideaux d'air chaud",
          },
          {
            id: 384,
            nomenclature: 'Sèches mains',
            unite: null,
            exemples: null,
            label: '8.1.15 - Sèches mains',
          },
          {
            id: 385,
            nomenclature: 'Seches serviettes',
            unite: null,
            exemples: null,
            label: '8.1.16 - Seches serviettes',
          },
          {
            id: 386,
            nomenclature: 'Tubes de chauffage par le sol',
            unite: null,
            exemples: null,
            label: '8.1.17 - Tubes de chauffage par le sol',
          },
          {
            id: 387,
            nomenclature: 'Ventilo-convecteurs',
            unite: null,
            exemples: null,
            label: '8.1.18 - Ventilo-convecteurs',
          },
          {
            id: 388,
            nomenclature: 'Autres équipements de chauffage',
            unite: null,
            exemples: null,
            label: '8.1.19 - Autres équipements de chauffage',
          },
        ],
      },
      {
        id: 43,
        nomenclature:
          'Équipements de ventilation (CTA, unités intérieures, bouches de ventilation, etc)',
        unite: 'U',
        exemples: "Centrales de traitement d'air, filtres à air, VMC (simple/ double flux)",
        label:
          '8.2 - Équipements de ventilation (CTA, unités intérieures, bouches de ventilation, etc)',
        children: [
          {
            id: 389,
            nomenclature: 'Bouches de ventilation',
            unite: null,
            exemples: null,
            label: '8.2.1 - Bouches de ventilation',
          },
          {
            id: 390,
            nomenclature: "Bouches d'extraction",
            unite: null,
            exemples: null,
            label: "8.2.2 - Bouches d'extraction",
          },
          {
            id: 391,
            nomenclature: "Centrales de traitement d'air (CTA)",
            unite: null,
            exemples: null,
            label: "8.2.3 - Centrales de traitement d'air (CTA)",
          },
          {
            id: 392,
            nomenclature: "Centrales de traitement d'air (CTA) double flux",
            unite: null,
            exemples: null,
            label: "8.2.4 - Centrales de traitement d'air (CTA) double flux",
          },
          {
            id: 393,
            nomenclature: 'Diffuseurs',
            unite: null,
            exemples: null,
            label: '8.2.5 - Diffuseurs',
          },
          {
            id: 394,
            nomenclature: "Entrées d'air",
            unite: null,
            exemples: null,
            label: "8.2.6 - Entrées d'air",
          },
          {
            id: 395,
            nomenclature: 'Filtres à air',
            unite: null,
            exemples: null,
            label: '8.2.7 - Filtres à air',
          },
          {
            id: 396,
            nomenclature: 'Grilles extérieur',
            unite: null,
            exemples: null,
            label: '8.2.8 - Grilles extérieur',
          },
          {
            id: 397,
            nomenclature: 'Terminaux passifs',
            unite: null,
            exemples: null,
            label: '8.2.9 - Terminaux passifs',
          },
          {
            id: 398,
            nomenclature: 'Ventilateurs',
            unite: null,
            exemples: null,
            label: '8.2.10 - Ventilateurs',
          },
          {
            id: 399,
            nomenclature: 'VMC',
            unite: null,
            exemples: null,
            label: '8.2.11 - VMC',
          },
          {
            id: 400,
            nomenclature: 'Conduits de fumisterie',
            unite: null,
            exemples: null,
            label: '8.2.12 - Conduits de fumisterie',
          },
          {
            id: 401,
            nomenclature: 'Autres équipements de ventilation',
            unite: null,
            exemples: null,
            label: '8.2.13 - Autres équipements de ventilation',
          },
        ],
      },
      {
        id: 44,
        nomenclature: 'Équipements de climatisation (groupes froids, cassettes, etc)',
        unite: 'U',
        exemples: 'Pompes à chaleur',
        label: '8.3 - Équipements de climatisation (groupes froids, cassettes, etc)',
        children: [
          {
            id: 402,
            nomenclature: 'Débit de réfrigérant variable (DRV)',
            unite: null,
            exemples: null,
            label: '8.3.1 - Débit de réfrigérant variable (DRV)',
          },
          {
            id: 403,
            nomenclature: 'Groupes froids',
            unite: null,
            exemples: null,
            label: '8.3.2 - Groupes froids',
          },
          {
            id: 404,
            nomenclature: 'Cassettes',
            unite: null,
            exemples: null,
            label: '8.3.3 - Cassettes',
          },
          {
            id: 405,
            nomenclature: 'Tours aéroréfrigérantes',
            unite: null,
            exemples: null,
            label: '8.3.4 - Tours aéroréfrigérantes',
          },
          {
            id: 406,
            nomenclature: 'Autres équipements de climatisation',
            unite: null,
            exemples: null,
            label: '8.3.5 - Autres équipements de climatisation',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    nomenclature: 'Installations sanitaires',
    unite: null,
    exemples: null,
    label: '9 - Installations sanitaires',
    children: [
      {
        id: 45,
        nomenclature: 'Production eau chaude sanitaire (ballons ECS, etc)',
        unite: 'U',
        exemples:
          'Chauffes-eau (thermodynamique, électrique, gaz), chauffes-eau solaire individuels, éléments de régulation, etc.',
        label: '9.1 - Production eau chaude sanitaire (ballons ECS, etc)',
        children: [
          {
            id: 407,
            nomenclature: 'Chauffe eau ',
            unite: null,
            exemples: null,
            label: '9.1.1 - Chauffe eau ',
          },
          {
            id: 408,
            nomenclature: 'Cumulus',
            unite: null,
            exemples: null,
            label: '9.1.2 - Cumulus',
          },
          {
            id: 409,
            nomenclature: 'Panneaux de production eau chaude sanitaire',
            unite: null,
            exemples: null,
            label: '9.1.3 - Panneaux de production eau chaude sanitaire',
          },
          {
            id: 410,
            nomenclature: 'Eléments de régulation',
            unite: null,
            exemples: null,
            label: '9.1.4 - Eléments de régulation',
          },
          {
            id: 411,
            nomenclature: 'Autres équipements production eau chaude sanitaire',
            unite: null,
            exemples: null,
            label: '9.1.5 - Autres équipements production eau chaude sanitaire',
          },
        ],
      },
      {
        id: 46,
        nomenclature: 'Réseaux intérieurs de distribution et d’évacuation',
        unite: 'ml, U, m² ',
        exemples:
          'Conduits flexibles, conduits rigides, coudes et accessoires, canalisations, etc.',
        label: '9.2 - Réseaux intérieurs de distribution et d’évacuation',
        children: [
          {
            id: 412,
            nomenclature: 'Calorifugeage de canalisations',
            unite: null,
            exemples: null,
            label: '9.2.1 - Calorifugeage de canalisations',
          },
          {
            id: 413,
            nomenclature: 'Canalisations',
            unite: null,
            exemples: null,
            label: '9.2.2 - Canalisations',
          },
          {
            id: 414,
            nomenclature: 'Conduits flexibles',
            unite: null,
            exemples: null,
            label: '9.2.3 - Conduits flexibles',
          },
          {
            id: 415,
            nomenclature: 'Conduits rigides',
            unite: null,
            exemples: null,
            label: '9.2.4 - Conduits rigides',
          },
          {
            id: 416,
            nomenclature: 'Coudes et accessoires',
            unite: null,
            exemples: null,
            label: '9.2.5 - Coudes et accessoires',
          },
          {
            id: 417,
            nomenclature: 'Filtres',
            unite: null,
            exemples: null,
            label: '9.2.6 - Filtres',
          },
          {
            id: 418,
            nomenclature: 'Siphons de sol',
            unite: null,
            exemples: null,
            label: '9.2.7 - Siphons de sol',
          },
          {
            id: 419,
            nomenclature: "Autres éléments de réseaux intérieurs de distribution et d'évacuation",
            unite: null,
            exemples: null,
            label: "9.2.8 - Autres éléments de réseaux intérieurs de distribution et d'évacuation",
          },
        ],
      },
      {
        id: 47,
        nomenclature:
          'Appareils sanitaires (lavabo, WC, vidoirs, urinoirs, vasques, éviers, mitigeurs, etc)',
        unite: 'U',
        exemples:
          'Receveurs de douches, baignoires, toilettes (cuvette et chasse), lavabos, éviers, fontaines à eau, robinetterie, portes et parois de cabines de douche, etc.',
        label:
          '9.3 - Appareils sanitaires (lavabo, WC, vidoirs, urinoirs, vasques, éviers, mitigeurs, etc)',
        children: [
          {
            id: 420,
            nomenclature: 'Baignoires',
            unite: null,
            exemples: null,
            label: '9.3.1 - Baignoires',
          },
          {
            id: 421,
            nomenclature: 'Bidets',
            unite: null,
            exemples: null,
            label: '9.3.2 - Bidets',
          },
          {
            id: 422,
            nomenclature: 'Colonnes de douche',
            unite: null,
            exemples: null,
            label: '9.3.3 - Colonnes de douche',
          },
          {
            id: 423,
            nomenclature: 'Cuvettes de WC',
            unite: null,
            exemples: null,
            label: '9.3.4 - Cuvettes de WC',
          },
          {
            id: 424,
            nomenclature: 'Douchettes',
            unite: null,
            exemples: null,
            label: '9.3.5 - Douchettes',
          },
          {
            id: 425,
            nomenclature: 'Eviers',
            unite: null,
            exemples: null,
            label: '9.3.6 - Eviers',
          },
          {
            id: 426,
            nomenclature: 'Fontaines à eau',
            unite: null,
            exemples: null,
            label: '9.3.7 - Fontaines à eau',
          },
          {
            id: 427,
            nomenclature: 'Lavabos',
            unite: null,
            exemples: null,
            label: '9.3.8 - Lavabos',
          },
          {
            id: 428,
            nomenclature: 'Laves-mains',
            unite: null,
            exemples: null,
            label: '9.3.9 - Laves-mains',
          },
          {
            id: 429,
            nomenclature: 'Mécanisme de chasse',
            unite: null,
            exemples: null,
            label: '9.3.10 - Mécanisme de chasse',
          },
          {
            id: 430,
            nomenclature: 'Mitigeurs / Mélangeur',
            unite: null,
            exemples: null,
            label: '9.3.11 - Mitigeurs / Mélangeur',
          },
          {
            id: 431,
            nomenclature: 'Portes et parois de cabine de douche',
            unite: null,
            exemples: null,
            label: '9.3.12 - Portes et parois de cabine de douche',
          },
          {
            id: 432,
            nomenclature: 'Receveurs de douche',
            unite: null,
            exemples: null,
            label: '9.3.13 - Receveurs de douche',
          },
          {
            id: 433,
            nomenclature: 'Siphons',
            unite: null,
            exemples: null,
            label: '9.3.14 - Siphons',
          },
          {
            id: 434,
            nomenclature: "Systèmes économiseurs d'eau",
            unite: null,
            exemples: null,
            label: "9.3.15 - Systèmes économiseurs d'eau",
          },
          {
            id: 435,
            nomenclature: 'Urinoirs',
            unite: null,
            exemples: null,
            label: '9.3.16 - Urinoirs',
          },
          {
            id: 436,
            nomenclature: 'Vidoirs / Déversoirs',
            unite: null,
            exemples: null,
            label: '9.3.17 - Vidoirs / Déversoirs',
          },
          {
            id: 437,
            nomenclature: 'Autres appareils sanitaires',
            unite: null,
            exemples: null,
            label: '9.3.18 - Autres appareils sanitaires',
          },
        ],
      },
      {
        id: 146,
        nomenclature: 'Sprinklages et réseaux',
        unite: 'U, ml',
        exemples: 'Réseaux de plomberie dédiés à la sécurité incendie',
        label: '9.4 - Sprinklages et réseaux',
        children: [
          {
            id: 438,
            nomenclature: 'Accessoires de sprinklage',
            unite: null,
            exemples: null,
            label: '9.4.1 - Accessoires de sprinklage',
          },
          {
            id: 439,
            nomenclature: 'Caisson de désenfumage',
            unite: null,
            exemples: null,
            label: '9.4.2 - Caisson de désenfumage',
          },
          {
            id: 440,
            nomenclature: 'Cartouche coupe-feu ou pare flamme',
            unite: null,
            exemples: null,
            label: '9.4.3 - Cartouche coupe-feu ou pare flamme',
          },
          {
            id: 441,
            nomenclature: 'Clapets coupe feu',
            unite: null,
            exemples: null,
            label: '9.4.4 - Clapets coupe feu',
          },
          {
            id: 442,
            nomenclature: 'Grilles ou volets de désenfumage',
            unite: null,
            exemples: null,
            label: '9.4.5 - Grilles ou volets de désenfumage',
          },
          {
            id: 443,
            nomenclature: 'Réseau de sprinklage',
            unite: null,
            exemples: null,
            label: '9.4.6 - Réseau de sprinklage',
          },
          {
            id: 444,
            nomenclature: "Réseaux et terminaux RIA (Robinet d'Incendie Armé)",
            unite: null,
            exemples: null,
            label: "9.4.7 - Réseaux et terminaux RIA (Robinet d'Incendie Armé)",
          },
          {
            id: 445,
            nomenclature: 'Enrouleurs et lances à incendie',
            unite: null,
            exemples: null,
            label: '9.4.8 - Enrouleurs et lances à incendie',
          },
          {
            id: 446,
            nomenclature: 'Autres éléments de sprinklage et de réseaux de sécurité incendie',
            unite: null,
            exemples: null,
            label: '9.4.9 - Autres éléments de sprinklage et de réseaux de sécurité incendie',
          },
        ],
      },
    ],
  },
  {
    id: 10,
    nomenclature: 'Réseaux (CFO/CFA)',
    unite: null,
    exemples: null,
    label: '10 - Réseaux (CFO/CFA)',
    children: [
      {
        id: 48,
        nomenclature: 'Tableau général basse tension et armoires divisionnaires',
        unite: 'U',
        exemples: null,
        label: '10.1 - Tableau général basse tension et armoires divisionnaires',
        children: [
          {
            id: 447,
            nomenclature: 'Armoires divisionnaires / Tableaux divisionnaires',
            unite: null,
            exemples: null,
            label: '10.1.1 - Armoires divisionnaires / Tableaux divisionnaires',
          },
          {
            id: 448,
            nomenclature: 'Tableaux généraux basse tension (TGBT)',
            unite: null,
            exemples: null,
            label: '10.1.2 - Tableaux généraux basse tension (TGBT)',
          },
          {
            id: 449,
            nomenclature: 'Transformateurs électriques',
            unite: null,
            exemples: null,
            label: '10.1.3 - Transformateurs électriques',
          },
          {
            id: 450,
            nomenclature: 'Protections modulaires',
            unite: null,
            exemples: null,
            label: '10.1.4 - Protections modulaires',
          },
          {
            id: 451,
            nomenclature: 'Disjoncteurs',
            unite: null,
            exemples: null,
            label: '10.1.5 - Disjoncteurs',
          },
          {
            id: 452,
            nomenclature: 'Cellules basse tension et moyenne tension',
            unite: null,
            exemples: null,
            label: '10.1.6 - Cellules basse tension et moyenne tension',
          },
          {
            id: 453,
            nomenclature: 'Installations de recharge de véhicule électrique',
            unite: null,
            exemples: null,
            label: '10.1.7 - Installations de recharge de véhicule électrique',
          },
          {
            id: 454,
            nomenclature:
              'Autres éléments de tableau général basse tension et armoires divisionnaires',
            unite: null,
            exemples: null,
            label:
              '10.1.8 - Autres éléments de tableau général basse tension et armoires divisionnaires',
          },
        ],
      },
      {
        id: 49,
        nomenclature:
          'Distributions électriques (câblages, chemins de câbles, réseaux informatiques et téléphoniques, etc.)',
        unite: 'ml, U',
        exemples:
          'Fils et câbles électriques, gaines, chemins de câbles, plinthes techniques, goulottes, fils et câbles de communication.',
        label:
          '10.2 - Distributions électriques (câblages, chemins de câbles, réseaux informatiques et téléphoniques, etc.)',
        children: [
          {
            id: 455,
            nomenclature: 'Câbles / Fils électriques',
            unite: null,
            exemples: null,
            label: '10.2.1 - Câbles / Fils électriques',
          },
          {
            id: 456,
            nomenclature: 'Chemins de câbles',
            unite: null,
            exemples: null,
            label: '10.2.2 - Chemins de câbles',
          },
          {
            id: 457,
            nomenclature: 'Gaines / Fourreaux',
            unite: null,
            exemples: null,
            label: '10.2.3 - Gaines / Fourreaux',
          },
          {
            id: 458,
            nomenclature: 'Goulottes',
            unite: null,
            exemples: null,
            label: '10.2.4 - Goulottes',
          },
          {
            id: 459,
            nomenclature: 'Plinthes techniques',
            unite: null,
            exemples: null,
            label: '10.2.5 - Plinthes techniques',
          },
          {
            id: 460,
            nomenclature: 'Prises de terre',
            unite: null,
            exemples: null,
            label: '10.2.6 - Prises de terre',
          },
          {
            id: 461,
            nomenclature: 'Paratonnerres',
            unite: null,
            exemples: null,
            label: '10.2.7 - Paratonnerres',
          },
          {
            id: 462,
            nomenclature: 'Autres élements de distribution électrique',
            unite: null,
            exemples: null,
            label: '10.2.8 - Autres élements de distribution électrique',
          },
        ],
      },
      {
        id: 50,
        nomenclature: 'Appareils d’éclairage',
        unite: 'U',
        exemples:
          "Eclairages intérieurs généraux (hors éclairage de sécurité), éclairages d'extérieur généraux (lampadaires, hublots, etc.), systèmes de contrôle et de régulation de l'éclairage, etc.",
        label: '10.3 - Appareils d’éclairage',
        children: [
          {
            id: 463,
            nomenclature: 'Balises',
            unite: null,
            exemples: null,
            label: '10.3.1 - Balises',
          },
          {
            id: 464,
            nomenclature: 'Bornes lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.2 - Bornes lumineuses',
          },
          {
            id: 465,
            nomenclature: 'Colonnes lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.3 - Colonnes lumineuses',
          },
          {
            id: 466,
            nomenclature: 'Eclairages en applique',
            unite: null,
            exemples: null,
            label: '10.3.4 - Eclairages en applique',
          },
          {
            id: 467,
            nomenclature: 'Eclairages en suspension',
            unite: null,
            exemples: null,
            label: '10.3.5 - Eclairages en suspension',
          },
          {
            id: 468,
            nomenclature: 'Hublots',
            unite: null,
            exemples: null,
            label: '10.3.6 - Hublots',
          },
          {
            id: 469,
            nomenclature: 'Lampadaires',
            unite: null,
            exemples: null,
            label: '10.3.7 - Lampadaires',
          },
          {
            id: 470,
            nomenclature: "Supports d'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.8 - Supports d'éclairage",
          },
          {
            id: 471,
            nomenclature: "Systèmes de contrôle et de régulation de l'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.9 - Systèmes de contrôle et de régulation de l'éclairage",
          },
          {
            id: 472,
            nomenclature: 'Dalles lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.10 - Dalles lumineuses',
          },
          {
            id: 473,
            nomenclature: 'Réglettes',
            unite: null,
            exemples: null,
            label: '10.3.11 - Réglettes',
          },
          {
            id: 474,
            nomenclature: 'Eclairages encastrés',
            unite: null,
            exemples: null,
            label: '10.3.12 - Eclairages encastrés',
          },
          {
            id: 475,
            nomenclature: "Autres appareils d'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.13 - Autres appareils d'éclairage",
          },
        ],
      },
      {
        id: 51,
        nomenclature: 'Équipements terminaux (interrupteurs, prises, etc)',
        unite: 'U',
        exemples: 'Interrupteurs, prises, etc',
        label: '10.4 - Équipements terminaux (interrupteurs, prises, etc)',
        children: [
          {
            id: 476,
            nomenclature: 'Prises courants forts',
            unite: null,
            exemples: null,
            label: '10.4.1 - Prises courants forts',
          },
          {
            id: 477,
            nomenclature: 'Prises courants faibles (RJ45...)',
            unite: null,
            exemples: null,
            label: '10.4.2 - Prises courants faibles (RJ45...)',
          },
          {
            id: 478,
            nomenclature: 'Autres équipements terminaux',
            unite: null,
            exemples: null,
            label: '10.4.3 - Autres équipements terminaux',
          },
        ],
      },
      {
        id: 52,
        nomenclature: 'Armoire VDI / baies informatiques',
        unite: 'U',
        exemples: null,
        label: '10.5 - Armoire VDI / baies informatiques',
        children: [
          {
            id: 479,
            nomenclature: 'Armoire VDI (Voix Données Images)',
            unite: null,
            exemples: null,
            label: '10.5.1 - Armoire VDI (Voix Données Images)',
          },
          {
            id: 480,
            nomenclature: 'Baie de brassage informatique',
            unite: null,
            exemples: null,
            label: '10.5.2 - Baie de brassage informatique',
          },
          {
            id: 481,
            nomenclature: "Autres éléments d'armoires et de baies informatiques",
            unite: null,
            exemples: null,
            label: "10.5.3 - Autres éléments d'armoires et de baies informatiques",
          },
        ],
      },
      {
        id: 53,
        nomenclature:
          "Équipements spécifiques (caméras, bornes Wi-Fi, équipements GTB, contrôles d'accès, etc.)",
        unite: 'U',
        exemples:
          "Systèmes de détection d'intrusion, système de contrôle d'accès, système de vidéosurveillance, système d'éclairage de sécurité, etc.",
        label:
          "10.6 - Équipements spécifiques (caméras, bornes Wi-Fi, équipements GTB, contrôles d'accès, etc.)",
        children: [
          {
            id: 482,
            nomenclature: 'Câbles de télécommunication',
            unite: null,
            exemples: null,
            label: '10.6.1 - Câbles de télécommunication',
          },
          {
            id: 483,
            nomenclature: 'Fils de télécommunications',
            unite: null,
            exemples: null,
            label: '10.6.2 - Fils de télécommunications',
          },
          {
            id: 484,
            nomenclature: 'Caméras',
            unite: null,
            exemples: null,
            label: '10.6.3 - Caméras',
          },
          {
            id: 485,
            nomenclature: 'Boitier répétiteur',
            unite: null,
            exemples: null,
            label: '10.6.4 - Boitier répétiteur',
          },
          {
            id: 486,
            nomenclature: 'Autres équipements spécifiques',
            unite: null,
            exemples: null,
            label: '10.6.5 - Autres équipements spécifiques',
          },
        ],
      },
      {
        id: 54,
        nomenclature: 'Systèmes de sécurité',
        unite: 'U',
        exemples: 'Systèmes de sécurité incendie, paratonnerres',
        label: '10.7 - Systèmes de sécurité',
        children: [
          {
            id: 487,
            nomenclature: "Blocs autonomes d'éclairage de sécurité (BAES)",
            unite: null,
            exemples: null,
            label: "10.7.1 - Blocs autonomes d'éclairage de sécurité (BAES)",
          },
          {
            id: 488,
            nomenclature: "Blocs autonomes d'éclairage d'habitation (BAEH)",
            unite: null,
            exemples: null,
            label: "10.7.2 - Blocs autonomes d'éclairage d'habitation (BAEH)",
          },
          {
            id: 489,
            nomenclature: 'Luminaires pour source centrale (LSC)',
            unite: null,
            exemples: null,
            label: '10.7.3 - Luminaires pour source centrale (LSC)',
          },
          {
            id: 490,
            nomenclature: "Systèmes de contrôle d'accès",
            unite: null,
            exemples: null,
            label: "10.7.4 - Systèmes de contrôle d'accès",
          },
          {
            id: 491,
            nomenclature: "Systèmes de détection d'intrusion",
            unite: null,
            exemples: null,
            label: "10.7.5 - Systèmes de détection d'intrusion",
          },
          {
            id: 492,
            nomenclature: 'Systèmes de sécurité incendie',
            unite: null,
            exemples: null,
            label: '10.7.6 - Systèmes de sécurité incendie',
          },
          {
            id: 493,
            nomenclature: 'Systèmes de vidéosurveillance',
            unite: null,
            exemples: null,
            label: '10.7.7 - Systèmes de vidéosurveillance',
          },
          {
            id: 494,
            nomenclature: "Systèmes d'éclairage de sécurité",
            unite: null,
            exemples: null,
            label: "10.7.8 - Systèmes d'éclairage de sécurité",
          },
          {
            id: 495,
            nomenclature: 'Autres système de sécurité',
            unite: null,
            exemples: null,
            label: '10.7.9 - Autres système de sécurité',
          },
        ],
      },
      {
        id: 55,
        nomenclature: 'Équipements spéciaux divers',
        unite: 'U',
        exemples: 'Transformateurs, motorisations de volets et portes, etc.',
        label: '10.8 - Équipements spéciaux divers',
        children: [
          {
            id: 496,
            nomenclature:
              'Installations et appareillages pour réseaux de communication filaires ou sans fil',
            unite: null,
            exemples: null,
            label:
              '10.8.1 - Installations et appareillages pour réseaux de communication filaires ou sans fil',
          },
          {
            id: 497,
            nomenclature: 'Autres équipements spéciaux divers',
            unite: null,
            exemples: null,
            label: '10.8.2 - Autres équipements spéciaux divers',
          },
        ],
      },
    ],
  },
  {
    id: 11,
    nomenclature: 'Mobiliers',
    unite: null,
    exemples: null,
    label: '11 - Mobiliers',
    children: [
      {
        id: 147,
        nomenclature: 'Mobiliers intérieurs',
        unite: 'U',
        exemples: 'Mobiliers de bureaux, mobiliers de restauration, mobiliers scolaire, etc.',
        label: '11.1 - Mobiliers intérieurs',
        children: [
          {
            id: 498,
            nomenclature: "Banques d'acceuil / Consoles",
            unite: null,
            exemples: null,
            label: "11.1.1 - Banques d'acceuil / Consoles",
          },
          {
            id: 499,
            nomenclature: 'Boîtes aux lettres',
            unite: null,
            exemples: null,
            label: '11.1.2 - Boîtes aux lettres',
          },
          {
            id: 500,
            nomenclature: 'Bureaux',
            unite: null,
            exemples: null,
            label: '11.1.3 - Bureaux',
          },
          {
            id: 501,
            nomenclature: 'Casiers',
            unite: null,
            exemples: null,
            label: '11.1.4 - Casiers',
          },
          {
            id: 502,
            nomenclature: 'Chaises',
            unite: null,
            exemples: null,
            label: '11.1.5 - Chaises',
          },
          {
            id: 503,
            nomenclature: 'Fauteuils',
            unite: null,
            exemples: null,
            label: '11.1.6 - Fauteuils',
          },
          {
            id: 504,
            nomenclature: 'Mobiliers de restauration',
            unite: null,
            exemples: null,
            label: '11.1.7 - Mobiliers de restauration',
          },
          {
            id: 505,
            nomenclature: 'Placards préfabriqués ou menuisés',
            unite: null,
            exemples: null,
            label: '11.1.8 - Placards préfabriqués ou menuisés',
          },
          {
            id: 506,
            nomenclature: 'Tables',
            unite: null,
            exemples: null,
            label: '11.1.9 - Tables',
          },
          {
            id: 507,
            nomenclature: 'Tabourets',
            unite: null,
            exemples: null,
            label: '11.1.10 - Tabourets',
          },
          {
            id: 508,
            nomenclature: 'Autres mobiliers intérieurs',
            unite: null,
            exemples: null,
            label: '11.1.11 - Autres mobiliers intérieurs',
          },
        ],
      },
      {
        id: 148,
        nomenclature: 'Mobiliers extérieurs',
        unite: 'U',
        exemples: 'Jardinières, pots, racks à vélo, mobiliers de jardin, etc.',
        label: '11.2 - Mobiliers extérieurs',
        children: [
          {
            id: 509,
            nomenclature: 'Mobilier de jardin',
            unite: null,
            exemples: null,
            label: '11.2.1 - Mobilier de jardin',
          },
          {
            id: 510,
            nomenclature: 'Pots/ bacs à végétaux / jardinières',
            unite: null,
            exemples: null,
            label: '11.2.2 - Pots/ bacs à végétaux / jardinières',
          },
          {
            id: 511,
            nomenclature: 'Racks à vélo',
            unite: null,
            exemples: null,
            label: '11.2.3 - Racks à vélo',
          },
          {
            id: 512,
            nomenclature: 'Autres mobiliers extérieurs',
            unite: null,
            exemples: null,
            label: '11.2.4 - Autres mobiliers extérieurs',
          },
        ],
      },
      {
        id: 149,
        nomenclature: 'Équipements',
        unite: 'U',
        exemples:
          'Electroménager, équipement de cuisine, écrans, ordinateurs et accessoires, téléphonie, sèchemain, etc.',
        label: '11.3 - Équipements',
        children: [
          {
            id: 513,
            nomenclature: 'Abattans de WC',
            unite: null,
            exemples: null,
            label: '11.3.1 - Abattans de WC',
          },
          {
            id: 514,
            nomenclature: 'Dévidoirs à papier',
            unite: null,
            exemples: null,
            label: '11.3.2 - Dévidoirs à papier',
          },
          {
            id: 515,
            nomenclature: 'Distributeurs de savon',
            unite: null,
            exemples: null,
            label: '11.3.3 - Distributeurs de savon',
          },
          {
            id: 516,
            nomenclature: 'Ecrans',
            unite: null,
            exemples: null,
            label: '11.3.4 - Ecrans',
          },
          {
            id: 517,
            nomenclature: 'Equipements électroménager',
            unite: null,
            exemples: null,
            label: '11.3.5 - Equipements électroménager',
          },
          {
            id: 518,
            nomenclature: 'Equipements de cuisine',
            unite: null,
            exemples: null,
            label: '11.3.6 - Equipements de cuisine',
          },
          {
            id: 519,
            nomenclature: 'Equipements de téléphonie',
            unite: null,
            exemples: null,
            label: '11.3.7 - Equipements de téléphonie',
          },
          {
            id: 520,
            nomenclature: 'Equipements technique de RIE',
            unite: null,
            exemples: null,
            label: '11.3.8 - Equipements technique de RIE',
          },
          {
            id: 521,
            nomenclature: 'Autres équipements ',
            unite: null,
            exemples: null,
            label: '11.3.9 - Autres équipements ',
          },
        ],
      },
      {
        id: 150,
        nomenclature: 'Autres',
        unite: 'U',
        exemples: null,
        label: '11.4 - Autres',
        children: [
          {
            id: 522,
            nomenclature: 'Autres',
            unite: null,
            exemples: null,
            label: '11.4.1 - Autres',
          },
        ],
      },
    ],
  },
  {
    id: 12,
    nomenclature: 'Appareils élévateurs et autres équipements de transport intérieur',
    unite: null,
    exemples: null,
    label: '12 - Appareils élévateurs et autres équipements de transport intérieur',
    children: [
      {
        id: 56,
        nomenclature: 'Ascenseurs',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.1 - Ascenseurs',
        children: [
          {
            id: 523,
            nomenclature: 'Ascenseurs',
            unite: null,
            exemples: null,
            label: '12.1.1 - Ascenseurs',
          },
          {
            id: 524,
            nomenclature: "Auxiliaires d'ascenseur",
            unite: null,
            exemples: null,
            label: "12.1.2 - Auxiliaires d'ascenseur",
          },
          {
            id: 525,
            nomenclature: "Autres éléments d'ascenseur",
            unite: null,
            exemples: null,
            label: "12.1.3 - Autres éléments d'ascenseur",
          },
        ],
      },
      {
        id: 57,
        nomenclature: 'Montes-charges',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.2 - Montes-charges',
        children: [
          {
            id: 526,
            nomenclature: 'Montes charge',
            unite: null,
            exemples: null,
            label: '12.2.1 - Montes charge',
          },
          {
            id: 527,
            nomenclature: 'Auxiliaires de monte charge',
            unite: null,
            exemples: null,
            label: '12.2.2 - Auxiliaires de monte charge',
          },
          {
            id: 528,
            nomenclature: 'Autres éléments de monte-charge',
            unite: null,
            exemples: null,
            label: '12.2.3 - Autres éléments de monte-charge',
          },
        ],
      },
      {
        id: 58,
        nomenclature: 'Escaliers mécaniques',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.3 - Escaliers mécaniques',
        children: [
          {
            id: 529,
            nomenclature: 'Escaliers mécaniques',
            unite: null,
            exemples: null,
            label: '12.3.1 - Escaliers mécaniques',
          },
          {
            id: 530,
            nomenclature: "Auxiliaires d'escalier mécanique",
            unite: null,
            exemples: null,
            label: "12.3.2 - Auxiliaires d'escalier mécanique",
          },
          {
            id: 531,
            nomenclature: "Autres éléments d'escaliers mécaniques",
            unite: null,
            exemples: null,
            label: "12.3.3 - Autres éléments d'escaliers mécaniques",
          },
        ],
      },
    ],
  },
  {
    id: 13,
    nomenclature: 'Équipement de production locale d’électricité',
    unite: null,
    exemples: null,
    label: '13 - Équipement de production locale d’électricité',
    children: [
      {
        id: 59,
        nomenclature: 'Groupe électrogène',
        unite: 'U',
        exemples: null,
        label: '13.1 - Groupe électrogène',
        children: [
          {
            id: 532,
            nomenclature: 'Groupes électrogènes',
            unite: null,
            exemples: null,
            label: '13.1.1 - Groupes électrogènes',
          },
          {
            id: 533,
            nomenclature: 'Panneaux photovoltaiques / Onduleurs ',
            unite: null,
            exemples: null,
            label: '13.1.2 - Panneaux photovoltaiques / Onduleurs ',
          },
          {
            id: 534,
            nomenclature: 'Onduleurs sur batteries',
            unite: null,
            exemples: null,
            label: '13.1.3 - Onduleurs sur batteries',
          },
          {
            id: 535,
            nomenclature: 'Autres types de groupe électrogène',
            unite: null,
            exemples: null,
            label: '13.1.4 - Autres types de groupe électrogène',
          },
        ],
      },
    ],
  },
  {
    id: 14,
    nomenclature: 'Autres',
    unite: null,
    exemples: null,
    label: '14 - Autres',
    children: [
      {
        id: 60,
        nomenclature: 'Autres',
        unite: 'U, m², ml, m³',
        exemples:
          'Matériaux bruts (pierre, sables, etc.), autres éléments ne correspondant pas aux catégories prédéfinies.',
        label: '14.1 - Autres',
        children: [
          {
            id: 536,
            nomenclature: "Eléments d'espaces verts",
            unite: null,
            exemples: null,
            label: "14.1.1 - Eléments d'espaces verts",
          },
          {
            id: 537,
            nomenclature: 'Extincteurs',
            unite: null,
            exemples: null,
            label: '14.1.2 - Extincteurs',
          },
          {
            id: 538,
            nomenclature: 'Matériaux bruts (pierre, sables, etc.)',
            unite: null,
            exemples: null,
            label: '14.1.3 - Matériaux bruts (pierre, sables, etc.)',
          },
          {
            id: 539,
            nomenclature: 'Remblais',
            unite: null,
            exemples: null,
            label: '14.1.4 - Remblais',
          },
          {
            id: 540,
            nomenclature: 'Autres éléments ne correspondant pas aux catégories prédéfinies',
            unite: null,
            exemples: null,
            label: '14.1.5 - Autres éléments ne correspondant pas aux catégories prédéfinies',
          },
        ],
      },
    ],
  },
]

export const getWasteCategoryChoices = (parentCategory?: number): SelectOption[] => {
  const parse = (cat: any) => ({
    value: cat.id,
    label: cat.nomenclature,
  })
  if (!parentCategory) {
    return WasteCategory.map(parse)
  } else {
    let primaryCat, secondaryCat
    for (let i = 0; i < WasteCategory.length; i++) {
      primaryCat = WasteCategory[i]
      if (parentCategory === primaryCat.id) {
        return primaryCat?.children.map(parse) ?? []
      }

      for (let j = 0; j < primaryCat?.children.length ?? 0; j++) {
        secondaryCat = primaryCat.children[j]
        if (parentCategory === secondaryCat.id) {
          return secondaryCat?.children?.map(parse) ?? []
        }
      }
    }
  }
  return []
}
export const checkWasteCategory = (
  primary?: number,
  secondary?: number,
  tertiary?: number,
): boolean => {
  if (!primary && !secondary && !tertiary) {
    return true
  }
  const primaryCat = WasteCategory.find((cat) => cat.id === primary)
  if (!primaryCat) {
    return false
  } else if (!primaryCat.children || primaryCat.children.length === 0) {
    return !secondary && !tertiary
  } else {
    const secondaryCat = primaryCat.children.find((cat) => cat.id === secondary)
    if (!secondaryCat) {
      return false
    } else if (!secondaryCat.children || secondaryCat.children.length === 0) {
      return !tertiary
    } else {
      return !!secondaryCat.children.find((cat) => cat.id === tertiary)
    }
  }
}
export const traduceWasteCategory = (category?: number): string => {
  let primaryCat, secondaryCat, tertiaryCat
  for (let i = 0; i < WasteCategory.length; i++) {
    primaryCat = WasteCategory[i]
    if (category === primaryCat.id) {
      return primaryCat.nomenclature
    }

    for (let j = 0; j < primaryCat?.children.length ?? 0; j++) {
      secondaryCat = primaryCat.children[j]
      if (category === secondaryCat.id) {
        return secondaryCat.nomenclature
      }
      if (secondaryCat.children) {
        for (let k = 0; k < secondaryCat.children.length ?? 0; k++) {
          tertiaryCat = secondaryCat.children[k]
          if (category === tertiaryCat.id) {
            return tertiaryCat.nomenclature
          }
        }
      }
    }
  }
  return ''
}
export const WasteCategory = [
  {
    id: 61,
    nomenclature: 'Déchets inertes (DI)',
    unite: null,
    exemples: null,
    label: '1 - Déchets inertes (DI)',
    children: [
      {
        id: 68,
        nomenclature: 'Béton',
        unite: null,
        exemples: null,
        label: '1.1 - Béton',
      },
      {
        id: 71,
        nomenclature: 'Briques',
        unite: null,
        exemples: null,
        label: '1.2 - Briques',
      },
      {
        id: 70,
        nomenclature: 'Tuiles et céramiques',
        unite: null,
        exemples: null,
        label: '1.3 - Tuiles et céramiques',
      },
      {
        id: 73,
        nomenclature:
          'Mélanges de béton, tuiles et céramique ne contenant pas de substances dangereuses',
        unite: null,
        exemples: null,
        label:
          '1.4 - Mélanges de béton, tuiles et céramique ne contenant pas de substances dangereuses',
      },
      {
        id: 72,
        nomenclature: 'Verre (sans cadre ou montant de fenêtres)',
        unite: null,
        exemples: null,
        label: '1.5 - Verre (sans cadre ou montant de fenêtres)',
      },
      {
        id: 74,
        nomenclature: 'Mélange bitumineux ne contenant pas de goudron',
        unite: null,
        exemples: null,
        label: '1.6 - Mélange bitumineux ne contenant pas de goudron',
      },
      {
        id: 67,
        nomenclature: 'Terres et cailloux ne contenant pas de substance dangereuse',
        unite: null,
        exemples: null,
        label: '1.7 - Terres et cailloux ne contenant pas de substance dangereuse',
      },
      {
        id: 69,
        nomenclature: 'Terres et pierres',
        unite: null,
        exemples: null,
        label: '1.8 - Terres et pierres',
      },
      {
        id: 151,
        nomenclature: 'Déchets de matériaux à base de fibre et de verre',
        unite: null,
        exemples: null,
        label: '1.9 - Déchets de matériaux à base de fibre et de verre',
      },
      {
        id: 152,
        nomenclature: 'Emballage en verre',
        unite: null,
        exemples: null,
        label: '1.10 - Emballage en verre',
      },
      {
        id: 153,
        nomenclature: 'Verre (triés)',
        unite: null,
        exemples: null,
        label: '1.11 - Verre (triés)',
      },
    ],
  },
  {
    id: 62,
    nomenclature: 'Déchets non dangereux non inertes (DNDNI) ',
    unite: null,
    exemples: null,
    label: '2 - Déchets non dangereux non inertes (DNDNI) ',
    children: [
      {
        id: 75,
        nomenclature: 'Plâtre',
        unite: null,
        exemples: null,
        label: '2.1 - Plâtre',
        children: [
          {
            id: 84,
            nomenclature: 'Plaques et carreaux',
            unite: null,
            exemples: null,
            label: '2.1.1 - Plaques et carreaux',
          },
          {
            id: 85,
            nomenclature: 'Enduits et supports inertes',
            unite: null,
            exemples: null,
            label: '2.1.2 - Enduits et supports inertes',
          },
        ],
      },
      {
        id: 76,
        nomenclature: 'Bois',
        unite: null,
        exemples: null,
        label: '2.2 - Bois',
        children: [
          {
            id: 86,
            nomenclature: 'Bois A (emballages, palettes)',
            unite: null,
            exemples: null,
            label: '2.2.1 - Bois A (emballages, palettes)',
          },
          {
            id: 87,
            nomenclature: 'Bois B : BR1',
            unite: null,
            exemples: null,
            label: '2.2.2 - Bois B : BR1',
          },
          {
            id: 88,
            nomenclature: 'Bois B : BR2',
            unite: null,
            exemples: null,
            label: '2.2.3 - Bois B : BR2',
          },
        ],
      },
      {
        id: 77,
        nomenclature: 'Métaux',
        unite: null,
        exemples: null,
        label: '2.3 - Métaux',
        children: [
          {
            id: 89,
            nomenclature: 'Cuivre',
            unite: null,
            exemples: null,
            label: '2.3.1 - Cuivre',
          },
          {
            id: 90,
            nomenclature: 'Aluminium',
            unite: null,
            exemples: null,
            label: '2.3.2 - Aluminium',
          },
          {
            id: 91,
            nomenclature: 'Ferreux',
            unite: null,
            exemples: null,
            label: '2.3.3 - Ferreux',
          },
          {
            id: 92,
            nomenclature: 'Zinc',
            unite: null,
            exemples: null,
            label: '2.3.4 - Zinc',
          },
          {
            id: 93,
            nomenclature: 'Autres, non ferreux',
            unite: null,
            exemples: null,
            label: '2.3.5 - Autres, non ferreux',
          },
        ],
      },
      {
        id: 78,
        nomenclature: 'Plastiques',
        unite: null,
        exemples: null,
        label: '2.4 - Plastiques',
        children: [
          {
            id: 94,
            nomenclature: 'PP',
            unite: null,
            exemples: null,
            label: '2.4.1 - PP',
          },
          {
            id: 95,
            nomenclature: 'PE',
            unite: null,
            exemples: null,
            label: '2.4.2 - PE',
          },
          {
            id: 96,
            nomenclature: 'PVC',
            unite: null,
            exemples: null,
            label: '2.4.3 - PVC',
          },
          {
            id: 97,
            nomenclature: 'PS',
            unite: null,
            exemples: null,
            label: '2.4.4 - PS',
          },
          {
            id: 98,
            nomenclature: 'PSE',
            unite: null,
            exemples: null,
            label: '2.4.5 - PSE',
          },
          {
            id: 99,
            nomenclature: 'XPS',
            unite: null,
            exemples: null,
            label: '2.4.6 - XPS',
          },
          {
            id: 100,
            nomenclature: 'Polyuréthane',
            unite: null,
            exemples: null,
            label: '2.4.7 - Polyuréthane',
          },
          {
            id: 101,
            nomenclature: 'Autres plastiques',
            unite: null,
            exemples: null,
            label: '2.4.8 - Autres plastiques',
          },
        ],
      },
      {
        id: 79,
        nomenclature: 'Matériaux isolants hors isolants polymères',
        unite: null,
        exemples: null,
        label: '2.5 - Matériaux isolants hors isolants polymères',
        children: [
          {
            id: 102,
            nomenclature: 'Laines minérales de verre',
            unite: null,
            exemples: null,
            label: '2.5.1 - Laines minérales de verre',
          },
          {
            id: 103,
            nomenclature: 'Laines minérales de roche',
            unite: null,
            exemples: null,
            label: '2.5.2 - Laines minérales de roche',
          },
          {
            id: 104,
            nomenclature: 'Isolants biosourcés',
            unite: null,
            exemples: null,
            label: '2.5.3 - Isolants biosourcés',
          },
          {
            id: 108,
            nomenclature: 'Autres matériaux isolants',
            unite: null,
            exemples: null,
            label: '2.5.4 - Autres matériaux isolants',
          },
        ],
      },
      {
        id: 80,
        nomenclature: 'Revêtements de sol',
        unite: null,
        exemples: null,
        label: '2.6 - Revêtements de sol',
        children: [
          {
            id: 109,
            nomenclature: 'Moquette',
            unite: null,
            exemples: null,
            label: '2.6.1 - Moquette',
          },
          {
            id: 110,
            nomenclature: 'Linoléum',
            unite: null,
            exemples: null,
            label: '2.6.2 - Linoléum',
          },
          {
            id: 111,
            nomenclature: 'Parquet (massif, flottant)',
            unite: null,
            exemples: null,
            label: '2.6.3 - Parquet (massif, flottant)',
          },
          {
            id: 112,
            nomenclature: 'Autres revêtements de sol',
            unite: null,
            exemples: null,
            label: '2.6.4 - Autres revêtements de sol',
          },
        ],
      },
      {
        id: 81,
        nomenclature: 'Revêtement d’étancheité',
        unite: null,
        exemples: null,
        label: '2.7 - Revêtement d’étancheité',
        children: [
          {
            id: 113,
            nomenclature: "Revêtements d'étanchéité bitumineux",
            unite: null,
            exemples: null,
            label: "2.7.1 - Revêtements d'étanchéité bitumineux",
          },
          {
            id: 114,
            nomenclature: "Revêtements d'étanchéité synthétiques",
            unite: null,
            exemples: null,
            label: "2.7.2 - Revêtements d'étanchéité synthétiques",
          },
        ],
      },
      {
        id: 82,
        nomenclature: 'Complexes',
        unite: null,
        exemples: null,
        label: '2.8 - Complexes',
        children: [
          {
            id: 115,
            nomenclature: 'Staff, stuc et plaques de gypse cellulose',
            unite: null,
            exemples: null,
            label: '2.8.1 - Staff, stuc et plaques de gypse cellulose',
          },
          {
            id: 116,
            nomenclature: 'Panneaux sandwich',
            unite: null,
            exemples: null,
            label: '2.8.2 - Panneaux sandwich',
          },
          {
            id: 117,
            nomenclature: 'Complexes plâtre + isolant',
            unite: null,
            exemples: null,
            label: '2.8.3 - Complexes plâtre + isolant',
          },
          {
            id: 118,
            nomenclature: 'Autres matériaux complexes',
            unite: null,
            exemples: null,
            label: '2.8.4 - Autres matériaux complexes',
          },
        ],
      },
      {
        id: 83,
        nomenclature: 'Divers',
        unite: null,
        exemples: null,
        label: '2.9 - Divers',
        children: [
          {
            id: 119,
            nomenclature:
              'Fenêtres et autres ouvertures vitrées : bois/alu/pvc et simple vitrage ou double vitrage',
            unite: null,
            exemples: null,
            label:
              '2.9.1 - Fenêtres et autres ouvertures vitrées : bois/alu/pvc et simple vitrage ou double vitrage',
          },
          {
            id: 120,
            nomenclature: 'Mélange de DND listés ci-dessus',
            unite: null,
            exemples: null,
            label: '2.9.2 - Mélange de DND listés ci-dessus',
          },
          {
            id: 121,
            nomenclature: 'Végétaux',
            unite: null,
            exemples: null,
            label: '2.9.3 - Végétaux',
          },
          {
            id: 122,
            nomenclature: 'Terre végétale',
            unite: null,
            exemples: null,
            label: '2.9.4 - Terre végétale',
          },
          {
            id: 123,
            nomenclature: 'Autres matériaux ou déchets non dangereux (DND) ',
            unite: null,
            exemples: null,
            label: '2.9.5 - Autres matériaux ou déchets non dangereux (DND) ',
          },
        ],
      },
    ],
  },
  {
    id: 64,
    nomenclature: 'Déchets d’équipements',
    unite: null,
    exemples: null,
    label: '3 - Déchets d’équipements',
    children: [
      {
        id: 124,
        nomenclature: 'Équipements sanitaires (lavabos, éviers, WC, ...)',
        unite: null,
        exemples: null,
        label: '3.1 - Équipements sanitaires (lavabos, éviers, WC, ...)',
      },
      {
        id: 125,
        nomenclature: 'Génie climatique (chauffage, climatisation, ventilation)',
        unite: null,
        exemples: null,
        label: '3.2 - Génie climatique (chauffage, climatisation, ventilation)',
      },
      {
        id: 126,
        nomenclature:
          'Équipements de chauffage, climatisation ou frigorifiques contenant des fluides frigorigènes dangereux',
        unite: null,
        exemples: null,
        label:
          '3.3 - Équipements de chauffage, climatisation ou frigorifiques contenant des fluides frigorigènes dangereux',
      },
      {
        id: 127,
        nomenclature: 'Matériel industriel ou mobilier',
        unite: null,
        exemples: null,
        label: '3.4 - Matériel industriel ou mobilier',
      },
      {
        id: 128,
        nomenclature: 'Conduits de fluide et canalisations',
        unite: null,
        exemples: null,
        label: '3.5 - Conduits de fluide et canalisations',
      },
      {
        id: 129,
        nomenclature: 'Câbles',
        unite: null,
        exemples: null,
        label: '3.6 - Câbles',
      },
      {
        id: 130,
        nomenclature: 'Équipement divers (ascenseurs, armoires TGBT, ...)',
        unite: null,
        exemples: null,
        label: '3.7 - Équipement divers (ascenseurs, armoires TGBT, ...)',
      },
      {
        id: 131,
        nomenclature: 'Déchets d’Équipements Électriques et Électroniques (DEEE)',
        unite: null,
        exemples: null,
        label: '3.8 - Déchets d’Équipements Électriques et Électroniques (DEEE)',
        children: [
          {
            id: 132,
            nomenclature: 'Luminaires (tubes fluorescents, lampes à décharges, lampes à LED)',
            unite: null,
            exemples: null,
            label: '3.8.1 - Luminaires (tubes fluorescents, lampes à décharges, lampes à LED)',
          },
          {
            id: 133,
            nomenclature: 'Électroménagers',
            unite: null,
            exemples: null,
            label: '3.8.2 - Électroménagers',
          },
          {
            id: 135,
            nomenclature: 'Autres DEEE contenant des substances dangereuses',
            unite: null,
            exemples: null,
            label: '3.8.9 - Autres DEEE contenant des substances dangereuses',
          },
          {
            id: 136,
            nomenclature: 'Autres DEEE non dangereux',
            unite: null,
            exemples: null,
            label: '3.8.10 - Autres DEEE non dangereux',
          },
        ],
      },
    ],
  },
  {
    id: 63,
    nomenclature: 'Déchets dangereux (DD)',
    unite: null,
    exemples: null,
    label: '4 - Déchets dangereux (DD)',
    children: [
      {
        id: 137,
        nomenclature: 'Amiante (précisez)',
        unite: null,
        exemples: null,
        label: '4.1 - Amiante (précisez)',
      },
      {
        id: 138,
        nomenclature: 'Enrobés à chaud contenant du goudron',
        unite: null,
        exemples: null,
        label: '4.2 - Enrobés à chaud contenant du goudron',
      },
      {
        id: 139,
        nomenclature: 'Revêtements d’étanchéité contenant du goudron',
        unite: null,
        exemples: null,
        label: '4.3 - Revêtements d’étanchéité contenant du goudron',
      },
      {
        id: 140,
        nomenclature: 'Éléments recouverts de peinture contenant des substances dangereuses',
        unite: null,
        exemples: null,
        label: '4.4 - Éléments recouverts de peinture contenant des substances dangereuses',
      },
      {
        id: 141,
        nomenclature: 'Bois traités contenant des substances dangereuses (Bois C)',
        unite: null,
        exemples: null,
        label: '4.5 - Bois traités contenant des substances dangereuses (Bois C)',
      },
      {
        id: 142,
        nomenclature: 'Terres contenant des substances dangereuses',
        unite: null,
        exemples: null,
        label: '4.6 - Terres contenant des substances dangereuses',
      },
      {
        id: 143,
        nomenclature: 'Déchets dangereux (DD)',
        unite: null,
        exemples: null,
        label: '4.7 - Déchets dangereux (DD)',
      },
    ],
  },
  {
    id: 65,
    nomenclature: 'Autre',
    unite: null,
    exemples: null,
    label: '5 - Autre',
    children: [
      {
        id: 154,
        nomenclature: 'Autre',
        unite: null,
        exemples: null,
        label: '5.1 - Autre',
      },
    ],
  },
]
