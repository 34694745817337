import { alpha, styled, Select } from '@mui/material'

const StyledSelectInput = styled(Select, {
  shouldForwardProp: (key: string) => key !== 'maxWidth',
})<{ required?: boolean; maxWidth?: string }>(({ theme, required, maxWidth }) => {
  return {
    maxWidth,
    '& .MuiSelect-select': {
      backgroundColor: required
        ? alpha(theme.palette.primary.main, 0.1)
        : theme.palette.textFieldBackground,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: required
        ? alpha(theme.palette.primary.main, 0.1)
        : theme.palette.textFieldBackground,
    },
    '& .MuiSelect-icon': {
      backgroundColor: required
        ? alpha(theme.palette.primary.main, 0.1)
        : theme.palette.textFieldBackground,
      color: theme.palette.primary.main,
    },
  }
})

export default StyledSelectInput
