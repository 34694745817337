import { sessionStore } from './session.store'
import { UserType } from '../../models/users.models'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'

export class SessionQuery {
  store = sessionStore
  user = this.store.pipe(select((state) => state.user))
  loading = this.store.pipe(selectIsRequestPending('init'))

  isAdmin = this.store.pipe(
    select((state) => {
      return UserType.admin === state.user?.type || UserType.platformAdmin === state.user?.type
    }),
  )

  getEnabelCerfa() {
    return !!this.store.getValue().user?.organization?.enableCerfa
  }
  getUserId() {
    return this.store.getValue().user?._id
  }

  getUserLang() {
    return this.store.getValue().user?.lang
  }

  getOrganizationId() {
    return this.store.getValue().user?.organization?._id
  }
}

export const sessionQuery = new SessionQuery()
