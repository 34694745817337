import { useEffect, useState, useRef, useCallback } from 'react'
import { styled, Box, InputLabel, FormHelperText, IconButton, InputAdornment } from '@mui/material'
import {
  Info as InfoIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import Tooltip from '../Tooltip.common'
import Stack from '../Stack.common'
import { NumberProps } from '../../../models/props.models'
import StyledInput from './Styled.input'
import { StringUtils } from '../../../utils/commons.utils'

const ChevronsContainer = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingRight: '4px',
  position: 'absolute',
  right: '0',
})
const ChevronButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 0,
}))
const NumberTextFieldComponent: React.FC<NumberProps> = (props): JSX.Element => {
  const {
    step,
    min,
    max,
    endAdornment,
    error,
    onClick,
    onChange,
    label,
    tooltip,
    placeholder,
    readOnly,
    disabled,
    pattern = '^-?(\\s|\\d)*\\.?\\d{0,2}$',
    required,
    value,
    showControl,
  } = props
  const computedShowControl = showControl && !readOnly && !disabled

  const [inputValue, setInputValue] = useState(value || '')
  const inputValueRef = useRef<any>(inputValue)
  const setInput = useCallback((val?: string | number) => {
    const formated = StringUtils.formatNumber(val?.toString() ?? '')
    if (inputValueRef.current !== formated) {
      setInputValue(formated)
    }
  }, [])

  useEffect(() => {
    inputValueRef.current = inputValue
  }, [inputValue])
  useEffect(() => {
    if (value === 0 || value === null) {
      setInput('0')
    } else {
      setInput(value)
    }
  }, [value, setInput])

  return (
    <Box display="flex" flexDirection="column" position="relative" onClick={onClick}>
      {!!label && (
        <InputLabel error={!!error}>
          <Stack direction="row" alignItems="center" spacing="6px">
            <Box>{label + (required ? '*' : '')}</Box>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon color="primary" />
              </Tooltip>
            )}
          </Stack>
        </InputLabel>
      )}
      <StyledInput
        variant="outlined"
        fullWidth
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        value={inputValue}
        type="text"
        onChange={(evt) => {
          let formatedValue = evt.target.value.replace(/\s*/g, '')
          if (pattern && formatedValue && !new RegExp(pattern).test(formatedValue.toString())) {
            evt.target.value = formatedValue.slice(0, formatedValue.length - 2)
            return
          }

          setInput(formatedValue)
          if (!formatedValue) {
            onChange?.()
            return
          }

          const floatValue = parseFloat(formatedValue)
          if (!!formatedValue && !isNaN(floatValue)) {
            if (floatValue !== value) {
              onChange?.(floatValue)
            }
          }
        }}
        onBlur={
          !readOnly
            ? () => {
                let val = value ?? 0
                if (step) {
                  val = Math.round(val / step) * step
                }
                if (max !== undefined && max <= val) {
                  val = max
                }
                if (min !== undefined && min >= val) {
                  val = min
                }
                if (val !== (value ?? 0)) {
                  onChange?.(val)
                }
              }
            : undefined
        }
        focused={readOnly ? false : undefined}
        InputProps={{
          readOnly,
          endAdornment:
            endAdornment || computedShowControl ? (
              <InputAdornment position="end">
                <Box display="flex">
                  {endAdornment ?? <></>}
                  {computedShowControl && !readOnly && !disabled ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ width: '13px' }}
                      onClick={(event: any) => event.stopPropagation()}>
                      <ChevronsContainer>
                        <ChevronButton
                          disabled={max !== undefined && max <= (value ?? 0)}
                          onClick={() => {
                            if (max !== undefined && (value ?? 0) + (step ?? 1) >= max) {
                              onChange?.(max)
                            } else {
                              let dStep = step ?? 1
                              let steppedValue = Math.round((value ?? 0) / dStep) * dStep
                              let newValue = steppedValue + dStep
                              if (
                                min !== undefined &&
                                ((min >= steppedValue && (value ?? 0) !== steppedValue) ||
                                  min >= newValue)
                              ) {
                                onChange?.(min)
                              } else if (steppedValue !== (value ?? 0)) {
                                onChange?.(steppedValue)
                              } else {
                                onChange?.(newValue)
                              }
                            }
                          }}>
                          <KeyboardArrowUpIcon />
                        </ChevronButton>
                        <ChevronButton
                          disabled={min !== undefined && (value ?? 0) <= min}
                          onClick={() => {
                            if (min !== undefined && (value ?? 0) - (step ?? 1) <= min) {
                              onChange?.(min)
                            } else {
                              let dStep = step ?? 1
                              let steppedValue = Math.round((value ?? 0) / dStep) * dStep
                              let newValue = steppedValue - dStep
                              if (
                                max !== undefined &&
                                ((max <= steppedValue && (value ?? 0) !== steppedValue) ||
                                  max <= newValue)
                              ) {
                                onChange?.(max)
                              } else if (steppedValue !== (value ?? 0)) {
                                onChange?.(steppedValue)
                              } else {
                                onChange?.(newValue)
                              }
                            }
                          }}>
                          <KeyboardArrowDownIcon />
                        </ChevronButton>
                      </ChevronsContainer>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </InputAdornment>
            ) : undefined,
        }}
        inputProps={{
          pattern,
          step: step,
          min: min,
          max: max,
        }}
        error={!!error}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', right: '15px' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}

export default NumberTextFieldComponent
